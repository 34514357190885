<script lang="ts" setup>
import TableRow from './TableRow.vue'
import { computed } from 'vue'
import TableRowCheckbox from './TableRowCheckbox.vue'
import ProjectTableCell from './ProjectTableCell.vue'
import { useProject } from './useProject'
import { useTableZIndices } from './useTableZIndices'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import { useRouter } from 'vue-router'
import { omit } from '@/shared/utils'
import DividerLine from '@/uiKit/DividerLine.vue'
import TableCell from './TableCell.vue'

const props = defineProps<{
  rowIndex: number
  cells: number[]
  workspaceId?: string
  projectId: string | null
  entityId?: string | null
  pluralSelection: boolean
  columnsInView: number[]
  extraIndexColWidth?: boolean
}>()

const emit = defineEmits<{
  (
    e:
      | 'extend-selection'
      | 'toggle'
      | 'delete'
      | 'context:delete'
      | 'context:open'
      | 'context:close',
  ): void
  (e: 'next', event?: KeyboardEvent): void
}>()

/** The number of columns that are out of the viewport to the left */
const columnsBeforeView = computed<number>(() => {
  const minInView = Math.min(...props.columnsInView)
  return props.cells.filter((cell) => cell < minInView).length
})

/** The number of columns that are out of the viewport to the right */
const columnsAfterView = computed<number>(() => {
  const maxInView = Math.max(...props.columnsInView)
  return props.cells.filter((cell) => cell > maxInView).length
})

const projectStore = useProject()

const entityId = computed(() => projectStore.activeView?.entities?.[props.rowIndex]?.id || null)

const isSelected = computed(
  () => !!entityId.value && projectStore.selectedEntityIds.has(entityId.value),
)

const tableScrollStore = useTableZIndices()

const { push } = useRouter()

const IDS = {
  expand: 'expand',
  delete: 'delete',
}

const onSelect = (id: string) => {
  switch (id) {
    case IDS.expand: {
      push({
        name: 'WorkspaceProjectEntityView',
        params: {
          workspaceId: props.workspaceId,
          projectId: props.projectId,
          entityId: props.entityId,
        },
      })
      break
    }
    case IDS.delete: {
      emit('context:delete')
      break
    }
  }
}
</script>

<template>
  <FloatingMenu
    @select="onSelect"
    @change:open="
      (isOpen) => {
        isOpen ? emit('context:open') : emit('context:close')
      }
    "
  >
    <template #context-trigger="{ triggerProps }">
      <TableRow
        :is-selected="isSelected"
        :data-test="`row-${rowIndex}`"
        role="row"
        class="group contents"
        :aria-rowindex="rowIndex + 1"
        v-bind="triggerProps"
      >
        <TableRowCheckbox
          :is-selected="isSelected"
          class="sticky left-0 w-12 border-b border-r border-border-subtle bg-surface-primary before:absolute before:-z-1 before:size-full group-last/row:border-b-0 group-hover/row:before:bg-background-gray-subtlest"
          :class="[tableScrollStore.zIndex.firstColumn]"
          :aria-colindex="1"
          :aria-rowindex="rowIndex + 1"
          :extra-width="props.extraIndexColWidth"
          @mousedown.shift.prevent.stop.exact="emit('extend-selection')"
          @change="emit('toggle')"
          >{{ rowIndex + 1 }}</TableRowCheckbox
        >
        <TableCell
          v-if="columnsBeforeView"
          :style="{ gridColumn: `span ${columnsBeforeView} / span ${columnsBeforeView}` }"
          loading
          role="gridcell"
          :aria-rowindex="rowIndex + 2"
          :aria-colindex="2"
          :is-focused="false"
          :is-selected="false"
        />
        <ProjectTableCell
          v-for="colIndex in columnsInView"
          :key="colIndex"
          class="before:pointer-events-none before:absolute before:size-full last:border-l group-hover/row:before:bg-background-gray-subtlest [&:nth-last-child(2)]:border-r-0"
          :data-test="`col-${colIndex}`"
          :col-index="colIndex"
          :row-index="rowIndex"
          @next="(e) => emit('next', e)"
        />
        <TableCell
          v-if="columnsAfterView"
          :style="{ gridColumn: `span ${columnsAfterView} / span ${columnsAfterView}` }"
          loading
          role="gridcell"
          :aria-rowindex="rowIndex + 2"
          :aria-colindex="Math.max(...columnsInView)"
          :is-focused="false"
          :is-selected="false"
        />
      </TableRow>
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        class="w-[256px] min-w-max"
        v-bind="contentProps"
        data-test="table-row-context-menu"
        :data-row="rowIndex + 1"
      >
        <div
          v-if="!pluralSelection"
          class="w-full p-0.5"
        >
          <ListMenuItem
            label="Open Entity"
            icon="expand"
            v-bind="omit(getItemProps({ value: IDS.expand }), ['onSelect'])"
          />
        </div>
        <DividerLine
          v-if="entityId && !pluralSelection"
          color="subtle"
        ></DividerLine>
        <div class="w-full p-0.5">
          <ListMenuItem
            :label="`Delete ${pluralSelection ? 'Entities' : 'Entity'}`"
            critical
            icon="trash"
            v-bind="omit(getItemProps({ value: IDS.delete }), ['onSelect'])"
          />
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
