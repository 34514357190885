import { getLibrary } from '@/backend/getLibrary'
import { listLibraryItems } from '@/backend/listLibraryItems'
import { PropertyType } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { computed, watch, type Ref } from 'vue'
import { useLibraryStore, type LibraryItem } from './libraryStore'
import { serializeLibraryItem } from './serializers'
import { useLibraryWebsocket } from './useLibraryWebsocket'

/**
 * Loads library data into the library store when the workspace ID changes.
 */
export const useLoadLibrary = (workspaceId: Ref<string>) => {
  const libraryStore = useLibraryStore()

  const loadAndSetLibraryItems = async (from: number, to: number) => {
    assertIsNotNullOrUndefined(libraryStore.library, 'Library ID is null')
    const fileSlug = libraryStore.library.fileProperty.slug
    const textSlug = libraryStore.library.textProperty.slug

    const res = await listLibraryItems({ workspaceId: workspaceId.value, start: from, end: to })
    if (!res.ok) {
      throw new Error('Failed to load library items')
    }

    /**
     * Since library items are just entities, their field values
     * can be null. Keep the IDs of entities that have field data
     * so that we can upload files to them later and reduce the
     * amount of 'orphaned' entities.
     */
    const { newItems, emptyIds } = res.data.data.reduce<{
      newItems: LibraryItem[]
      emptyIds: string[]
    }>(
      (acc, entity) => {
        const fileField = entity.fields[fileSlug]
        assertIsNotNullOrUndefined(fileField, 'File field is not defined')
        const textField = entity.fields[textSlug]
        assertIsNotNullOrUndefined(textField, 'Text field is not defined')

        if (fileField.manual_value.value) {
          acc.newItems.push(serializeLibraryItem(entity, fileSlug))
        } else if (textField.manual_value.value) {
          acc.newItems.push(serializeLibraryItem(entity, textSlug))
        } else {
          acc.emptyIds.push(entity.id)
        }
        return acc
      },
      { newItems: [], emptyIds: [] },
    )

    libraryStore.libraryItems = [...libraryStore.libraryItems, ...newItems]
    libraryStore.emptyItemIds = [...libraryStore.emptyItemIds, ...emptyIds]

    return res.data.metadata.total_count ?? 0
  }

  /**
   * Connect to a websocket session to receive library updates
   * from other users.
   */
  const libraryId = computed(() => libraryStore.library?.id)
  useLibraryWebsocket(libraryId)

  watch(
    () => workspaceId.value,
    async (id) => {
      libraryStore.reset()

      const getLibraryRes = await getLibrary(id)
      if (!getLibraryRes.ok) {
        throw new Error('Failed to load library')
      }

      const libraryId = getLibraryRes.data.id
      const fileProperty = getLibraryRes.data.properties.find((p) => p.type === PropertyType.file)
      const textProperty = getLibraryRes.data.properties.find((p) => p.type === PropertyType.text)
      assertIsNotNullOrUndefined(fileProperty, 'File property not found in library response')
      assertIsNotNullOrUndefined(textProperty, 'Text property not found in library response')

      libraryStore.library = {
        id: libraryId,
        fileProperty,
        textProperty,
      }

      const pageSize = 100
      const totalCount = await loadAndSetLibraryItems(0, pageSize - 1)

      // The endpoint is a paginated endpoint, but we load all items up front
      // because there are UI features that rely on all items being loaded.
      for (let i = totalCount - pageSize; i > 0; i -= pageSize) {
        const from = libraryStore.libraryItems.length
        const to = from + pageSize - 1
        await loadAndSetLibraryItems(from, to)
      }
    },
    {
      immediate: true,
    },
  )
}
