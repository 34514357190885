import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * When a workspace reaches one of its limits, we show a paywall dialog
 * to push the user towards upgrading their plan.
 */
export const usePaywallStore = defineStore('paywall', () => {
  const dialogIsOpen = ref(false)

  return {
    dialogIsOpen,
  }
})
