import type { components } from '@/api'
import { put } from './client'

export type StarFieldPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/properties/${string}/ground_truth`
export type StarFieldRequest = components['schemas']['Projects.SetFieldGroundTruth']
export type StarFieldResponse = components['schemas']['Projects.Entities.FieldResponse']

export const starField = ({
  workspaceId,
  projectId,
  entityId,
  propertyId,
  groundTruth,
}: {
  workspaceId: string
  projectId: string
  entityId: string
  propertyId: string
  groundTruth: boolean
}) => {
  return put<StarFieldRequest, StarFieldResponse, StarFieldPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/properties/${propertyId}/ground_truth`,

    { ground_truth: groundTruth },
  )
}
