<script setup lang="ts">
import { computed, ref } from 'vue'

import { type PropertyType } from '@/backend/types'
import { type SimpleFilter, parseMatcherName } from '@/modules/Project/useFilters'
import { omit } from '@/shared/utils'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import type { MatcherName } from '../useFilters'

const props = defineProps<{
  filter: SimpleFilter
  type: PropertyType
  /** The total number of options this property has */
  optionCount?: number
}>()

const emit = defineEmits<{
  (e: 'match', matchId: ID): void
}>()

export type ID = Extract<
  MatcherName,
  | 'property_any_of'
  | 'property_all_of'
  | 'property_none_of'
  | 'property_not_set'
  | 'property_contains_any_of'
  | 'property_contains_none_of'
>

const IDS = {
  matchAnyOf: 'property_any_of',
  matchAllOf: 'property_all_of',
  matchNoneOf: 'property_none_of',
  matchNotSet: 'property_not_set',
  matchContainsAnyOf: 'property_contains_any_of',
  matchContainsNoneOf: 'property_contains_none_of',
} as const

const onSelect = (id: ID) => {
  emit('match', id)
}

const amount = computed(() => {
  if (!('values' in props.filter.matcher)) return

  if (typeof props.filter.matcher.values === 'string') return 1
  return props.filter.matcher.values.length
})

/**
 * 'property_not_set' is not a valid backend filter matcher, so this is where we
 * swap out the 'is_none_of <all_options>' matcher for the user friendly 'is not set'
 * option.
 */
const currentFilterName = computed(() => {
  if (props.filter.matcher.name === 'property_none_of' && amount.value === props.optionCount) {
    return IDS.matchNotSet
  }

  return props.filter.matcher.name
})

const isOpen = ref(false)
</script>

<template>
  <FloatingMenu
    :positioning="{ placement: 'top-start' }"
    @change:open="isOpen = $event"
    @select="(id: string) => onSelect(id as ID)"
  >
    <template #trigger="{ triggerProps }">
      <button
        v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
        class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
        :class="{ 'bg-background-transparent-hovered': isOpen }"
      >
        {{
          parseMatcherName({
            matcherName: currentFilterName,
            amount,
            type,
          })
        }}
      </button>
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="min-w-[242px]"
      >
        <div class="flex w-full flex-col p-0.5">
          <ListMenuItem
            v-if="type === 'text'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchContainsAnyOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchContainsAnyOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchContainsAnyOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="type === 'text'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchContainsNoneOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchContainsNoneOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchContainsNoneOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="['single_select', 'multi_select', 'user_select', 'text'].includes(type)"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchAnyOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchAnyOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchAnyOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="type === 'multi_select'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchAllOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchAllOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchAllOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="['single_select', 'multi_select', 'user_select', 'text'].includes(type)"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchNoneOf }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchNoneOf ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            {{
              parseMatcherName({
                matcherName: IDS.matchNoneOf,
                amount,
                type,
              })
            }}
          </ListMenuItem>
          <ListMenuItem
            v-if="type === 'single_select' || type === 'multi_select' || type === 'user_select'"
            class="gap-1 px-1.5 py-1"
            element="button"
            v-bind="omit(getItemProps({ value: IDS.matchNotSet }), ['onSelect'])"
          >
            <template #prefix>
              <IconSprite
                :icon="currentFilterName === IDS.matchNotSet ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            is not set
          </ListMenuItem>
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
