<script setup lang="ts">
import type { ListTemplatesResponse } from '@/backend/listTemplates'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { useCloneProject } from '@/modules/Projects/useCloneProject'

const props = defineProps<{
  template: ListTemplatesResponse['data'][number]
  workspaceId: string
}>()

const emit = defineEmits<{
  (e: 'close' | 'is-cloning'): void
}>()

const { cloneProject } = useCloneProject()
const onCloneProject = async (templateId: string) => {
  emit('is-cloning')
  await cloneProject(templateId, props.workspaceId, 'template')
  emit('close')
}
</script>

<template>
  <button
    class="group/template relative flex h-[200px] cursor-pointer flex-col overflow-hidden rounded-corner-8 bg-surface-secondary-persist text-start"
    data-test="template-card"
    @click="onCloneProject(template.id)"
  >
    <div class="h-[136px] overflow-hidden">
      <ObjectURLImage
        class="w-full object-cover transition-all duration-300 group-hover/template:scale-110"
        :url="template.cover_image_urls.high"
        :loading="false"
      />
    </div>
    <div
      class="flex w-full items-center justify-between rounded-b-corner-8 border border-border-subtle group-hover/template:bg-background-gray-subtlest"
    >
      <div class="pointer-events-none flex h-16 w-full flex-col p-4 group-hover/template:w-1/2">
        <h3 class="line-clamp-1 text-sm-12px-bold text-text">{{ template.name }}</h3>
        <p class="line-clamp-1 text-xs-11px-light text-text-subtlest">
          {{ template.properties.length }}
          {{ template.properties.length === 1 ? 'property' : 'properties' }}
        </p>
      </div>
      <div class="hidden w-1/2 group-hover/template:block">
        <DarwinButton
          variant="neutral"
          size="md"
          tabindex="-1"
          role="presentation"
          >Use template</DarwinButton
        >
      </div>
    </div>
  </button>
</template>
