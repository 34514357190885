import type { components, operations } from '@/api'
import { get } from './client'

export type ListProjectsPath = `/api/workspaces/${string}/projects`

export type ListProjectsResponse = components['schemas']['Projects.ListProjectsResponse']
export type ListProjectsRequest = operations['project-list']['parameters']['query']
export type ListProjectsParams = operations['project-list']['parameters']['query']

export const listProjects = (workspaceId: string, parameters?: ListProjectsParams) =>
  get<ListProjectsRequest, ListProjectsResponse, ListProjectsPath>(
    `/api/workspaces/${workspaceId}/projects`,
    parameters,
  )
