<script lang="ts">
const KEY = Symbol('Menu')

type Menu = {
  machine: Parameters<zagMenu.Api['setChild']>['0']
  menu: ComputedRef<zagMenu.Api>
}

const useMenu = (menu: Menu) => {
  const parentMenu = inject<Menu | null>(KEY, null)
  onBeforeMount(() => {
    setTimeout(() => {
      if (!parentMenu) return
      parentMenu.menu.value.setChild(menu.machine)
      menu.menu.value.setParent(parentMenu.machine)
    })
  })

  const itemTriggerProps = computed(() => {
    if (!parentMenu) return null
    return parentMenu?.menu.value.getTriggerItemProps(menu.menu.value)
  })

  provide(KEY, menu)

  return {
    itemTriggerProps: itemTriggerProps,
    isSubmenu: !!parentMenu,
    parentMenu,
  }
}
</script>

<script setup lang="ts">
import { isHtmlElement } from '@/shared/utils'
import { useEventListener } from '@vueuse/core'
import * as zagMenu from '@zag-js/menu'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, inject, onBeforeMount, provide, ref, watch, type ComputedRef } from 'vue'

const props = withDefaults(
  defineProps<{
    ariaLabel?: string
    positioning?: zagMenu.Context['positioning']
    disableTeleport?: boolean
    open?: boolean
  }>(),
  {
    open: false,
    ariaLabel: undefined,
    positioning: undefined,
    disableTeleport: false,
  },
)

const emit = defineEmits<{
  (e: 'select', item: string): void
  (e: 'change:open', open: boolean): void
}>()

const [state, send, machine] = useMachine(
  zagMenu.machine({
    id: window.crypto.randomUUID(),
    onOpenChange(e) {
      emit('change:open', e.open)
    },
    async onSelect({ value }) {
      // This is needed not to trigger the click outside handler on certain components
      // Tried reproducing in https://stackblitz.com/edit/vitejs-vite-yvw4vp?file=src%2FApp.vue
      // to no avail, no idea why it's happening.
      await new Promise((resolve) => setTimeout(resolve, 1))
      emit('select', value)
    },
    closeOnSelect: false,
    onEscapeKeyDown(e) {
      if (!menu.value.open) return
      e.stopPropagation()
    },
  }),
  {
    context: computed(() => {
      return {
        'aria-label': props.ariaLabel,
        positioning: props.positioning,
      }
    }),
  },
)
const menu = computed(() => zagMenu.connect(state.value, send, normalizeProps))
const { itemTriggerProps, isSubmenu, parentMenu } = useMenu({ machine, menu })

watch(
  () => props.open,
  () => menu.value.setOpen(props.open),
)

function onEscapeKeydown(event: KeyboardEvent) {
  const target = event.target
  if (!isHtmlElement(target)) return
  const isCurrentMenu =
    target.dataset.scope === 'menu' && target.id.includes(state.value.context.id)

  if (event.key !== 'Escape' || !isSubmenu || !isCurrentMenu) return

  event.stopPropagation()
  menu.value.setOpen(false)

  // focus parent menu instead of body
  const parentId = `menu:${parentMenu?.machine.state.context.id}:content`
  document.getElementById(parentId)?.focus()
}

useEventListener(document, 'keydown', onEscapeKeydown, { capture: true })

const contentRef = ref<{ $el: HTMLElement } | null>(null)
function getCurrentHighlightedEl() {
  const menuEl = contentRef.value?.$el
  if (!menuEl) return null
  const highlightedEl = menuEl.querySelector('[data-state="highlighted"]')
  if (!isHtmlElement(highlightedEl)) return null
  return highlightedEl
}

type GetItemPropsArgs = Omit<Parameters<zagMenu.Api['getItemProps']>[0], 'value'> & {
  value: string | number
}
const getItemProps = (args: GetItemPropsArgs) => {
  const props = menu.value.getItemProps({
    ...args,
    value: args.value.toString(),
  })

  function onKeydown(e: KeyboardEvent) {
    const currentHighlightedEl = getCurrentHighlightedEl()
    if (currentHighlightedEl?.querySelector('input') && ['Enter', 'Space'].includes(e.key)) {
      // Let the default behavior happen, don't propagate to Zag
      e.stopPropagation()
      return
    }

    // Otherwise, call Zags handler
    props.onKeydown?.(e)
  }

  return {
    ...props,
    onKeydown,
  }
}

type GetOptionItemPropsArgs = Omit<Parameters<zagMenu.Api['getOptionItemProps']>[0], 'value'> & {
  value: string | number
}
const getOptionItemProps = (args: GetOptionItemPropsArgs) => {
  return menu.value.getOptionItemProps({
    ...args,
    value: args.value.toString(),
  })
}

function contentKeydown(e: KeyboardEvent) {
  const menuEl = contentRef.value?.$el
  if (!menuEl) return

  const currHighlighted = menuEl.querySelector('[data-highlighted]')
  // If highlighted contains an input, and enter or space is pressed, we let it.
  if (currHighlighted?.querySelector('input') && ['Enter', 'Space'].includes(e.key)) {
    return
  }

  menu.value.getContentProps().onKeydown?.(e)
  const nextHighlighted = menuEl.querySelector('[data-highlighted]')
  // If highlighted contains an input, lets focus that so that
  // keydown handlers work correctly
  const input = nextHighlighted?.querySelector('input')
  input?.focus()
}

const contentProps = computed(() => {
  return { ...menu.value.getContentProps(), onKeydown: contentKeydown, ref: contentRef }
})
</script>

<template>
  <slot
    name="context-trigger"
    :trigger-props="menu.getContextTriggerProps()"
  />

  <slot
    name="trigger"
    :open="menu.open"
    :trigger-props="menu.getTriggerProps()"
  />

  <slot
    v-if="itemTriggerProps"
    name="item-trigger"
    :item-trigger-props="itemTriggerProps"
  />

  <Teleport
    to="body"
    :disabled="disableTeleport"
  >
    <div
      v-if="menu.open || $slots['context-trigger']"
      v-bind="menu.getPositionerProps()"
    >
      <slot
        name="content"
        :content-props="contentProps"
        :get-item-props="getItemProps"
        :get-option-item-props="getOptionItemProps"
      />
    </div>
  </Teleport>
</template>

<style>
/* Undo tailwind's hidden reset for menu */
[data-scope='menu']:is([hidden]) {
  display: none !important;
}
</style>
