<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { TYPE_ICON } from './icons'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed, watchEffect } from 'vue'
import { useProject, type FileProperty } from './useProject'
import IconButton from '@/uiKit/IconButton.vue'
import TextWithClaims from '@/sharedComponents/TextWithClaims.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import TabList from '@/uiKit/TabList.vue'
import { useGroundingStore } from './useGroundingStore'
import GroundingModalFile from './GroundingModalFile.vue'

const projectStore = useProject()
const groundingStore = useGroundingStore()

// Keep the store in sync with the field that the open modal is showing
watchEffect(async () => {
  if (!groundingStore.field) {
    return
  }

  const firstInput = groundingStore.inputFields[0]
  if (!firstInput || groundingStore.activeInputId) {
    return
  }
  groundingStore.activeInputId = firstInput.propertyId
})

const { captureAnalyticsEvent } = useAnalytics()

watchEffect(() => {
  if (!groundingStore.field) return
  captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_OPENED)
})

const targetProperty = computed(() =>
  groundingStore.field ? projectStore.propertiesById[groundingStore.field.propertyId] : null,
)

const targetFieldValue = computed(() => {
  return groundingStore.field?.manualValue ?? groundingStore.field?.toolValue
})

const pdfProperties = computed(() => {
  return groundingStore.inputFields
    .map((field) => projectStore.propertiesById[field.propertyId])
    .filter((property): property is FileProperty => {
      return (
        property?.type === 'file' &&
        groundingStore.sources.some((source) => source.propertyId === property.id)
      )
    })
})

const activeInputName = computed(() => {
  if (!groundingStore.activeInputId) return undefined
  return projectStore.propertiesById[groundingStore.activeInputId]?.name
})

const shouldShowSourceTabs = computed(() => groundingStore.inputFields.length > 1)
</script>

<template>
  <ModalDialog
    :open="Boolean(groundingStore.field)"
    to="body"
    aria-label="Grounding Modal"
    class="overflow-hidden"
    @close="groundingStore.reset"
  >
    <div
      class="h-[75vh] w-[70vw] min-w-[1080px] max-w-[90vw] overflow-y-auto overflow-x-hidden rounded-corner-16 bg-surface-popover scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      data-test="grounding-modal"
    >
      <div class="flex size-full flex-row">
        <div class="flex w-1/2 flex-col bg-surface-tertiary">
          <div
            v-if="shouldShowSourceTabs"
            class="p-1.5 pt-2"
          >
            <TabList
              v-if="groundingStore.activeInputField"
              id="grounding-modal-tab-list"
              :items="
                pdfProperties.map((p) => ({
                  value: p.id,
                  leadingIcon: TYPE_ICON[p.type],
                  label: p.name,
                }))
              "
              :value="groundingStore.activeInputField.propertyId"
              @change="groundingStore.activeInputId = $event"
            />
          </div>
          <GroundingModalFile
            class="mb-2 pr-3"
            :class="!shouldShowSourceTabs && 'mt-3'"
            role="tabpanel"
            :aria-label="activeInputName"
          />
        </div>

        <div
          v-if="targetProperty"
          class="flex w-1/2 grow flex-col border-l border-border-subtle"
        >
          <div
            class="flex h-10 shrink-0 items-center gap-2 border-b border-border-subtle pl-2.5 pr-2"
          >
            <IconSprite
              :icon="TYPE_ICON[targetProperty.type]"
              class="text-icon-subtlest"
            />
            <div class="grow text-sm-12px-default text-text">{{ targetProperty.name }}</div>
            <IconButton
              icon="close"
              size="md"
              variant="neutral"
              rounded
              class="text-icon-subtle"
              aria-label="Close AI citations dialog"
              @click="groundingStore.reset"
            />
          </div>
          <div class="overflow-y-auto p-4">
            <TextWithClaims
              v-if="typeof targetFieldValue === 'string'"
              :text="targetFieldValue"
              class="text-sm-12px-light text-text"
              :claims="groundingStore.claims"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
