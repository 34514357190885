import type { components } from '@/api'

import { post } from './client'

export type ListFilteredEntitiesPath =
  `/api/workspaces/${string}/projects/${string}/entities/filter`
export type ListFilteredEntitiesResponse =
  components['schemas']['Projects.ListEntities.ListEntitiesResponse']
export type ListFilteredEntitiesRequest = components['schemas']['Projects.ListEntitiesRequest']

export type ListFilteredEntitiesArgs = {
  workspaceId: string
  projectId: string
} & ListFilteredEntitiesRequest

export const listFilteredEntities = ({
  workspaceId,
  projectId,
  ...requestBody
}: ListFilteredEntitiesArgs) =>
  post<ListFilteredEntitiesRequest, ListFilteredEntitiesResponse, ListFilteredEntitiesPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/filter`,
    requestBody,
  )
