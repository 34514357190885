import type { FileField, PdfField } from './useProject'

export const resolveFilename = (
  field: Pick<
    FileField | PdfField,
    'manualFilename' | 'toolFilename' | 'manualValue' | 'toolValue'
  >,
): string | null => {
  const fromData = field.manualFilename || field.toolFilename
  if (fromData) {
    return fromData
  }

  const urlString = field.manualValue || field.toolValue
  if (!urlString) {
    return null
  }

  if (isValidUrl(urlString)) {
    return new URL(urlString).pathname.split('/').pop() || null
  }

  return null
}

const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
