<script setup lang="ts">
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import type { VirtualElement } from '@floating-ui/vue'
import { arrow, offset, useFloating } from '@floating-ui/vue'
import { useIntervalFn, usePrevious, useWindowSize } from '@vueuse/core'
import { computed, ref, toValue, watchEffect } from 'vue'
import type { Coordinates } from './types'
import { useWelcomeTour } from './useWelcomeTour'
import DotSteps from '@/sharedComponents/DotSteps.vue'
import { useTheme } from '../App/useTheme'
import { useAbTest } from '@/sharedComposables/useAbTest'

type Quadrant = 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left'

function generateRoundedCorner(
  point: { x: number; y: number },
  radius: number,
  quadrant: Quadrant,
): string[] {
  const points = 12
  const angleStep = Math.PI / (2 * points)

  return Array.from({ length: points + 1 }, (_, i) => {
    const angle = angleStep * i
    let x: number, y: number

    switch (quadrant) {
      case 'top-right':
        x = point.x + radius * Math.cos(angle)
        y = point.y - radius * Math.sin(angle)
        break
      case 'bottom-right':
        x = point.x + radius * Math.cos(angle)
        y = point.y + radius * Math.sin(angle)
        break
      case 'bottom-left':
        x = point.x - radius * Math.cos(angle)
        y = point.y + radius * Math.sin(angle)
        break
      case 'top-left':
        x = point.x - radius * Math.cos(angle)
        y = point.y - radius * Math.sin(angle)
        break
    }

    return `${x.toFixed(2)}px ${y.toFixed(2)}px`
  })
}

function createRoundedPoly(coords: Coordinates): string {
  const radius = Math.min(coords.radius ?? 4, 16)
  const { x1, y1, x2, y2 } = coords

  return [
    ...generateRoundedCorner({ x: x1 + radius, y: y1 + radius }, radius, 'top-left'),
    `${(x2 - radius).toFixed(2)}px ${y1.toFixed(2)}px`,
    ...generateRoundedCorner({ x: x2 - radius, y: y1 + radius }, radius, 'top-right').reverse(),
    `${x2.toFixed(2)}px ${(y2 - radius).toFixed(2)}px`,
    ...generateRoundedCorner({ x: x2 - radius, y: y2 - radius }, radius, 'bottom-right'),
    `${(x1 + radius).toFixed(2)}px ${y2.toFixed(2)}px`,
    ...generateRoundedCorner({ x: x1 + radius, y: y2 - radius }, radius, 'bottom-left').reverse(),
    `${x1.toFixed(2)}px ${(y1 + radius).toFixed(2)}px`,
  ].join(',\n')
}

const welcomeTour = useWelcomeTour()
const coords = computed(() => {
  return toValue(welcomeTour.step?.coordinates)
})
const previousCoords = usePrevious(coords)
// Prevent jumping around while waiting for elements to show
const coordsToUse = computed(() => {
  return coords.value ?? previousCoords.value
})

const reference = computed<VirtualElement>(() => {
  const c = coordsToUse.value ?? { x1: 0, y1: 0, x2: 0, y2: 0 }
  const rect = {
    x: c.x1,
    y: c.y1,
    width: c.x2 - c.x1,
    height: c.y2 - c.y1,
    top: c.y1,
    right: c.x2,
    bottom: c.y2,
    left: c.x1,
  }

  return {
    getBoundingClientRect() {
      return rect
    },
  }
})

const floating = ref<HTMLElement>()
const floatingArrow = ref<HTMLElement>()
const { placement, floatingStyles, middlewareData } = useFloating(reference, floating, {
  placement: computed(() => welcomeTour.step?.placement ?? 'right-start'),
  middleware: [offset(16), arrow({ element: floatingArrow })],
})

const windowSize = useWindowSize()

const dimensions = computed(() => {
  const scale = 0.7
  const width = windowSize.width.value * scale
  const height = windowSize.height.value * scale

  return { width, height, scale }
})

function zoomIn() {
  if (welcomeTour.status === 'ZOOMING') return
  welcomeTour.status = 'ZOOMING'
  setTimeout(() => {
    welcomeTour.begin()
  }, 1500)
}

const slotWrapper = ref<HTMLElement>()
const slotHtml = ref('')
watchEffect(() => {
  if (welcomeTour.status === 'COMPLETED' || welcomeTour.status === 'IDLE') return
  useIntervalFn(() => {
    slotHtml.value = slotWrapper.value?.innerHTML ?? ''
  }, 250)
})

const canAnimateTooltip = ref(false)
watchEffect(() => {
  if (welcomeTour.status !== 'IN_PROGRESS') return
  setTimeout(() => {
    canAnimateTooltip.value = true
  }, 500)
})

const animatedScale = computed(() => {
  if (welcomeTour.status === 'CREATING_PROJECT') return 0
  return welcomeTour.status === 'ZOOMING' ? 1 : dimensions.value.scale
})

const { actualTheme } = useTheme()

const removeSkipAbTest = useAbTest('remove-welcome-tour-skip')
</script>

<template>
  <Teleport to="body">
    <Transition
      class="transition"
      enter-from-class="opacity-0"
      enter-active-class="duration-500"
      leave-active-class="opacity-0 duration-200"
    >
      <div v-if="welcomeTour.status === 'IN_PROGRESS' && coordsToUse">
        <div
          class="fixed inset-0 z-[999] bg-blanket-gradient transition-all"
          :class="canAnimateTooltip ? 'duration-500' : 'duration-0'"
          :style="{
            clipPath: `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, ${coordsToUse.x2}px 0%,
						${createRoundedPoly(coordsToUse)}, ${coordsToUse.x2}px 0%)`,
          }"
          @click="welcomeTour.step?.onClickOutside"
        />
        <div
          ref="floating"
          class="z-[9999] max-w-[360px] rounded-corner-12 bg-surface-popover-inverted outline outline-border transition-all"
          :class="canAnimateTooltip ? 'duration-500' : 'duration-0'"
          :style="floatingStyles"
        >
          <div class="relative z-10 overflow-hidden rounded-corner-12 bg-surface-popover-inverted">
            <div
              class="contents"
              data-theme="dark"
            >
              <div class="p-4 pb-0">
                <p class="text-lg-15px-bold text-text">{{ welcomeTour.step?.title }}</p>
                <p class="text-sm-12px-light text-text-subtle">
                  {{ welcomeTour.step?.description }}
                </p>

                <component
                  :is="welcomeTour.step?.component"
                  v-if="welcomeTour.step?.component"
                />
              </div>

              <div
                class="mt-7 flex justify-between border-t border-border-subtle bg-background-gray-sunken px-4 py-[14px]"
              >
                <DotSteps
                  :total="welcomeTour.steps.length"
                  :current="welcomeTour.stepIndex + 1"
                />
                <div class="flex gap-2">
                  <DarwinButton
                    v-if="removeSkipAbTest !== 'remove-skip'"
                    variant="transparent"
                    size="sm"
                    @click="welcomeTour.skipTour"
                  >
                    Skip tour
                    <template #trailing-icon>
                      <IconSprite icon="close" />
                    </template>
                  </DarwinButton>
                  <DarwinButton
                    v-if="welcomeTour.step?.next"
                    :variant="welcomeTour.step.next.variant ?? 'neutral'"
                    size="sm"
                    :disabled="toValue(welcomeTour.step.next.disabled)"
                    @click="welcomeTour.step.next.action"
                  >
                    {{ welcomeTour.step.next.text }}
                  </DarwinButton>
                </div>
              </div>
            </div>
          </div>
          <div
            ref="floatingArrow"
            class="absolute size-3 rotate-45 rounded-[3px] bg-surface-popover-inverted transition-all duration-500"
            :class="[
              actualTheme === 'light'
                ? placement.includes('right')
                  ? '-translate-x-1'
                  : '-translate-x-2'
                : '-translate-x-2',
              actualTheme === 'light' && 'outline outline-border',
            ]"
            :style="{
              left:
                middlewareData.arrow?.x != null
                  ? `${middlewareData.arrow.x}px`
                  : `${placement.includes('right') ? 0 : '100%'}`,
              top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
            }"
          ></div>
        </div>
      </div>
    </Transition>
  </Teleport>

  <div
    v-if="
      welcomeTour.status === 'BEFORE_ZOOM' ||
      welcomeTour.status === 'ZOOMING' ||
      welcomeTour.status === 'CREATING_PROJECT'
    "
    class="fixed inset-0 z-[9999] bg-surface-tertiary"
  >
    <div class="flex h-[37%] flex-col items-center justify-center text-center">
      <h1 class="text-display-xs-20px-bold">Welcome to V7 Go</h1>
      <p class="mt-2 max-w-[480px] text-sm-12px-default text-text-subtle">
        V7 Go solves tasks at scale by connecting your data to GenAI workflows. Let's dive into some
        core concepts.
      </p>
      <DarwinButton
        variant="black"
        size="sm"
        class="mt-8"
        :disabled="welcomeTour.status === 'CREATING_PROJECT'"
        @click="zoomIn"
      >
        Get started
      </DarwinButton>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div
      class="pointer-events-none absolute left-1/2 overflow-hidden outline outline-[hsl(0,0%,0%,0.025)] transition-all duration-1000"
      :style="{
        transform: `translate(-50%) scale(${animatedScale})`,
        width: `${windowSize.width.value}px`,
        height: `${windowSize.height.value}px`,
        outlineWidth: `${12 / dimensions.scale}px`,
        top: welcomeTour.status === 'ZOOMING' ? '0' : '43%',
        borderRadius: welcomeTour.status === 'ZOOMING' ? '' : `${12 / dimensions.scale}px`,
        transformOrigin: 'top center',
      }"
      v-html="slotHtml"
    ></div>

    <div
      class="pointer-events-none absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center gap-2 text-sm-12px-light text-text-subtle transition"
      :class="welcomeTour.status !== 'CREATING_PROJECT' ? 'opacity-0' : 'opacity-100'"
    >
      <CircularProgress
        class="animate-spin"
        size="sm"
      />
      Creating project ...
    </div>
  </div>

  <div
    ref="slotWrapper"
    class="contents"
  >
    <slot />
  </div>
</template>
