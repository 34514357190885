<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import type { Entity, Property } from './useProject'
import { useProject } from './useProject'
import { PropertyType, type SingleSelectPropertyConfig } from '@/backend/types'
import { computed, ref } from 'vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { getMemberName, useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { TYPE_ICON } from './icons'
import IconSprite from '@/uiKit/IconSprite.vue'

type SelectValues = Partial<Record<Entity['id'], string[] | null>>

const emit = defineEmits<{
  (e: 'setValue', propertyId: string, value: SelectValues): void
}>()

const workspaceMemberStore = useWorkspaceMembers()
const projectStore = useProject()
const activeViewProperties = computed(
  () =>
    projectStore.activeView?.view.propertyIds
      ?.map((id) => projectStore.propertiesById[id])
      .filter((p: Property | undefined): p is Property => !!p) ?? [],
)

type SelectItem = {
  id: string
  data: {
    id: string
    name: string
    options: Array<{
      id: string
      data: SingleSelectPropertyConfig['options'][number] & { name: string }
    }>
    type: PropertyType
  }
}

/** All of the select properties on the current project */
const selectProperties = computed(() =>
  activeViewProperties.value.reduce<SelectItem[]>((acc, curr) => {
    if (
      curr.type === PropertyType.single_select ||
      curr.type === PropertyType.multi_select ||
      curr.type === PropertyType.user_select
    ) {
      const options = curr.config?.options.map((o) => {
        const matchingWorkspaceMember = workspaceMemberStore.workspaceMembers.find(
          (m) => m.id === o.value,
        )
        const optionName =
          curr.type === PropertyType.user_select && matchingWorkspaceMember
            ? getMemberName(matchingWorkspaceMember)
            : o.value

        return {
          id: o.value,
          data: {
            ...o,
            name: optionName,
          },
        }
      })

      return [
        ...acc,
        {
          id: curr.id,
          data: {
            id: curr.id,
            name: curr.name,
            type: curr.type,
            options: options ?? [],
          },
        },
      ]
    }

    return acc
  }, []),
)

/** ID of the property that is selected */
const activeSelectId = ref<string | null>(null)

const activeSelectProperty = computed(() => {
  if (!activeSelectId.value) {
    return null
  }

  const property = selectProperties.value.find((p) => p.id === activeSelectId.value)
  if (!property) {
    return null
  }

  return { ...property.data, currentValues: [] }
})

const getField = (entityId: string, fieldId: string | null) => {
  if (
    !projectStore.activeView?.entityIdToIndex ||
    !projectStore.activeView.entities ||
    !activeSelectProperty.value ||
    !fieldId
  ) {
    return
  }
  const index = projectStore.activeView.entityIdToIndex.get(entityId)
  if (typeof index !== 'number') {
    return
  }
  const entity = projectStore.activeView.entities[index]
  if (!entity) {
    return
  }
  const field = entity.fields.get(fieldId)
  if (!field) {
    return
  }
  if (
    field.type !== PropertyType.single_select &&
    field.type !== PropertyType.multi_select &&
    field.type !== PropertyType.user_select
  ) {
    return
  }
  return field
}

/**
 * Given a list of entity IDs, returns a mapping of id to the values of the select property in the store
 */
const getFieldValuesFromStore = (entityIds: string[], fieldId: string | null) =>
  entityIds.reduce<Record<Entity['id'], string[] | null>>((acc, id) => {
    const field = getField(id, fieldId)
    if (!field) {
      return acc
    }

    return {
      ...acc,
      [id]: field.manualValue,
    }
  }, {})

/** Maps entities to the original values they had */
const originalValues = ref<Partial<Record<Entity['id'], string[] | null>>>({})
/** Maps entities to the values they have after a user has made changes */
const newValues = ref<Partial<Record<Entity['id'], string[] | null>>>({})

/**
 * Maps each option value to the number of entities that have that value selected
 */
const countByValue = computed(() =>
  Object.values(newValues.value).reduce<Partial<Record<string, number>>>((acc, value) => {
    const values = value ?? []
    values.forEach((v) => {
      acc[v] = (acc[v] ?? 0) + 1
    })
    return acc
  }, {}),
)

/**
 * Maps each option value to a boolean indicating whether all selected entities have
 * - True if all selected entities have this value selected
 * - False if none of the selected entities have this value selected
 * - 'indeterminate' if some of the selected entities have this value selected
 */
const optionStatus = computed<Partial<Record<string, boolean | 'indeterminate'>>>(() =>
  Object.entries(countByValue.value).reduce<Partial<Record<string, boolean | 'indeterminate'>>>(
    (acc, [value, count]) => {
      if (count === projectStore.selectedEntityIds.size) {
        acc[value] = true
      } else if (count === 0) {
        acc[value] = false
      } else {
        acc[value] = 'indeterminate'
      }
      return acc
    },
    {},
  ),
)

/** Handler for when the user clicks a select property */
const onClickSelectProperty = (item: SelectItem['data']) => {
  activeSelectId.value = item.id
  // Store the original values of the entities so we can revert if needed
  const valuesFromStore = getFieldValuesFromStore(
    Array.from(projectStore.selectedEntityIds),
    activeSelectId.value,
  )
  originalValues.value = { ...valuesFromStore }
  newValues.value = { ...valuesFromStore }
}

const isDirty = ref(false)
/**
 * Clear the active select property, changing the menu from rendering a list of options for a
 * given property, to rendering a list of select properties.
 */
const clearSelectProperty = () => {
  Object.entries(originalValues.value).forEach(([id, value]) => {
    if (
      !projectStore.activeView?.entityIdToIndex ||
      !projectStore.activeView.entities ||
      !activeSelectProperty.value
    ) {
      return
    }
    const index = projectStore.activeView.entityIdToIndex.get(id)
    if (typeof index !== 'number') {
      return
    }
    const entity = projectStore.activeView.entities[index]
    if (!entity) {
      return
    }
    const field = entity.fields.get(activeSelectProperty.value.id)
    if (!field) {
      return
    }
    if (
      field.type !== PropertyType.single_select &&
      field.type !== PropertyType.multi_select &&
      field.type !== PropertyType.user_select
    ) {
      return
    }
    entity.fields.set(activeSelectProperty.value.id, {
      ...field,
      manualValue: value ?? null,
    })
  })
  activeSelectId.value = null
  isDirty.value = false
}

/**
 * Handler for when the user clicks an option in the select property menu.
 */
const onClickOption = (option: SelectItem['data']['options'][number]['data']) => {
  isDirty.value = true
  const action = [false, 'indeterminate'].includes(optionStatus.value[option.value] ?? false)
    ? 'add'
    : 'remove'

  if (!activeSelectProperty.value) {
    return
  }

  projectStore.selectedEntityIds.forEach((id) => {
    if (
      !projectStore.activeView?.entityIdToIndex ||
      !projectStore.activeView.entities ||
      !activeSelectProperty.value
    ) {
      return
    }
    const index = projectStore.activeView.entityIdToIndex.get(id)
    if (typeof index !== 'number') {
      return
    }
    const entity = projectStore.activeView.entities[index]
    if (!entity) {
      return
    }
    const field = entity.fields.get(activeSelectProperty.value.id)
    if (!field) {
      return
    }
    if (
      field.type !== PropertyType.single_select &&
      field.type !== PropertyType.multi_select &&
      field.type !== PropertyType.user_select
    ) {
      return
    }

    let manualValue = newValues.value[id] ?? []

    if (action === 'remove') {
      manualValue = manualValue.filter((v) => v !== option.value)
    } else if (!manualValue.includes(option.value)) {
      if (activeSelectProperty.value.type === PropertyType.single_select) {
        manualValue = [option.value]
      } else {
        manualValue = [...manualValue, option.value]
      }
    }

    newValues.value[id] = manualValue
    entity.fields.set(activeSelectProperty.value.id, {
      ...field,
      manualValue,
    })
  })
}

const isMenuOpen = ref(false)
const onToggleMenu = () => {
  if (isMenuOpen.value && isDirty.value) {
    if (activeSelectProperty.value) {
      if (activeSelectProperty.value.type === PropertyType.multi_select) {
        emit('setValue', activeSelectProperty.value.id, newValues.value)
      } else {
        /**
         * It sucks that we have to do this, but there is an impossible to reproduce
         * bug (that has been observed happening on PostHog) where the values are
         * only set for a subset of the selected entities.
         *
         * This is a workaround for that, but we can only do it for single and user
         * select properties, because we know that we will be setting the same value
         * for all entities. Multi select properties can have different values for
         * each entity, so we can't use this workaround.
         */
        const selectedValue = Object.values(newValues.value)[0]
        emit('setValue', activeSelectProperty.value.id, {
          ...Object.fromEntries(
            Array.from(projectStore.selectedEntityIds).map((id) => [id, selectedValue]),
          ),
        })
      }
    }
    activeSelectId.value = null
  }
  isMenuOpen.value = !isMenuOpen.value
}

const valueToColorMap = computed(() =>
  Object.fromEntries(
    activeSelectProperty.value?.options.map((o) => [o.data.value, o.data.color]) ?? [],
  ),
)
</script>

<template>
  <PopupMenu
    :open="isMenuOpen"
    :placement="'top'"
    trigger-element="div"
    @change:open="onToggleMenu"
  >
    <template #trigger>
      <DarwinButton
        size="sm"
        variant="transparent"
        :class="isMenuOpen && '!bg-background-transparent-hovered'"
        @click="onToggleMenu"
      >
        Set Value
      </DarwinButton>
    </template>
    <template #dropdown>
      <ListMenu
        v-if="activeSelectProperty"
        :items="activeSelectProperty.options"
        search-by-field="name"
        :search-placeholder="`Set value for ${activeSelectProperty.name}`"
        @keydown.escape.stop="clearSelectProperty"
        @select="(item) => onClickOption(item)"
      >
        <template #item="{ active, item, key, setActiveItem }">
          <ListMenuCheckboxItem
            v-if="activeSelectProperty.type === PropertyType.multi_select"
            element="div"
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            always-visible
            :checked="optionStatus[item.data.value] === true"
            :indeterminate="optionStatus[item.data.value] === 'indeterminate'"
            @mousemove="setActiveItem(key)"
            @select="onClickOption(item.data)"
          >
            <div class="flex grow justify-start">
              <BadgeItem
                size="sm"
                :label="item.data.value"
                variant="blue"
                :rainbow-color="valueToColorMap[item.data.value]"
              />
            </div>
            <template #suffix>
              <div
                v-if="countByValue[item.data.value]"
                class="text-xs-11px-default text-text-subtlest"
              >
                {{ countByValue[item.data.value] }}
              </div>
            </template>
          </ListMenuCheckboxItem>
          <ListMenuItem
            v-else
            element="div"
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onClickOption(item.data)"
          >
            <template #prefix>
              <IconSprite
                :icon="optionStatus[item.data.value] === true ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            <div class="flex grow justify-start">
              <BadgeItem
                size="sm"
                :label="item.data.name"
                variant="blue"
                :rainbow-color="valueToColorMap[item.data.value]"
              />
            </div>
            <template #suffix>
              <div
                v-if="countByValue[item.data.value]"
                class="text-xs-11px-default text-text-subtlest"
              >
                {{ countByValue[item.data.value] }}
              </div>
            </template>
          </ListMenuItem>
        </template>
      </ListMenu>
      <ListMenu
        v-else
        :items="selectProperties"
        search-by-field="name"
        search-placeholder="Set value for"
        @select="onClickSelectProperty"
      >
        <template #item="{ active, item, key, setActiveItem }">
          <ListMenuItem
            element="div"
            :label="item.data.name"
            :active="active"
            :aria-selected="active"
            :icon="TYPE_ICON[item.data.type]"
            @mousemove="setActiveItem(key)"
            @select="onClickSelectProperty(item.data)"
          >
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
