<script setup lang="ts">
/**
 * Button to open workspace deletion dialog and the dialog itself.
 * Holds the full workspace deletion flow.
 */
import { ref } from 'vue'

import DarwinButton from '@/uiKit/DarwinButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import TextField from '@/uiKit/TextField.vue'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'

import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'
import { deleteWorkspace } from '@/backend/deleteWorkspace'

const emit = defineEmits<{ (e: 'close'): void }>()

const workspaceStore = useWorkspaces()

const workspaceName = ref('')
const workspaceNameError = ref<string>()

const setWorkspaceName = (name: string) => {
  workspaceNameError.value = undefined
  workspaceName.value = name
}

const validateAndDispatch = async () => {
  workspaceNameError.value = undefined
  if (workspaceName.value !== workspaceStore.currentWorkspace?.name) {
    workspaceNameError.value = 'Name does not match'
    return
  }

  const result = await deleteWorkspace(workspaceStore.currentWorkspace?.id)
  if (!result.ok) {
    workspaceNameError.value = 'There was an error when deleting the workspace'
    return
  }
  workspaceStore.setCurrentWorkspace(undefined)
  window.location.href = '/'
}
</script>

<template>
  <ModalDialog
    open
    to="#main"
    @close="emit('close')"
  >
    <template #header>
      <ModalDialogHeader
        title="Delete workspace"
        description="This will permanently delete this workspace. This includes all projects, users or other data. This action cannot be undone."
      />
    </template>
    <template #body>
      <form
        id="create-project"
        class="flex flex-col"
        @submit.prevent="validateAndDispatch"
      >
        <TextField
          size="md"
          :error="workspaceNameError"
          :value="workspaceName"
          placeholder="Type in the full workspace name"
          :label="`Please type the workspace name '${workspaceStore.currentWorkspace?.name ?? ''}' to confirm`"
          @change="setWorkspaceName"
        />
      </form>
    </template>
    <template #footer="{ close }">
      <DarwinButton
        variant="neutral"
        size="lg"
        @click="close"
        >Cancel</DarwinButton
      >
      <DarwinButton
        flair="soft"
        form="create-project"
        variant="critical"
        size="lg"
        type="submit"
      >
        Delete
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
