import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { components } from '@/api'
import type { PropertyType } from '@/backend/types'

// For now, skipping serialization of filters
export type GroupFilter = components['schemas']['EntityFilters.GroupFilter']
export type SimpleFilter = components['schemas']['EntityFilters.SimpleFilter']
export type Filter = GroupFilter | SimpleFilter
export type SelectOptionValueFilter = components['schemas']['EntityFilters.SelectOptionValue']
export type TextValueFilter = components['schemas']['EntityFilters.TextValue']

export type Conjunction = 'and' | 'or'

export const parseConjunction = (conjunction: string) => {
  if (conjunction === 'and') {
    return 'match all filters'
  }
  return 'match any filter'
}

/**
 * 'property_not_set' is a FE-only value that we convert to is_none_of <all_options>
 * when sending to the backend.
 */
export type MatcherName = SimpleFilter['matcher']['name'] | 'property_not_set'

/**
 * From a matcher name, amount and property type, return a human-readable string.
 * e.g. 'property_any_of', 3, 'multi_select' => 'is any of'
 * e.g. 'property_none_of', 1, 'single_select' => 'is not'
 */
export const parseMatcherName = (matcherParams: {
  matcherName: MatcherName
  amount?: number
  type: PropertyType
}) => {
  if (matcherParams.type === 'text') {
    if (matcherParams.matcherName === 'property_any_of') {
      return 'is'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return 'is not'
    } else if (matcherParams.matcherName === 'property_contains_any_of') {
      return 'contains'
    } else if (matcherParams.matcherName === 'property_contains_none_of') {
      return 'does not contain'
    }
  }

  if (matcherParams.type === 'single_select' || matcherParams.type === 'user_select') {
    if (matcherParams.matcherName === 'property_any_of') {
      return matcherParams.amount === 1 ? 'is' : 'is any of'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return matcherParams.amount === 1 ? 'is not' : 'is none of'
    }
  }

  if (matcherParams.type === 'multi_select') {
    if (matcherParams.matcherName === 'property_any_of') {
      return 'is any of'
    } else if (matcherParams.matcherName === 'property_all_of') {
      return 'is all of'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return 'is none of'
    }
  }

  if (matcherParams.matcherName === 'property_not_set') {
    return 'is not set'
  }

  return matcherParams.matcherName
}

/**
 * The project table can show a filtered list of entities on that project. This
 * store holds the state of the filters that are currently applied, and provides
 * functions to add/remove/update filters.
 */
export const useFilters = defineStore('filters', () => {
  const currentFilter = ref<GroupFilter>()

  const setCurrentFilter = (filter?: GroupFilter) => {
    currentFilter.value = filter
  }

  const addFilter = (filter: Filter) => {
    if (!currentFilter.value) {
      currentFilter.value = {
        conjunction: 'and',
        filters: [filter],
      }
      return
    }

    currentFilter.value.filters.push(filter)
  }

  const updateFilter = (index: number, filter: Filter) => {
    currentFilter.value?.filters.splice(index, 1, filter)
  }

  const removeFilter = (index: number) => {
    currentFilter.value?.filters.splice(index, 1)
  }

  const setConjunction = (conjunction: Conjunction) => {
    if (!currentFilter.value) {
      currentFilter.value = {
        conjunction,
        filters: [],
      }
      return
    }

    currentFilter.value.conjunction = conjunction
  }

  /** Whether the filter bar is currently visible */
  const viewFilters = ref<boolean>(false)

  return {
    currentFilter,
    setCurrentFilter,
    addFilter,
    updateFilter,
    removeFilter,
    setConjunction,
    viewFilters,
  }
})
