<script setup lang="ts">
import { RouterLink } from 'vue-router'

import IconButton from '@/uiKit/IconButton.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'
import { computed } from 'vue'
import { useRoutes } from '../App/useRoutes'

const props = defineProps<{
  isLayoutLocked: boolean
  projectId: string
  hasPrevious?: boolean
  hasNext?: boolean
  viewId?: string
}>()

defineEmits<{
  (e: 'update:isLayoutLocked', value: boolean): void
  (e: 'prev' | 'next'): void
}>()

const { canEditProjects } = useWorkspacePermissions()

const { currentlyInSubProject, route } = useRoutes()

const closeRouteTo = computed(() => {
  if (currentlyInSubProject) {
    return !props.viewId
      ? {
          name: 'WorkspaceSubProjectTable',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
          },
          query: { parentEntityId: route.query.parentEntityId },
        }
      : {
          name: 'WorkspaceSubProjectTableView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
            viewId: props.viewId,
          },
          query: { parentEntityId: route.query.parentEntityId },
        }
  }

  return !props.viewId
    ? {
        name: 'WorkspaceProjectTable',
        params: {
          workspaceId: route.params.workspaceId,
          projectId: route.params.projectId,
        },
      }
    : {
        name: 'WorkspaceProjectTableView',
        params: {
          workspaceId: route.params.workspaceId,
          projectId: route.params.projectId,
          viewId: props.viewId,
        },
      }
})
</script>

<template>
  <div class="grid grid-cols-3 items-center gap-1 p-2">
    <div>
      <RouterLink
        :to="closeRouteTo"
        class="inline-block"
        tabindex="-1"
      >
        <IconButton
          size="lg"
          variant="transparent"
          icon="close"
          data-test="close-entity-button"
        />
      </RouterLink>
    </div>
    <div class="flex grow justify-center">
      <div class="inline-flex items-center justify-start gap-1.5">
        <IconButton
          icon="chevron-top"
          size="lg"
          variant="transparent"
          data-test="previous-entity-button"
          :disabled="!hasPrevious"
          aria-label="Previous entity"
          @click="$emit('prev')"
        />
        <IconButton
          icon="chevron-bottom"
          size="lg"
          variant="transparent"
          data-test="next-entity-button"
          aria-label="Next entity"
          :disabled="!hasNext"
          @click="$emit('next')"
        />
      </div>
    </div>
    <div class="flex items-center justify-end">
      <ToolTip
        :arrow="true"
        :placement="{ allowedPlacements: ['left'] }"
        :title="isLayoutLocked ? 'Unlock layout' : 'Lock layout'"
      >
        <SwitchButton
          v-if="canEditProjects"
          color="neutral"
          :checked="isLayoutLocked"
          :icon="isLayoutLocked ? 'lock-fill' : 'unlock'"
          aria-label="Lock layout"
          size="xl"
          @change="$emit('update:isLayoutLocked', $event)"
        />
      </ToolTip>
    </div>
  </div>
</template>
