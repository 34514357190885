import type { operations } from '@/api'
import { post } from './client'

export type RecalculateEntityPath =
  `/api/workspaces/${string}/projects/${string}/entities/${string}/recalculate`
export type RecalculateEntityRequest = Exclude<
  operations['entity-recalculate-single']['requestBody'],
  undefined
>['content']['application/json']
export type RecalculateEntityResponse = Exclude<
  operations['entity-recalculate-single']['responses']['200'],
  undefined
>['content']['application/json']

export const recalculateEntity = (
  workspaceId: string,
  projectId: string,
  entityId: string,
  propertyIds: string[],
) =>
  post<RecalculateEntityRequest, RecalculateEntityResponse, RecalculateEntityPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}/recalculate`,
    {
      property_ids: propertyIds,
      force: false,
    },
  )
