<script setup lang="ts">
import { ref } from 'vue'

import DarwinButton from '@/uiKit/DarwinButton.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import WorkspaceDeletionDialog from '@/modules/WorkspaceSettings/WorkspaceDeletionDialog.vue'
import { serializeWorkspace, useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { updateWorkspace } from '@/backend/updateWorkspace'
import { toast } from '@/shared/toast'
import { useCurrentWorkspace } from '../Workspaces/useCurrentWorkspace'
import WorkspaceSettingsIconDropdown from './WorkspaceSettingsIconDropdown.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'

const workspaceStore = useWorkspaces()
const currentWorkspace = useCurrentWorkspace()

const editWorkspaceName = ref<string>(currentWorkspace.value.name)

const isDeleting = ref(false)

const saveWorkspaceNameBlur = async () => {
  if (editWorkspaceName.value.trim() === '') {
    editWorkspaceName.value = currentWorkspace.value.name
    return
  }
  saveWorkspaceName(editWorkspaceName.value)
}
const saveWorkspaceName = async (name: string) => {
  if (editWorkspaceName.value === currentWorkspace.value.name) {
    return
  }

  const result = await updateWorkspace(currentWorkspace.value.id, { name })
  if (result.ok) {
    workspaceStore.updateWorkspace(serializeWorkspace(result.data))
    toast.success('Workspace name updated successfully')
  } else {
    toast.error('Failed to update workspace name')
  }
}

const onImageSrcError = () => {
  workspaceStore.setIconDownloadError(currentWorkspace.value.id, true)
}
</script>

<template>
  <div class="mx-auto flex w-[768px] flex-col gap-8 py-16">
    <div class="flex flex-col gap-1">
      <h2 class="text-xl-18px-bold text-text">Workspace</h2>
      <p class="text-sm-12px-light text-text-subtle">
        Change the settings for your current workspace here.
      </p>
    </div>

    <div
      class="flex flex-col items-center justify-center gap-4 rounded-xl bg-surface-secondary p-8"
    >
      <div>
        <div
          class="relative rounded-corner-20 border-2 border-dashed border-background-border-default p-0.5"
        >
          <div
            v-if="currentWorkspace.iconUploading"
            class="flex size-[64px] items-center justify-center"
          >
            <CircularProgress
              size="xs"
              class="animate-spin"
            />
          </div>
          <AvatarIcon
            v-else
            :full-text="currentWorkspace.name"
            size="xl"
            :url="currentWorkspace.iconUrl"
            :loading-condition="currentWorkspace.iconUploading ?? false"
            :error-condition="currentWorkspace.iconDownloadError"
            @error="onImageSrcError"
          />
          <div
            class="absolute -bottom-1.5 -right-1.5 flex items-center rounded-corner-12 bg-surface-primary p-0.5 shadow-xs"
          >
            <WorkspaceSettingsIconDropdown :workspace-id="currentWorkspace.id" />
          </div>
        </div>
      </div>

      <InlineTextField
        class="inline-flex"
        centered
        size="sm"
        :value="editWorkspaceName"
        @input="editWorkspaceName = $event"
        @submit="saveWorkspaceName"
        @blur="saveWorkspaceNameBlur()"
        @esc="editWorkspaceName = workspaceStore.currentWorkspace?.name ?? ''"
      />
    </div>
    <DividerLine
      color="subtle"
      :width="1"
      class="mb-0.5"
    />

    <div class="flex flex-row items-center justify-between align-middle">
      <div class="flex flex-col gap-1">
        <h3 class="text-md-13px-bold text-text">Danger Zone</h3>
        <p class="text-sm-12px-light text-text-subtle">
          This will delete all datasets and remove all members from the workspace
        </p>
      </div>
      <DarwinButton
        class="w-[160px]"
        size="sm"
        variant="critical"
        @click="isDeleting = true"
        >Delete Workspace
      </DarwinButton>
    </div>

    <WorkspaceDeletionDialog
      v-if="isDeleting"
      @close="isDeleting = false"
    />
  </div>
</template>
