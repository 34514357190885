<script setup lang="ts">
import { ref } from 'vue'

import type { PropertyType } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import PropertyTypePicker from './PropertyTypePicker.vue'
import IconButton from '@/uiKit/IconButton.vue'

withDefaults(
  defineProps<{
    propertyId?: string
    name: string
    type: PropertyType
    showAdvanced?: boolean
    placeholder?: string
  }>(),
  {
    propertyId: undefined,
    placeholder: undefined,
    showAdvanced: true,
  },
)
defineEmits<{
  (e: 'update' | 'create', v: { name: string; type: PropertyType }): void
  (e: 'delete'): void
}>()

defineExpose({
  focus: () => input.value.focus(),
})

const input = ref()
</script>

<template>
  <div
    v-bind="$attrs"
    class="group/option flex h-7 items-center gap-0.5 rounded-lg bg-background-transparent p-0.5 hover:bg-background-transparent-hovered focus-visible:outline-none active:hover:bg-background-transparent-hovered"
    aria-label="Collection Type Property"
  >
    <div class="flex grow items-center gap-0.5 rounded-lg py-0.5">
      <PropertyTypePicker
        v-if="showAdvanced"
        :value="type"
        @change="$emit('update', { name, type: $event })"
      />
      <IconSprite
        v-else
        class="size-6 text-icon-subtlest"
        size="md"
        icon="search"
        @click="input.focus()"
      />
      <InlineTextField
        ref="input"
        size="xs"
        :value="name"
        class="w-full min-w-1/2"
        :class="!showAdvanced && 'hover:[&:not(:disabled)]:bg-background-transparent'"
        variant="transparent"
        :placeholder="placeholder"
        @input="$emit('update', { type, name: $event.trim() })"
        @submit="$emit('create', { type, name: $event.trim() })"
      />
    </div>
    <div class="flex">
      <IconButton
        v-if="showAdvanced"
        aria-label="Delete Collection Property"
        icon="trash"
        size="md"
        class="invisible self-center group-focus-within/option:visible group-hover/option:visible"
        variant="transparent"
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>
