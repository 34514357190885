<script lang="ts">
const isOpen = ref(false)

export function useTemplateModal() {
  return {
    open() {
      isOpen.value = true
    },
    close() {
      isOpen.value = false
    },
    isOpen,
  }
}
</script>

<script setup lang="ts">
import Illustration from '@/assets/images/illustration.svg'
import { useTemplates } from '@/modules/Projects/useTemplates'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import TextField from '@/uiKit/TextField.vue'
import { refDebounced, useIntervalFn } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useCreateProject } from '../Projects/useCreateProject'
import TemplateCard from './TemplateCard.vue'

const props = defineProps<{
  workspaceId: string
}>()

const search = ref('')
const searchDebounced = refDebounced(search, 300, { maxWait: 1000 })

const isCloning = ref(false)
function closeModal() {
  if (isCloning.value) return
  isOpen.value = false
}

watch(
  () => isOpen.value,
  () => {
    if (!isOpen.value) {
      search.value = ''
    }
  },
)

const templateStore = useTemplates()
useIntervalFn(
  () => {
    templateStore.refreshTemplates()
  },
  1000 * 60 * 5, // Refresh every 5 minutes, to minimize FOUC and unsigned images
  { immediateCallback: true },
)

watch(
  () => isOpen.value,
  (open) => {
    if (!open) return
    templateStore.refreshTemplates()
  },
)

const filteredTemplates = computed(() => {
  return templateStore.templates.filter((template) => {
    if (template.name === null) {
      return false
    }
    if (template.parent_property) {
      return false
    }
    return template.name.toLowerCase().includes(searchDebounced.value.toLowerCase())
  })
})

const { createNewProject } = useCreateProject()
const onCreateNewProject = async () => {
  isCloning.value = true
  await createNewProject(props.workspaceId)
  isCloning.value = false
}
</script>

<template>
  <ModalDialog
    :open="isOpen"
    disable-focus-trap
    @close="closeModal"
  >
    <div
      ref="scrollable"
      class="flex max-h-[640px] w-[900px] flex-col overflow-y-auto rounded-corner-16 bg-surface-primary-persist scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      data-test="template-modal"
    >
      <div
        class="relative flex shrink-0 flex-col items-center justify-center gap-1 bg-surface-secondary-persist p-12 pt-8"
      >
        <IconButton
          icon="close"
          variant="neutral"
          size="md"
          rounded
          class="absolute right-4 top-4"
          :disabled="isCloning"
          @click="closeModal"
        />
        <div class="flex flex-col items-center justify-center gap-4">
          <Illustration />

          <div class="flex flex-col items-center justify-center gap-1">
            <h2 class="text-display-sm-24px-bold text-text">What will you build?</h2>
            <p class="text-sm-12px-default text-text-subtle">Explore our library of templates.</p>
          </div>

          <div class="flex items-center">
            <TextField
              size="md"
              placeholder="Search templates"
              aria-label="Search templates"
              :value="search"
              class="relative z-30 w-80 transition-transform duration-200"
              autofocus
              :disabled="isCloning"
              @input="(s) => (search = s)"
            >
              <template #leading-icon>
                <IconSprite
                  icon="search"
                  class="text-icon-subtle"
                />
              </template>
              <template #trailing-icon>
                <div class="flex w-8 translate-x-2.5 justify-end overflow-hidden">
                  <div
                    class="transition-all duration-300"
                    :class="[
                      search === searchDebounced
                        ? 'translate-x-0 opacity-0 delay-100'
                        : '-translate-x-2.5 opacity-100',
                    ]"
                  >
                    <CircularProgress
                      class="animate-spin"
                      :value="0.3"
                      size="sm"
                    />
                  </div>
                </div>
              </template>
            </TextField>
          </div>
        </div>
      </div>
      <div class="relative flex size-full grow flex-col">
        <div
          v-if="isCloning"
          class="flex h-[264px] flex-col items-center justify-center gap-2"
        >
          <CircularProgress
            size="sm"
            class="animate-spin"
          />
          <p class="text-sm-12px-default text-text-subtle">A new project is being created...</p>
        </div>

        <div
          v-if="filteredTemplates.length"
          class="grid grid-cols-3 gap-6 p-8"
          :class="[isCloning ? 'hidden' : '']"
        >
          <button
            v-if="!searchDebounced"
            class="flex h-full flex-col items-center justify-center gap-2 rounded-corner-8 border border-border-subtle bg-surface-secondary-persist transition hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
            @click="
              () => {
                templateStore.startFromScratch(workspaceId).finally(() => {
                  isCloning = false
                  isOpen = false
                })
                isCloning = true
              }
            "
          >
            <IconSprite
              icon="circle-plus"
              class="text-icon"
            />
            <p class="text-sm-12px-default text-text">Start from scratch</p>
          </button>
          <TemplateCard
            v-for="template in filteredTemplates"
            :key="template.id"
            :template="template"
            :workspace-id="workspaceId"
            @is-cloning="isCloning = true"
            @close="
              () => {
                isCloning = false
                closeModal()
              }
            "
          />
        </div>
        <!-- Empty state -->
        <div
          v-else
          class="flex h-[264px] flex-col items-center justify-center gap-2"
        >
          <IconSprite
            icon="search"
            size="md"
            class="text-icon-subtle"
          />
          <p class="mb-2 text-sm-12px-light text-text-subtle">No templates matching your search</p>
          <DarwinButton
            variant="black"
            size="sm"
            @click="onCreateNewProject"
          >
            Start from scratch
          </DarwinButton>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
