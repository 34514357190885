import { useLDClient } from './useLDClient'

export const LDEvents = {
  TIME_SPENT_ON_EDITING: 'time-spent-on-editing',
}

interface PerformanceStore {
  [key: string]: number | undefined
}

export const useABMetrics = () => {
  const ld = useLDClient()
  const perfomanceStore: PerformanceStore = {}

  const startTrackingTimeFor = (eventName: string) => {
    perfomanceStore[eventName] = performance.now()
  }

  const trackElapsedTimeEvent = (eventName: string) => {
    const startTime = perfomanceStore[eventName]
    if (typeof startTime === 'number') {
      const duration = performance.now() - startTime
      trackEvent(eventName, { duration })
      perfomanceStore[eventName] = undefined
    }
  }

  const trackEvent = (eventName: string, args: object) => {
    const isEventExists = Object.values(LDEvents).includes(eventName)
    // console.log('trackEvent', eventName, args)

    if (!isEventExists) return
    ld.client?.track(eventName, args)
  }

  return { trackEvent, trackElapsedTimeEvent, startTrackingTimeFor }
}
