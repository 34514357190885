<script setup lang="ts">
import type { Plan } from '@/modules/Billing/types'
import { computed } from 'vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import LimitUsage from '@/sharedComponents/LimitUsage.vue'
import type { LimitWithUsage } from '@/modules/Billing/types'
import { PLAN_PRICES } from '@/modules/Billing/constants'
import { useStripeCheckout } from '../Billing/useStripeCheckout'
import { format as formatDate } from 'date-fns'

const props = defineProps<{
  plan: Plan
  fieldUsage: LimitWithUsage
  tokenUsage: LimitWithUsage
  workspaceId: string
}>()

const nameMap: Record<Plan['name'], string> = {
  free: 'Free',
  pro: 'Pro',
  enterprise: 'Enterprise',
}
const planName = computed(() => nameMap[props.plan.name])

const price = computed(() =>
  props.plan.name === 'enterprise' ? null : PLAN_PRICES[props.plan.name][props.plan.billingPeriod],
)

const isProOrEnterprise = computed(() => {
  return props.plan.name === 'pro' || props.plan.name === 'enterprise'
})

const formatCurrency = new Intl.NumberFormat(undefined, {
  currency: 'USD',
  style: 'currency',
  currencyDisplay: 'narrowSymbol',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format

const { startStripeCheckout, isLoadingCheckoutUrl } = useStripeCheckout()

/** The date on which the billing period limits will reset */
const formattedResetDate = computed(() => formatDate(props.plan.currentPeriodEnd, 'MMMM d, yyyy'))
</script>

<template>
  <div class="overflow-hidden rounded-xl border border-border-subtle">
    <div class="flex justify-between bg-surface-secondary p-6">
      <div>
        <h2 class="mb-1 text-xl-18px-bold text-text">You are on the {{ planName }} plan</h2>
        <div
          v-if="typeof price === 'number'"
          class="text-sm-12px-light text-text-subtle"
        >
          {{ formatCurrency(price) }} billed {{ plan.billingPeriod }}
        </div>
      </div>
      <div class="flex items-start gap-3">
        <DarwinButton
          variant="neutral"
          size="sm"
          :to="{ name: 'WorkspaceSettingsPlans' }"
          >Explore plans</DarwinButton
        >
        <DarwinButton
          v-if="isProOrEnterprise"
          variant="black"
          size="sm"
          target="_blank"
          href="http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=billing-usage-banner-pro-enterprise-user"
          >Contact Sales</DarwinButton
        >
        <DarwinButton
          v-else
          variant="black"
          size="sm"
          :loading="isLoadingCheckoutUrl"
          @click="
            startStripeCheckout({
              billingPeriod: 'monthly',
              workspaceId: workspaceId,
            })
          "
          >Upgrade</DarwinButton
        >
      </div>
    </div>
    <div class="grid grid-cols-2 gap-6 p-6">
      <LimitUsage
        id="billing-field-usage"
        title="Fields usage"
        title-tag="h3"
        :current-usage="fieldUsage.limitUsage"
        :max-usage="fieldUsage.limitValue"
        data-test="billing-field-usage"
      />
      <div>
        <LimitUsage
          id="billing-token-usage"
          title="Go tokens"
          title-tag="h3"
          :current-usage="tokenUsage.limitUsage"
          :max-usage="tokenUsage.limitValue"
          data-test="billing-token-usage"
        />
        <p
          v-if="isProOrEnterprise"
          class="mt-2 text-xs-11px-default text-text-subtlest"
        >
          Tokens will reset on {{ formattedResetDate }}
        </p>
      </div>
    </div>
  </div>
</template>
