<script setup lang="ts">
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useWebSocket } from '@/sharedComposables/useWebSocket'
import { RouterView, useRoute } from 'vue-router'

import SignupForm from '@/modules/Signup/SignupForm.vue'
import GoToaster from '@/shared/toast/GoToaster.vue'
import { useLoadIntercom } from '@/sharedComposables/useLoadIntercom'
import { ref, watch } from 'vue'
import { useUser } from '../IdentityAndAccess/useUser'
import WelcomeTour from '../WelcomeTour/WelcomeTour.vue'
import { useImports } from '../Workspaces/useImports'
import AppPopover from './AppPopover.vue'
import { useSentryTracking } from './useSentryTracking'
import { useTheme } from './useTheme'
import MobileUxWarning from './MobileUxWarning.vue'
import { useIsMobile } from '@/sharedComposables/useIsMobile'

const ws = useWebSocket()
ws.connect()

const { captureAnalyticsEvent } = useAnalytics()
const route = useRoute()
watch(
  () => route.fullPath,
  (newPath, oldPath) => {
    if (oldPath) {
      captureAnalyticsEvent(ANALYTICS_EVENT.PAGELEAVE, {
        $current_url: oldPath,
      })
    }

    captureAnalyticsEvent(ANALYTICS_EVENT.PAGEVIEW, {
      $current_url: newPath,
    })
  },
)

useSentryTracking()
useLoadIntercom()

const { actualTheme } = useTheme()

const userStore = useUser()

const imports = useImports()
imports.init()

const isMobile = useIsMobile()
const hasDismissedMobileWarning = ref(false)
</script>

<template>
  <div class="flex size-full flex-col gap-2 bg-surface-primary">
    <div
      id="main"
      class="relative size-full min-w-0"
    >
      <WelcomeTour>
        <RouterView />
      </WelcomeTour>
      <!--
        These components all require LaunchDarkly to be initialised
        (which happens when the user is set).
      -->
      <template v-if="userStore.user">
        <MobileUxWarning
          v-if="isMobile && !hasDismissedMobileWarning"
          @dismiss="hasDismissedMobileWarning = true"
        />
        <SignupForm v-else />
      </template>
    </div>
  </div>

  <Teleport to="body">
    <GoToaster :theme="actualTheme" />
  </Teleport>

  <AppPopover />
</template>

<style>
[data-go-toaster] [data-icon] {
  width: 28px !important;
  height: 28px !important;
  position: relative !important;

  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: 0.5px !important;
}

[data-go-toaster] [data-icon] > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
