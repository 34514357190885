<script setup lang="ts">
/**
 * Top left dropdown showing login status, workspace list and workspace creation.
 */
import { useAuth0 } from '@auth0/auth0-vue'
import { computed, ref } from 'vue'

import CurrentWorkspace from '@/modules/Workspaces/CurrentWorkspace.vue'
import WorkspaceCreationDialog from '@/modules/Workspaces/WorkspaceCreationDialog.vue'

import { useTheme } from '@/modules/App/useTheme'
import { GO_DOCS_REFERENCE_URL, omit } from '@/shared/utils'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { useRouter } from 'vue-router'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'
import { useWorkspaceOwnership } from './useWorkspaceOwnership'

defineProps<{ displayCurrentWorkspaceName?: boolean }>()
const { logout: logoutBase } = useAuth0()

const { theme, setTheme, themePreference } = useTheme()

const store = useWorkspaces()

const router = useRouter()

const isCreating = ref(false)

const currentWorkspaceId = computed(() => store.currentWorkspace?.id)

const { isAllowedToCreate: isUserAllowedToCreateWorkspace } = useWorkspaceOwnership(
  store.workspaces,
)

const { captureAnalyticsEvent } = useAnalytics()

const onSelect = (id: string) => {
  if (id === 'create-workspace') {
    isCreating.value = true
  } else if (id === 'settings') {
    captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_SETTINGS_MENU)
    router.push({ name: 'WorkspaceSettings' })
  } else if (id === 'help') {
    captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_HELP_CENTER)
    window.open(GO_DOCS_REFERENCE_URL, '_blank')
  } else if (id === 'logout') {
    logout()
  } else if (id === 'theme-light') {
    setTheme('light')
  } else if (id === 'theme-dark') {
    setTheme('dark')
  } else if (id === 'theme-default') {
    setTheme('auto')
  } else {
    router.push(`/${id}`)
  }
}

const formatWorkspaceRole = (role: string) => {
  return role.charAt(0).toUpperCase() + role.slice(1)
}

const logout = () => logoutBase({ logoutParams: { returnTo: window.location.origin } })

const onImageSrcError = (workspaceId: string) => {
  store.setIconDownloadError(workspaceId, true)
}

const { canAccessSettings } = useWorkspacePermissions()
const sortedWorkspaces = computed(() => {
  return store.workspaces.toSorted((a, b) =>
    // puts first the active workspace but also sorts the rest alphabetically
    a.id === currentWorkspaceId.value ? -1 : a.name.localeCompare(b.name),
  )
})
</script>

<template>
  <FloatingMenu
    :positioning="{ placement: 'bottom-end', offset: { mainAxis: 2 } }"
    @select="onSelect"
  >
    <template #trigger="{ triggerProps, open }">
      <CurrentWorkspace
        class="max-w-full"
        :class="open && 'bg-background-transparent-hovered'"
        :display-name="displayCurrentWorkspaceName"
        tabindex="1"
        v-bind="omit(triggerProps, ['disabled'])"
      />
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="z-10 w-[320px]"
      >
        <div
          class="max-h-[400px] w-full overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
        >
          <div class="flex w-full flex-col p-0.5">
            <template
              v-for="workspace in sortedWorkspaces"
              :key="workspace.id"
            >
              <ListMenuItem
                :id="workspace.id.toString()"
                class="w-full py-2"
                v-bind="omit(getItemProps({ value: workspace.id.toString() }), ['onSelect'])"
                :active="workspace.id === currentWorkspaceId"
              >
                <template #prefix>
                  <div
                    class="rounded-[9px] p-0.5"
                    :class="
                      workspace.id === currentWorkspaceId
                        ? 'border-2 border-border-focused-transparent'
                        : 'p-1'
                    "
                  >
                    <ObjectURLImage
                      v-if="!workspace.iconDownloadError && workspace.iconUrl"
                      class="size-7 rounded-corner-6 object-cover"
                      :url="workspace.iconUrl"
                      :loading="workspace.iconUploading ?? false"
                      @error="onImageSrcError(workspace.id)"
                    />
                    <AvatarIcon
                      v-else
                      :full-text="workspace.name"
                      class="size-7"
                    />
                  </div>
                </template>
                <template #default>
                  <div class="mr-2 flex flex-col px-1">
                    <p class="truncate text-sm-12px-default leading-4">{{ workspace.name }}</p>
                    <p
                      v-if="workspace.role"
                      class="text-xs-11px-default leading-4 text-text-subtlest"
                    >
                      {{ formatWorkspaceRole(workspace.role) }}
                    </p>
                  </div>
                </template>
              </ListMenuItem>
            </template>
          </div>
        </div>
        <DividerLine
          color="subtle"
          :width="1"
        />
        <ListMenuItem
          v-if="isUserAllowedToCreateWorkspace"
          :id="'create-workspace'"
          label="Create Workspace"
          class="m-0.5 cursor-pointer"
          role="button"
          icon="plus"
          v-bind="omit(getItemProps({ value: 'create-workspace' }), ['onSelect'])"
        />
        <DividerLine
          v-if="isUserAllowedToCreateWorkspace"
          color="subtle"
          :width="1"
        />
        <div class="w-full p-0.5">
          <ListMenuItem
            v-if="canAccessSettings"
            :id="'settings'"
            label="Settings"
            role="button"
            v-bind="omit(getItemProps({ value: 'settings' }), ['onSelect'])"
            class="mb-0.5 w-full cursor-pointer"
            icon="settings"
          />
          <FloatingMenu @select="onSelect">
            <template #item-trigger="{ itemTriggerProps }">
              <ListMenuItem
                :id="'theme'"
                v-bind="itemTriggerProps"
                label="Theme"
                class="mb-0.5 cursor-pointer"
                icon="light-mode"
              >
                <template #suffix>
                  <IconSprite
                    icon="chevron-right"
                    class="text-icon-subtlest"
                  />
                </template>
              </ListMenuItem>
            </template>
            <template #content="{ contentProps: subProps, getItemProps: getSubItemProps }">
              <ListMenuContainer
                v-bind="subProps"
                class="z-10 p-0.5"
              >
                <ListMenuItem
                  :id="'theme-light'"
                  role="menuitem"
                  v-bind="omit(getSubItemProps({ value: 'theme-light' }), ['onSelect'])"
                >
                  <template #prefix>
                    <IconSprite
                      :icon="theme === 'light' ? 'check' : 'blank'"
                      class="text-icon-subtlest"
                    />
                  </template>
                  Light</ListMenuItem
                >
                <ListMenuItem
                  :id="'theme-dark'"
                  v-bind="omit(getSubItemProps({ value: 'theme-dark' }), ['onSelect'])"
                  role="menuitem"
                >
                  <template #prefix>
                    <IconSprite
                      :icon="theme === 'dark' ? 'check' : 'blank'"
                      class="text-icon-subtlest"
                    />
                  </template>
                  Dark</ListMenuItem
                >
                <ListMenuItem
                  :id="'theme-default'"
                  v-bind="omit(getSubItemProps({ value: 'theme-default' }), ['onSelect'])"
                  role="menuitem"
                >
                  <template #prefix>
                    <IconSprite
                      :icon="theme === 'auto' ? 'check' : 'blank'"
                      class="text-icon-subtlest"
                    />
                  </template>
                  Use System Default ({{
                    themePreference === 'light' ? 'Light' : 'Dark'
                  }})</ListMenuItem
                >
              </ListMenuContainer>
            </template>
          </FloatingMenu>

          <ListMenuItem
            :id="'help'"
            v-bind="omit(getItemProps({ value: 'help' }), ['onSelect'])"
            label="Help Center"
            role="menuitem"
            icon="help"
          />
        </div>
        <DividerLine
          color="subtle"
          :width="1"
        />
        <ListMenuItem
          :id="'logout'"
          v-bind="omit(getItemProps({ value: 'logout' }), ['onSelect'])"
          label="Log out"
          role="menuitem"
          class="m-0.5 cursor-pointer"
        />
      </ListMenuContainer>
    </template>
  </FloatingMenu>
  <WorkspaceCreationDialog
    v-if="isCreating"
    @close="isCreating = false"
  />
</template>
