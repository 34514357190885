<script setup lang="ts">
import { useAnalytics, ANALYTICS_EVENT } from '@/sharedComposables/useAnalytics'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { computed } from 'vue'
import ErrorPage from '../App/ErrorPage.vue'

const route = useRoute()
const router = useRouter()
const { captureAnalyticsEvent } = useAnalytics()
const userStore = useUser()

const isError = computed(() => userStore.invitationStatus === 'error')

onMounted(async () => {
  const inviteToken = `${route.query.token}`
  const workspaceId = `${route.query.workspace_id}`

  await userStore.validateUserInvite(workspaceId, inviteToken)

  if (userStore.invitationStatus === 'valid') {
    captureAnalyticsEvent(ANALYTICS_EVENT.FIRST_LOGIN)
    router.push({ name: 'WorkspaceProjects', params: { workspaceId }, query: {} })
  }
})
</script>

<template>
  <ErrorPage
    :title="isError ? 'Error' : 'Accepting invite...'"
    :message="isError ? userStore.invitationErrorMessage : ''"
    :show-icon="isError"
    :show-button="isError"
  />
</template>
