<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue'
import type { LibraryItem } from './libraryStore'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import type { IconName } from '@/uiKit/IconName'
import { LIBRARY_DIALOG_ID } from './constants'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { getAgoString } from '@/shared/utils/date'
import CsvPlaceholder from '@/illustrations/library-csv.svg'
import ImagePlaceholder from '@/illustrations/library-image.svg'
import PdfPlaceholder from '@/illustrations/library-pdf.svg'

const props = defineProps<{ item: LibraryItem; uploadProgress?: number }>()
const emit = defineEmits<{
  (e: 'delete' | 'open'): void
  (e: 'rename', value: string): void
}>()

const extension = computed(() =>
  props.item.type === 'file' ? props.item.filename.split('.').pop() : null,
)

const fileType = computed(() => {
  if (props.item.type === 'file') {
    return `${extension.value?.toUpperCase()} File`
  }

  return 'Document'
})

const lastUpdated = computed(() => getAgoString(props.item.updatedAt))

const dropdownIsOpen = ref(false)
type DropdownItem = { id: 'delete' | 'rename' | 'open'; label: string; icon: IconName }
const listItems: Array<{ id: string; data: DropdownItem }> = [
  {
    id: 'open',
    data: { id: 'open', label: 'Open', icon: 'expand' },
  },
  {
    id: 'rename',
    data: { id: 'rename', label: 'Rename', icon: 'edit' },
  },
  {
    id: 'delete' as const,
    data: { id: 'delete', label: 'Delete', icon: 'trash' },
  },
]

const renameValue = ref<string | null>(null)
const renameInputRef = ref<HTMLInputElement | null>(null)
const onSelectItem = (item: { id: string; data: DropdownItem }) => {
  dropdownIsOpen.value = false

  if (item.data.id === 'delete') {
    emit('delete')
  } else if (item.data.id === 'rename') {
    renameValue.value = props.item.name
    nextTick(() => {
      renameInputRef.value?.focus()
      renameInputRef.value?.select()
    })
  } else if (item.data.id === 'open') {
    if (props.item.type === 'file') {
      assertIsNotNullOrUndefined(props.item.fileUrl)
      window.open(props.item.fileUrl, '_blank')
    } else {
      emit('open')
    }
  }
}

const labelId = computed(() => `library-item-${props.item.id}`)

const onUpdateName = () => {
  if (renameValue.value) {
    emit('rename', renameValue.value)
  }
  renameValue.value = null
}

const onStopRenaming = (e: Event) => {
  renameValue.value = null
  e.stopPropagation()
}
const onChangeName = (e: Event) => {
  if (e.target instanceof HTMLInputElement) {
    renameValue.value = e.target.value
  }
}

const uploadPercentage = computed<string | null>(() => {
  if (typeof props.uploadProgress === 'number') {
    return `${Math.floor(props.uploadProgress * 100)}%`
  }
  return null
})

const onClickListItem = () => {
  // Eventually we will have the ability to preview all files within Go, but for now
  // we render all links as files that will open in a new tab
  if (props.item.type === 'file') {
    return
  }

  emit('open')
}
</script>

<template>
  <li
    class="group relative"
    :aria-labelledby="labelId"
  >
    <component
      :is="item.type === 'file' ? 'a' : 'button'"
      class="flex size-full flex-col items-center justify-start rounded-corner-10 bg-background-transparent px-3 py-2 outline-none transition-colors duration-200 focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered"
      :href="item.type === 'file' && item.fileUrl ? item.fileUrl : undefined"
      target="_blank"
      :aria-labelledby="labelId"
      @click="onClickListItem"
    >
      <div class="mb-2 flex h-20 items-center">
        <div
          v-if="item.status === 'uploading' && item.type === 'file'"
          class="flex h-[72px] w-14 items-center justify-center rounded-sm shadow-xs"
        >
          <CircularProgress
            :value="uploadProgress ?? 0"
            :aria-label="`Uploading ${item.filename}`"
          />
        </div>
        <CsvPlaceholder v-else-if="extension === 'csv'" />
        <PdfPlaceholder v-else-if="extension === 'pdf' || item.type === 'text'" />
        <ImagePlaceholder v-else />
      </div>
      <div
        :id="labelId"
        class="line-clamp-1 max-w-full text-sm-12px-default"
        :class="item.status === 'uploading' ? 'text-text-subtlest' : 'text-text'"
      >
        <input
          v-if="typeof renameValue === 'string'"
          ref="renameInputRef"
          class="h-4 w-full bg-background-transparent px-1 py-0.5 text-center text-sm-12px-default text-text transition-colors placeholder:text-text-subtlest focus:outline-none"
          type="text"
          :value="renameValue"
          aria-label="Item name"
          @input="onChangeName"
          @blur="onUpdateName"
          @keydown.esc="onStopRenaming"
          @keydown.enter.prevent="onUpdateName"
        />
        <div
          v-else
          class="truncate"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="text-center text-xs-11px-light text-text-subtlest">
        <template v-if="item.status === 'uploading'">
          Uploading{{
            typeof uploadPercentage === 'string' && uploadPercentage !== '100%'
              ? ` · ${uploadPercentage}`
              : ''
          }}
        </template>
        <template v-else> {{ fileType }} · {{ lastUpdated }} </template>
      </div>
    </component>

    <PopupMenu
      v-if="item.status !== 'uploading'"
      :open="dropdownIsOpen"
      trigger-element="div"
      class="absolute right-1 top-1"
      :teleport-to="`#${LIBRARY_DIALOG_ID}`"
      :auto-placement="{ allowedPlacements: ['bottom-start'] }"
      disable-focus-trap
      @change:open="dropdownIsOpen = !dropdownIsOpen"
    >
      <template #trigger>
        <IconButton
          class="transition-opacity duration-200 group-hover:opacity-100 focus-visible:opacity-100"
          :class="dropdownIsOpen ? 'opacity-100' : 'opacity-0'"
          :active="dropdownIsOpen"
          :aria-label="`Edit ${item.name}`"
          icon="more-dots"
          variant="transparent"
          size="md"
          @click="dropdownIsOpen = !dropdownIsOpen"
        />
      </template>
      <template #dropdown>
        <ListMenu
          :items="listItems"
          :group-by-predicate="(item) => String(item.id === 'delete')"
          class="min-w-60"
        >
          <template #item="{ item: dropdownItem, key, active, setActiveItem }">
            <component
              :is="dropdownItem.data.id === 'open' && item.type === 'file' ? 'a' : 'div'"
              :href="
                dropdownItem.data.id === 'open' && item.type === 'file' ? item.fileUrl : undefined
              "
              target="_blank"
            >
              <ListMenuItem
                :label="dropdownItem.data.label"
                :icon="dropdownItem.data.icon"
                :critical="dropdownItem.data.id === 'delete'"
                :active="active"
                :aria-selected="active"
                @mousemove="setActiveItem(key)"
                @select="onSelectItem(dropdownItem)"
              />
            </component>
          </template>
        </ListMenu>
      </template>
    </PopupMenu>
  </li>
</template>
