<script setup lang="ts">
import { computed, ref } from 'vue'

import { createView } from '@/backend/createView'
import { updateView } from '@/backend/updateView'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import ColorPresetPicker from '@/uiKit/ColorPresetPicker.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import { toast } from '@/shared/toast'

import { useProject, type View } from './useProject'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { getViewColor } from './utils'
import type { PropertyType } from '@/backend/types'

const props = withDefaults(
  defineProps<{
    propertyId?: string
    propertyType?: PropertyType
    propertyConfig?: { defaultOption?: string | null }
    isDefault?: boolean
    isManual?: boolean
    value: string
    color?: string | null
    showAdvanced?: boolean
    placeholder?: string
  }>(),
  {
    propertyId: undefined,
    propertyType: undefined,
    propertyConfig: undefined,
    placeholder: undefined,
    showAdvanced: true,
    color: null,
  },
)

const emit = defineEmits<{
  (e: 'update' | 'create', v: { value: string; color?: string | null }): void
  (e: 'delete' | 'mark-as-default' | 'unmark-as-default'): void
}>()

defineExpose({
  focus: () => input.value.focus(),
})

const input = ref()
const isLinkViewMenuOpen = ref(false)
const isOptionsMenuOpen = ref(false)

const projectStore = useProject()
const workspacesStore = useWorkspaces()

const existingViewIds = computed(() => {
  return projectStore.views
    .filter((view) =>
      view.filters.some(
        (filter) =>
          filter.property_id === props.propertyId && filter.select_option_value === props.value,
      ),
    )
    .map((view) => view.id)
})

const linkedView = computed(() => {
  return projectStore.views.find((view) =>
    view.filters.some(
      (filter) =>
        filter.property_id === props.propertyId && filter.select_option_value === props.value,
    ),
  )
})

const linkedViewColor = (view?: View) => {
  if (!view) return 'var(--color-icon-subtle)'

  const color = getViewColor(view, projectStore.properties)
  return view.name === 'main' || color === 'rainbow-17'
    ? 'var(--color-icon-subtle)' || color === 'rainbow-17'
    : `var(--${color}-saturated)`
}

const onViewCreate = async (viewName: string) => {
  if (!workspacesStore.currentWorkspace || !projectStore.projectId || !props.propertyId)
    throw new Error('Missing required data to create view')

  const createViewResponse = await createView({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    name: viewName,
    propertyIds: projectStore.properties[0]?.id ? [projectStore.properties[0].id] : [],
    filters: [{ property_id: props.propertyId, select_option_value: props.value }],
  })

  if (!createViewResponse.ok) {
    toast.warning('Failed to create view')
    return
  }

  searchText.value = ''
}

const onViewSelect = async (item: { id: string }) => {
  if (!workspacesStore.currentWorkspace || !projectStore.projectId || !props.propertyId)
    throw new Error('Missing required data to link view to')

  const selectedView = projectStore.views.find((view) => view.id === item.id)
  if (!selectedView) return

  const filters = selectedView.filters
  const existingFilterIndex = filters.findIndex(
    (filter) =>
      filter.property_id === props.propertyId && filter.select_option_value === props.value,
  )
  if (existingFilterIndex !== -1) {
    filters.splice(existingFilterIndex, 1)
  } else {
    filters.push({ property_id: props.propertyId, select_option_value: props.value })
  }

  await updateView({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    viewId: selectedView.id,
    filters,
    propertyIds: selectedView.propertyIds ?? [],
    propertyLayouts: selectedView.propertyLayouts,
    propertyOptions: selectedView.propertyOptions ?? [],
    assignablePropertyId: selectedView.assignablePropertyId,
  })
}

const searchText = ref('')

const onInput = (event: string) => {
  searchText.value = event
}

const onMarkAsDefault = () => {
  if (props.isDefault) {
    emit('unmark-as-default')
  } else {
    emit('mark-as-default')
  }
}

const onDeleteOption = () => {
  emit('delete')
  isOptionsMenuOpen.value = false
}
</script>

<template>
  <PopupMenu
    class="w-full"
    :auto-placement="{ allowedPlacements: ['left-start', 'right-start'] }"
    :open="isLinkViewMenuOpen"
    trigger-element="div"
    @click:outside="isLinkViewMenuOpen = false"
  >
    <template #trigger>
      <div
        v-bind="$attrs"
        class="group/option flex h-7 items-center gap-0.5 rounded-lg p-0.5 hover:bg-background-transparent-hovered focus-visible:outline-none active:hover:bg-background-transparent-hovered"
        :class="
          isLinkViewMenuOpen ? 'bg-background-transparent-hovered' : 'bg-background-transparent'
        "
        aria-label="Select Type Option"
      >
        <div class="flex grow items-center gap-0.5 rounded-lg py-0.5">
          <ColorPresetPicker
            v-if="showAdvanced"
            :value="color"
            @change="$emit('update', { value, color: $event })"
          />
          <IconSprite
            v-else
            class="size-6 text-icon-subtlest"
            size="md"
            icon="search"
            @click="input.focus()"
          />

          <InlineTextField
            ref="input"
            size="xs"
            :value="value"
            class="w-full min-w-1/2"
            :class="!showAdvanced && 'hover:[&:not(:disabled)]:bg-background-transparent'"
            variant="transparent"
            :placeholder="placeholder"
            @input="$emit('update', { color, value: $event.trim() })"
            @submit="$emit('create', { color, value: $event.trim() })"
          />

          <button
            v-if="showAdvanced && existingViewIds.length > 0"
            class="flex w-full items-center gap-0.5 truncate rounded-corner-6 px-1.5 py-0.5 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
            :class="[
              isLinkViewMenuOpen
                ? 'bg-background-transparent-hovered'
                : 'bg-background-transparent',
            ]"
            @click="isLinkViewMenuOpen = !isLinkViewMenuOpen"
          >
            <IconSprite
              class="text-icon-subtle"
              aria-label="Link Option to View"
              icon="arrow-right"
              size="sm"
            />
            <p class="truncate">
              <span v-if="existingViewIds.length !== 1">{{ existingViewIds.length }}</span>
              {{ existingViewIds.length === 1 && linkedView ? linkedView.name : 'views' }}
            </p>
          </button>
        </div>
        <div class="flex">
          <ToolTip
            v-if="propertyType !== 'user_select'"
            title="Connect to a view"
            :placement="{ allowedPlacements: ['top'] }"
          >
            <IconButton
              v-if="showAdvanced && existingViewIds.length === 0"
              aria-label="Link Option to View"
              icon="workflow"
              size="md"
              class="self-center group-focus-within/option:visible group-hover/option:visible"
              :class="[isLinkViewMenuOpen ? 'visible' : 'invisible']"
              variant="transparent"
              @click="isLinkViewMenuOpen = !isLinkViewMenuOpen"
            />
          </ToolTip>
          <IconButton
            v-if="showAdvanced"
            aria-label="Delete Type Option"
            icon="trash"
            size="md"
            class="self-center group-focus-within/option:visible group-hover/option:visible"
            :class="isLinkViewMenuOpen ? 'visible' : 'invisible'"
            variant="transparent"
            @click="onDeleteOption"
          />
          <IconButton
            v-if="showAdvanced && isManual"
            class="self-center group-focus-within/option:visible group-hover/option:visible"
            :class="[isLinkViewMenuOpen || isDefault ? 'visible' : 'invisible']"
            :icon="isDefault ? 'rating-fill' : 'rating'"
            size="md"
            variant="transparent"
            @click="onMarkAsDefault"
          />
        </div>
      </div>
    </template>

    <template #dropdown>
      <ListMenu
        :items="
          projectStore.views
            .filter((view) => view.name !== 'main')
            .map((view) => ({
              id: view.id,
              data: { id: view.id, label: view.name },
            }))
        "
        create-enabled
        create-enabled-when-empty
        search-by-field="label"
        no-results-text="Stage not found. Press enter to create new."
        @input="onInput"
        @select="onViewSelect"
        @create="onViewCreate($event)"
      >
        <template #item="{ key, item, active, setActiveItem }">
          <ListMenuCheckboxItem
            icon="table"
            :icon-color="linkedViewColor(projectStore.views.find((view) => view.id === item.id))"
            :label="item.data.label"
            :active="active"
            :aria-selected="active"
            :checked="existingViewIds.includes(item.id)"
            default-hover-disabled
            @click="() => onViewSelect(item)"
            @mousemove="setActiveItem(key)"
          />
        </template>
        <template #create>
          <div class="w-full p-0.5">
            <ListMenuItem
              icon="plus"
              :label="`Create a new view &quot;${searchText || value}&quot;`"
              @click="() => onViewCreate(value)"
            />
          </div>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
