<script setup lang="ts">
// Figma Component: Fugazi/Fugazi.
// Figma URL: https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?type=design&node-id=3495%3A102519&mode=dev

import BadgeItem from '@/uiKit/BadgeItem.vue'
import { ref, watch } from 'vue'

const props = defineProps<{
  value: Array<string>
}>()

const emit = defineEmits<{
  (e: 'change', value: Array<string>): void
}>()

const inputValue = ref('')
const badges = ref<string[]>(props.value || [])

const isEmailValid = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(email.toLowerCase())
}

const getValidEmails = (emails: Array<string>) => emails.filter((email) => isEmailValid(email))

watch(
  () => props.value,
  (newValue) => {
    badges.value = newValue
    if (newValue.length === 0) {
      inputValue.value = ''
    }
  },
)

const onKeyUp = (e: KeyboardEvent) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  const { value } = e.target

  if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
    addAsBadges(value)
    updateAndEmit(badges.value)
    inputValue.value = ''
  } else if (isEmailValid(value) && badges.value.length === 0) {
    updateAndEmit([value])
  } else {
    return
  }
}

const onKeyDown = (e: KeyboardEvent) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  const { value } = e.target

  if (e.key === 'Backspace' && value === '') {
    badges.value.pop()
    updateAndEmit(badges.value)
  }
}

const updateAndEmit = (emails: Array<string>) => {
  const valdEmails = getValidEmails(emails)
  emit('change', valdEmails)
}

const addAsBadges = (val: string) => {
  const newBadges = val.split(/,| |, /).filter((badge) => badge !== '')
  badges.value = [...badges.value, ...newBadges]
}

const onRemoveBadge = (index: number) => {
  badges.value.splice(index, 1)
  updateAndEmit(badges.value)
}
</script>

<template>
  <div
    class="flex w-full flex-wrap rounded-md bg-background-gray-subtlest outline-2 outline-border-focused transition-colors placeholder:text-text-subtlest has-[input:focus-visible]:outline aria-readonly:cursor-pointer focus:bg-background-gray-subtlest focus:text-text focus:outline-none focus-visible:shadow-focus-ring-primary disabled:cursor-not-allowed hover:[&:not(:has(input:focus-visible))]:bg-background-gray-subtlest-hovered"
  >
    <div
      v-if="badges.length > 0"
      class="flex max-h-36 flex-wrap overflow-y-scroll pb-1 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
    >
      <BadgeItem
        v-for="(item, index) in badges"
        :key="index"
        :label="item"
        size="sm"
        class="ml-1 mt-1"
        :variant="isEmailValid(item) ? 'neutral' : 'warning'"
        trailing-icon="close"
        @trailing-icon-click="onRemoveBadge(index)"
      />
    </div>
    <input
      v-model="inputValue"
      class="h-7 grow bg-background-transparent p-2 text-sm-12px-default text-text outline-none placeholder:text-text-subtlest"
      type="text"
      placeholder="Email address (comma-separated)"
      aria-label="Email address"
      autocomplete="off"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
    />
  </div>
</template>
