<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'

import { SUPPORTED_MIME_TYPES } from '@/backend/types'
import { useFieldUpload } from '@/modules/Project/useFieldUpload'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { RouterLink } from 'vue-router'

import { useTableCellFocus } from './useTableCellFocus'

import FileUpload from '@/uiKit/FileUpload.vue'

const props = defineProps<{
  workspaceId: string
  projectId: string
  entityId: string
  propertyId: string
  filename: string | null
  isFocused: boolean
  isSelected: boolean
  pdfProjectId?: string | null
  url: string | null
}>()

const emit = defineEmits<{
  (e: 'uploaded', url: string): void
  (e: 'cleared' | 'blur'): void
  (e: 'upload', files: File[]): void
}>()

const fileUpload = ref<InstanceType<typeof FileUpload>>()

// Into string[] so we can use .include + we don't want to accidentally mutate the original
const supportedMimeTypes: string[] = [...SUPPORTED_MIME_TYPES]

const uploadStore = useFieldUpload()

const upload = computed(() =>
  uploadStore.getUpload(props.workspaceId, props.projectId, props.entityId, props.propertyId),
)
const uploadStatus = computed(() => upload.value?.status || 'idle')
const uploadFileName = computed(() => upload.value?.fileName)
const uploadProgress = computed(() => upload.value?.progress || 0)

watch(uploadStatus, (status) => {
  const completeStatuses: (typeof status)[] = ['done', 'error', 'idle', 'ready']
  const isComplete = completeStatuses.includes(status)
  if (isComplete && props.isFocused) {
    emit('blur')
  }
})

const dropZone = ref<HTMLElement | null>(null)
const cellContent = computed(() => props.filename || uploadFileName.value)

watch(
  () => props.isSelected,
  (isSelected) => (isSelected ? dropZone.value?.focus() : dropZone.value?.blur()),
  { immediate: true },
)

useTableCellFocus({
  cell: dropZone,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})
</script>

<template>
  <FileUpload
    ref="fileUpload"
    v-slot="{ rootProps, dropzoneProps, hiddenInputProps, isDragging }"
    :accept="supportedMimeTypes.join(', ')"
    :max-files="100"
    @change="$emit('upload', $event)"
  >
    <div
      v-bind="{ ...rootProps, ...$attrs }"
      class="w-full"
    >
      <div
        v-bind="dropzoneProps"
        ref="dropZone"
        :class="
          isDragging && 'rounded-corner-4 border border-border-focused bg-background-selected'
        "
        class="group/file flex h-8 shrink grow basis-0 flex-row content-center items-center justify-start truncate px-1.5 py-1 text-sm-12px-light text-text focus:outline-none"
        @drop="
          (e) => {
            // Workaround for Welcome Tour sample data.
            // This is needed because programatically dropping the files does not trigger
            // the zag drop event.
            if (!e.dataTransfer) return
            $emit('upload', [...e.dataTransfer.files])
          }
        "
      >
        <input v-bind="hiddenInputProps" />
        <div
          v-if="isDragging"
          class="truncate text-text-selected"
        >
          Drag and drop to upload content
        </div>

        <div v-else-if="cellContent && pdfProjectId">
          <div
            data-table-cell-content
            class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
          >
            <RouterLink
              title="Open PDF"
              :to="{
                name: 'WorkspaceSubProjectTable',
                params: { workspaceId, projectId: pdfProjectId, parentProjectId: projectId },
                query: { parentEntityId: entityId },
              }"
              aria-label="Open PDF"
              @click.stop
            >
              <div
                class="flex h-5 items-center gap-1 rounded-corner-6 px-1 hover:bg-background-blue-subtle"
              >
                <IconSprite
                  class="text-icon-primary"
                  icon="file-fill"
                  size="xs"
                />
                <p
                  class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
                >
                  {{ cellContent }}
                </p>
              </div>
            </RouterLink>
          </div>
        </div>
        <div
          v-else-if="cellContent"
          class="inline-flex h-5 items-center justify-center gap-1 truncate rounded-md bg-background-gray-subtlest px-1"
        >
          <CircularProgress
            v-if="uploadStatus === 'uploading'"
            class="shrink-0"
            size="sm"
            :value="uploadProgress"
          />
          <div
            v-else
            class="flex size-3.5 items-center justify-center text-text-subtle"
            data-test="file-uploaded-icon"
          >
            <IconSprite icon="file" />
          </div>
          <div class="shrink truncate text-sm-12px-default text-text-subtle">
            {{ cellContent }}
          </div>
        </div>

        <div
          v-else
          class="inline-flex h-5 shrink items-center gap-0.5 truncate rounded-md bg-background-gray-subtlest px-1 group-hover/cell:visible hover:cursor-pointer hover:bg-background-gray-subtlest-hovered"
          :class="isSelected ? 'visible' : 'invisible'"
          role="button"
        >
          <div class="size-3.5 shrink-0 text-icon-subtle">
            <IconSprite icon="plus" />
          </div>
          <div class="truncate text-sm-12px-default text-text-subtle">
            Add file or drag and drop
          </div>
        </div>
      </div>
    </div>
  </FileUpload>
</template>
