import type { APIResult } from '@/backend/types'
import type { IfEquals } from '@/shared/types'
import { readonly, ref } from 'vue'

type PaginatedArgs = { limit: number; offset: number }
type PaginatedResult = {
  data: unknown[]
  metadata: {
    total_count: number | null
    has_next_page: boolean
  }
}
type PaginatedFn<Args extends PaginatedArgs> = (args: Args) => Promise<APIResult<PaginatedResult>>

type LoadMoreArgs<Args> = IfEquals<Args, PaginatedArgs, [], [Omit<Args, keyof PaginatedArgs>]>

type FnDatum<Fn> = Fn extends (...args: infer Args) => Promise<APIResult<{ data: Array<infer T> }>>
  ? T
  : unknown

export const usePaginatedLoader = <Args extends PaginatedArgs, Fn extends PaginatedFn<Args>>(
  loadFn: Fn,
  limit = 50,
) => {
  const status = ref<'idle' | 'loading' | 'loaded' | 'errored'>('idle')
  const data = ref([] as FnDatum<Fn>[])
  const canLoadMore = ref(true)

  const loadMore = async (...args: LoadMoreArgs<Args>) => {
    if (status.value === 'loading') return
    status.value = 'loading'

    const offset = data.value.length
    const loadFnArgs = { ...args?.[0], limit, offset } as Args
    const result = await loadFn(loadFnArgs)
    status.value = result.ok ? 'loaded' : 'errored'
    if (!result.ok) return

    canLoadMore.value = result.data.metadata.has_next_page
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- vue's types are being weird
    data.value = [...data.value, ...(result.data.data as any)]
  }

  const reset = () => {
    data.value = []
    canLoadMore.value = true
    status.value = 'idle'
  }

  return { status, loadMore, canLoadMore: readonly(canLoadMore), data, reset }
}
