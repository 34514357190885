<script setup lang="ts">
import { computed, ref } from 'vue'

import { createApiKey } from '@/backend/createApiKey'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import TextField from '@/uiKit/TextField.vue'

import { useApiKeys, type ApiKey } from './useApiKeys'
import { Expiration, expirationToDays } from './utils'
import WorkspaceSettingsApiKeysAcknowledgeDialog from './WorkspaceSettingsApiKeysAcknowledgeDialog.vue'
import { toast } from '@/shared/toast'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'

const creating = ref<boolean>(false)
const error = ref<string>()
const name = ref<string>()
const expiration = ref<Expiration>(Expiration.week)
const createdApiKey = ref<ApiKey | null>(null)

const apiKeyStore = useApiKeys()

const { captureAnalyticsEvent } = useAnalytics()

const onSubmit = async () => {
  creating.value = true
  const apiKeyResult = await createApiKey(expirationToDays(expiration.value), name.value)
  if (apiKeyResult.ok) {
    creating.value = false
    createdApiKey.value = apiKeyResult.data
    apiKeyStore.addApiKey(apiKeyResult.data)

    captureAnalyticsEvent(ANALYTICS_EVENT.API_KEY_CREATED)

    toast.success('API key created successfully')
  } else {
    creating.value = false
    if (apiKeyResult.error.code === 'duplicate_name') {
      error.value = 'Name already taken'
      return
    }
    toast.error('Failed to create API key')
  }

  name.value = undefined
  expiration.value = Expiration.week
}

const items = computed(() => {
  return Object.values(Expiration).map((expiration, index) => ({
    id: `${index}`,
    data: { expiration },
  }))
})

const onChange = (value: string) => {
  name.value = value
}

const onInput = () => {
  error.value = undefined
}
</script>

<template>
  <div class="flex gap-2">
    <TextField
      size="md"
      :error="error"
      :value="name || ''"
      placeholder="API key name (optional)"
      @change="onChange"
      @input="onInput"
    />
    <SelectDropdown class="w-64 rounded-md bg-background-gray-subtlest">
      <template #trigger>
        <SelectDropdownTrigger inline>
          <div class="flex px-1.5">
            <IconSprite
              icon="clock"
              size="sm"
              class="mr-1"
            />
            {{ expiration }}
          </div>
        </SelectDropdownTrigger>
      </template>
      <template #dropdown="{ close }">
        <ListMenu
          class="w-40"
          :items="items"
        >
          <template #item="{ item, active, key, setActiveItem }">
            <ListMenuItem
              :label="item.data.expiration"
              :active="active"
              default-hover-disabled
              @mousemove="setActiveItem(key)"
              @select="(expiration = item.data.expiration), close()"
            />
          </template>
        </ListMenu>
      </template>
    </SelectDropdown>
    <div class="flex flex-col">
      <DarwinButton
        variant="black"
        size="sm"
        @click="onSubmit"
        >Create API key
      </DarwinButton>
    </div>
  </div>
  <WorkspaceSettingsApiKeysAcknowledgeDialog
    v-if="createdApiKey !== null"
    :api-key="createdApiKey"
    title="API key created"
    description="Save this key somewhere safe and accessible. For security reasons, you won't be able to see it again. If you lose this key, you will need to create a new one."
    @ack="createdApiKey = null"
  />
</template>
