import { listProjectMembers } from '@/backend/listProjectMembers'
import { watch, type Ref } from 'vue'
import { useProjectPermissionsStore } from './projectPermissionsStore'

export const useLoadProjectPermissions = ({
  rootProjectId,
  workspaceId,
}: {
  workspaceId: Ref<string>
  rootProjectId: Ref<string>
}) => {
  const projectPermissionsStore = useProjectPermissionsStore()

  watch(
    () => [rootProjectId.value, workspaceId.value],
    async ([newProjectId, newWorkspaceId]) => {
      projectPermissionsStore.reset()
      const res = await listProjectMembers({
        projectId: newProjectId,
        workspaceId: newWorkspaceId,
      })

      if (!res.ok) {
        throw new Error('Failed to load project permissions')
      }

      res.data.data.forEach((member) => {
        if (typeof member.user === 'string') {
          // TODO: GO-2510 - these cases will be the default permissions for the workspace
          // and for teams in the future
          return
        }

        projectPermissionsStore.workspaceMemberRoles[member.user.id] = member.role
      })
    },
    {
      immediate: true,
    },
  )
}
