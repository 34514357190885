export async function getFirstNBytes(file: File, n: number) {
  // Ensure n is not greater than the file size
  const bytesToRead = Math.min(n, file.size)

  // Slice the first n bytes of the file
  const slice = file.slice(0, bytesToRead)

  // Convert the slice to an ArrayBuffer
  const arrayBuffer = await slice.arrayBuffer()

  return arrayBuffer
}

export function bufferToString(buffer: ArrayBuffer) {
  const decoder = new TextDecoder('utf-8') // Use the correct encoding
  return decoder.decode(new Uint8Array(buffer))
}

export const fileNameIsPdf = (filename: string) => {
  return filename.replace(/\?.*$/, '').toLowerCase().endsWith('.pdf')
}

const extensionToType = {
  pdf: 'application/pdf',
  '': 'application/octet-stream',
  png: 'image/png',
  jpg: 'image/jpeg',
} as const
type FileExtension = keyof typeof extensionToType

function isFileExtension(extension: string): extension is FileExtension {
  return Object.keys(extensionToType).includes(extension)
}

export async function dragFileToElement(fileUrl: string, selector: string) {
  // Select the dropzone element
  const dropzone = document.querySelector(selector)
  if (!dropzone) return

  // Fetch the PDF file and convert it to a Blob
  const fileToAddRes = await fetch(fileUrl)
  const blobToAdd = await fileToAddRes.blob()

  const fileName = fileUrl.split('/').pop() ?? ''
  const fileExtension = fileName?.split('.').pop() ?? ''
  const fileType = isFileExtension(fileExtension)
    ? extensionToType[fileExtension]
    : 'application/octet-stream'

  // Create a new File object from the Blob
  const fileToAdd = new File([blobToAdd], fileName, { type: fileType })

  // Create a DataTransfer object and add the File to it
  const list = new DataTransfer()
  list.items.add(fileToAdd)

  // Create a new DropEvent and set the dataTransfer property to the DataTransfer object
  const dropEvent = new DragEvent('drop', {
    dataTransfer: list,
    bubbles: true,
    cancelable: true,
  })

  // Dispatch the drop event on the dropzone element
  dropzone.dispatchEvent(dropEvent)
}
