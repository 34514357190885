<script lang="ts" setup>
import { computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

defineProps<{ numberOfProjects: number }>()

const { user } = useAuth0()
const generateGreeting = (name: string) => {
  const hour = new Date().getHours()
  let partOfDay

  if (hour < 12) {
    partOfDay = 'morning'
  } else if (hour < 18) {
    partOfDay = 'afternoon'
  } else {
    partOfDay = 'evening'
  }
  if (name) {
    return `Good ${partOfDay}, ${name}.`
  }
  return `Good ${partOfDay}!`
}

const greeting = computed(() => generateGreeting(user.value?.given_name ?? ''))
</script>

<template>
  <div class="flex flex-col">
    <h1 class="text-md-13px-bold text-text">{{ greeting }}</h1>
    <p
      data-test="project-count"
      class="text-xs-11px-light text-text-subtle"
    >
      {{ numberOfProjects }} {{ numberOfProjects === 1 ? 'project' : 'projects' }}
    </p>
  </div>
</template>
