import { getProject } from '@/backend/getProject'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { serializeProject, useProjects } from '../Projects/useProjects'
import { serializeProperty, serializeView, useProject } from './useProject'

/**
 * When the project ID changes, this composable will load the project and set it in the project Pinia
 * store. When the view ID changes, it will set the active view in the project Pinia store.
 */
export const useProjectSync = (props: {
  workspaceId: string
  projectId: string
  viewId?: string
  parentEntityId?: string
}) => {
  const currentProjectStore = useProject()
  const allProjectsStore = useProjects()
  const projectLoader = useDataLoader(() => getProject(props.workspaceId, props.projectId))
  const router = useRouter()

  const loadAndSetProject = async () => {
    currentProjectStore.setProjectId(props.projectId)

    const result = await projectLoader.load()
    if (result.ok) {
      allProjectsStore.addProject(serializeProject(result.data))
      currentProjectStore.setProperties(result.data.properties.map(serializeProperty))
      currentProjectStore.setViews(result.data.main_view_id, result.data.views.map(serializeView))
      const hasMainView = result.data.main_view_id !== null
      if (!hasMainView && !props.viewId) {
        const firstView = result.data.views[0]
        if (!firstView) {
          throw new Error('User is unable to access any views in this project.')
        }
        // 1. If the response contains no main view, then the user does not have permission to access
        //    the main view
        // 2. If the user has not provided a viewId, then we redirect the user to the first view that
        //    they do have access to
        router.replace({
          name: 'WorkspaceProjectTableView',
          params: {
            workspaceId: props.workspaceId,
            projectId: props.projectId,
            viewId: firstView.id,
          },
        })
      } else {
        currentProjectStore.setActiveViewId(props.viewId)
      }
      currentProjectStore.projectLoaded = true
    }
  }

  watch(
    () => props.projectId,
    (newId) => {
      if (newId !== currentProjectStore.projectId) {
        currentProjectStore.projectLoaded = false
        currentProjectStore.setProperties([])
        currentProjectStore.setViews(null, [])
        loadAndSetProject()
      }
    },
    { immediate: true },
  )

  watch(
    () => props.viewId,
    (newViewId) => {
      if (newViewId !== currentProjectStore.activeView?.id) {
        currentProjectStore.setActiveViewId(newViewId)
      }
    },
    { immediate: true },
  )

  watch(
    () => props.parentEntityId,
    (newPid) => {
      currentProjectStore.setParentEntityId(newPid)
    },
    { immediate: true },
  )

  return { projectLoader }
}
