<script setup lang="ts">
import { ref } from 'vue'

import { copyToClipboard } from '@/shared/clipboard'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'

import { removeProperty } from '@/backend/removeProperty'
import { useProject } from './useProject'
import { useProperty } from './useProperty'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import ToolTip from '@/uiKit/ToolTip.vue'

const props = defineProps<{ workspaceId: string }>()

const IDS = {
  copyPropertyId: 'copy-property-id',
  copyPropertySlug: 'copy-property-slug',
  delete: 'delete',
}

const isDeleting = ref<boolean>(false)
const open = ref<boolean>(false)

defineExpose({ open })
const onSelect = (id: string) => {
  switch (id) {
    case IDS.copyPropertyId: {
      if (projectStore.selectedPropertyId) {
        copyToClipboard('Property ID', projectStore.selectedPropertyId)
      }
      break
    }
    case IDS.copyPropertySlug: {
      if (projectStore.selectedProperty) {
        copyToClipboard('Property slug', projectStore.selectedProperty.slug)
      }
      break
    }
    case IDS.delete: {
      isDeleting.value = true
      break
    }
    default:
      break
  }
}

const projectStore = useProject()
const propertyStore = useProperty()

const onDelete = async () => {
  if (projectStore.projectId && projectStore.selectedPropertyId) {
    await removeProperty(props.workspaceId, projectStore.projectId, projectStore.selectedPropertyId)
  }
  propertyStore.sidebarIsOpen = false
  isDeleting.value = false
}
</script>

<template>
  <PopupMenu
    :open="open"
    placement="bottom-end"
    trigger-element="div"
    :offset="{ mainAxis: 4 }"
    v-bind="$attrs"
    @change:open="open = !open"
  >
    <template #trigger="{ click }">
      <IconButton
        icon="more-dots"
        data-test="property-sidebar-top-menu-trigger"
        size="md"
        variant="transparent"
        aria-label="More options"
        @click="click"
      />
    </template>
    <template #dropdown>
      <ListMenuContainer class="w-[296px] min-w-56 p-0.5">
        <ToolTip
          class="w-full"
          :arrow="true"
          :placement="{ allowedPlacements: ['left'] }"
          :title="projectStore.selectedPropertyId ?? 'Copy property ID'"
        >
          <ListMenuItem
            element="button"
            icon="copy"
            @select="onSelect(IDS.copyPropertyId)"
          >
            Copy property ID
          </ListMenuItem>
        </ToolTip>
        <ToolTip
          class="w-full"
          :arrow="true"
          :placement="{ allowedPlacements: ['left'] }"
          :title="projectStore.selectedProperty?.slug ?? 'Copy property slug'"
        >
          <ListMenuItem
            element="button"
            icon="copy"
            @select="onSelect(IDS.copyPropertySlug)"
          >
            Copy property slug
          </ListMenuItem>
        </ToolTip>
        <hr class="my-0.5 -ml-0.5 w-[calc(100%+0.25rem)] border-border-subtle" />
        <ListMenuItem
          element="button"
          icon="trash"
          critical
          @select="onSelect(IDS.delete)"
        >
          Delete property
        </ListMenuItem>
      </ListMenuContainer>
    </template>
  </PopupMenu>

  <ConfirmationDialog
    id="delete-property-confirmation"
    :open="isDeleting"
    title="Delete this property?"
    description="This property will be deleted immediately. You can't undo this action."
    @confirm="onDelete"
    @close="isDeleting = false"
  />
</template>
