<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'

import WorkspaceSettingsUserInviteForm from '@/modules/WorkspaceSettings/WorkspaceSettingsUserInviteForm.vue'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SimpleTable from '@/uiKit/SimpleTable.vue'

import { toast } from '@/shared/toast'
import { useClipboard, useInfiniteScroll } from '@vueuse/core'

import { listInvitations } from '@/backend/listInvitations'
import { listUsers } from '@/backend/listUsers'
import { isHtmlElement, omit } from '@/shared/utils'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import { useProfileImage } from './useProfileImage'
import {
  serializeInvitation,
  serializeWorkspaceMember,
  useWorkspaceMembers,
  type Invitation,
  type WorkspaceMember,
} from './useWorkspaceMembers'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import WorkspaceSettingsRoleDropdown from './WorkspaceSettingsRoleDropdown.vue'
import TagButton from '@/uiKit/TagButton.vue'
import { usePaginatedLoader } from '@/sharedComposables/usePaginatedLoader'

const props = defineProps<{ workspaceId: string }>()

type InviteRow = {
  type: 'invite'
  id: string
  /**
   * Will be undefined for invitations in an optimistic ui state where
   * the url is not yet known
   */
  inviteUrl?: Invitation['inviteUrl']
  email: string
  status: Invitation['status']
  role: Invitation['role']
}

type MemberRow = {
  type: 'member'
  id: string
  name: string | null
  email: string
  role: WorkspaceMember['role']
}

type TableRow = InviteRow | MemberRow

const isInviteRow = (row: TableRow): row is InviteRow => row.type === 'invite'

const USER_TABLE_COLUMNS = [
  {
    key: 'avatar',
    width: '24px',
  },
  {
    key: 'name',
    label: 'User',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'role',
    label: 'Role',
  },
  {
    key: 'menu',
    width: '32px',
  },
]

const workspaceMembersStore = useWorkspaceMembers()
const usersLoader = useDataLoader(() => listUsers(props.workspaceId))
const invitations = usePaginatedLoader(
  ({ offset, limit }) => listInvitations(props.workspaceId, { limit, offset }),
  50,
)

/**
 * On load (or when the workspaceId changes), load the workspace members and
 * invitations into the store.
 */
watch(
  () => props.workspaceId,
  async () => {
    invitations.reset()
    invitations.loadMore()
    const userResponse = await usersLoader.load()

    if (userResponse.ok) {
      workspaceMembersStore.setWorkspaceMembers(
        userResponse.data.data.map(serializeWorkspaceMember),
      )
    }
  },
  { immediate: true },
)

watch(invitations.data, () => {
  workspaceMembersStore.setInvitations(invitations.data.value.map(serializeInvitation))
})

const isLoading = computed(() => [usersLoader.status.value].includes('loading'))

const { copy, copied, isSupported: isClipboardSupported } = useClipboard()

const isOwner = (role: WorkspaceMember['role']) => role === 'owner'

const getInvitationByEmail = (email: string): Invitation | undefined =>
  workspaceMembersStore.invitations.find((i) => i.email === email)

const onRevoke = async (row: TableRow) => {
  const { email, id: userId } = row
  const invitation = getInvitationByEmail(email)

  if (!invitation) {
    await workspaceMembersStore.removeMember(userId)
    toast.success(`User access revoked.`)
  } else if (invitation.id) {
    await workspaceMembersStore.revokeInvitation(invitation.id)
    toast.success(`Invitation revoked.`)
  } else {
    toast.error(`Something went wrong, please refresh the page and try again.`)
  }
}

const onResendInvite = async (row: InviteRow) => {
  const { email, role } = row

  // technically resending is an update on the invitation which will trigger an email as well
  await workspaceMembersStore.updateInvitation({ email, role })
  toast.success(`Invite email sent.`)
}

watch(copied, (newValue) => {
  if (newValue) {
    toast.info('Copied to clipboard.')
  }
})

const tableRows = computed<TableRow[]>(() => {
  const rows: TableRow[] = []

  if (isLoading.value) {
    const LOADING_DATA: Array<TableRow> = Array(5).fill({
      id: '',
      name: '',
      email: '',
      role: 'admin',
    })

    return LOADING_DATA
  }

  workspaceMembersStore.workspaceMembers.forEach((member) => {
    rows.push({
      id: member.id,
      name: member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : null,
      email: member.email ?? '',
      role: member.role,
      type: 'member',
    })
  })

  workspaceMembersStore.invitations.forEach((invitation) => {
    rows.push({
      // We can't use the invitation ID here because it might not exist (optimistic UI).
      // we use the email as it's unique so can be used to get the ID when it's needed.
      id: invitation.email,
      email: invitation.email,
      inviteUrl: invitation.inviteUrl,
      type: 'invite',
      status: invitation.status,
      role: invitation.role,
    })
  })

  return rows
})

// Map member roles to UI-friendly labels
const roleLabels: Record<WorkspaceMember['role'], string> = {
  admin: 'Admin',
  reader: 'Reader',
  editor: 'Editor',
  owner: 'Owner',
  worker: 'Worker',
}

const inviteStatusLabels: Record<Invitation['status'], string> = {
  pending: 'Invite Pending',
  accepted: 'Invite Accepted',
  expired: 'Invite Expired',
}

const onCopy = async (value: string) => {
  await copy(value)
}

const MenuIds = {
  CopyEmail: 'copy-email',
  ResendInvite: 'resend-invite',
  CopyInviteLink: 'copy-invite-link',
  RevokeAccess: 'revoke-access',
}

const canChangeRoles = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
const tableRef = ref<HTMLElement | null>(null)
const overflowingParent = ref<HTMLElement | null>(null)

function getScrollParent(node: HTMLElement) {
  if (!isHtmlElement(node)) return null
  const overflowValue = getComputedStyle(node).overflowY
  if (overflowValue === 'auto' || overflowValue === 'scroll') return node
  return getScrollParent(node.parentNode as HTMLElement)
}
watch(tableRef, (table) => {
  if (!table) return
  overflowingParent.value = getScrollParent(table)
})

useInfiniteScroll(
  overflowingParent,
  () => {
    if (!invitations.canLoadMore.value) return
    invitations.loadMore()
  },
  { distance: 10 },
)
</script>

<template>
  <div class="mx-auto flex w-[768px] flex-col">
    <h2 class="mx-4 mb-1 mt-16 text-xl-18px-bold text-text">Users</h2>
    <p class="mx-4 mb-8 text-sm-12px-light text-text-subtle">
      Manage users of your workspace and set their access level.
    </p>

    <div class="mb-8 rounded-xl bg-surface-secondary p-4">
      <h3 class="mb-1 text-sm-12px-bold font-semibold">Invite Members</h3>
      <p class="mb-4 text-sm-12px-light text-text-subtle">
        You can invite new users up to the seats allowed on your plan.
      </p>
      <WorkspaceSettingsUserInviteForm />
    </div>
    <div ref="tableRef">
      <SimpleTable
        :columns="USER_TABLE_COLUMNS"
        :data="tableRows"
        :hover="false"
        class="mx-4 mb-20"
      >
        <template #avatar="{ row }">
          <LoadingSkeleton
            :status="isLoading"
            class="h-5 w-full"
          >
            <AvatarIcon
              v-if="!isLoading"
              size="sm"
              :full-text="isInviteRow(row) ? row.email : (row.name ?? row.email)"
              shape="circle"
              :url="useProfileImage(toRef(row.id)).value"
            />
          </LoadingSkeleton>
        </template>
        <template #name="{ row }">
          <LoadingSkeleton
            class="flex h-5 w-2/3 items-center"
            :status="isLoading"
          >
            {{ isInviteRow(row) ? '-' : (row.name ?? '-') }}
          </LoadingSkeleton>
        </template>
        <template #email="{ row }">
          <LoadingSkeleton
            class="flex h-5 w-2/3 items-center"
            :status="isLoading"
          >
            {{ row.email }}
          </LoadingSkeleton>
        </template>
        <template #role="{ row }">
          <LoadingSkeleton
            class="flex h-5 w-2/3"
            :status="isLoading"
          >
            <WorkspaceSettingsRoleDropdown
              v-if="
                canChangeRoles &&
                !isLoading &&
                !isInviteRow(row) &&
                (row.role === 'admin' || row.role === 'worker')
              "
              :value="row.role"
              :offset="{ mainAxis: 4, alignmentAxis: -10 }"
              @change="
                (role) =>
                  workspaceMembersStore.updateMemberRole({ role, userId: row.id, workspaceId })
              "
            >
              <template #trigger="{ isOpen }">
                <TagButton
                  :active="isOpen"
                  size="sm"
                  class="-translate-x-1.5"
                  :label="roleLabels[row.role]"
                  :trailing-icon="isOpen ? 'chevron-top' : 'chevron-bottom'"
                >
                </TagButton>
              </template>
            </WorkspaceSettingsRoleDropdown>
            <BadgeItem
              v-else-if="!isLoading"
              :label="isInviteRow(row) ? inviteStatusLabels[row.status] : roleLabels[row.role]"
              class="-translate-x-1.5"
              size="sm"
              :variant="isInviteRow(row) ? 'neutral' : 'transparent'"
            />
          </LoadingSkeleton>
        </template>
        <template #menu="{ row }">
          <FloatingMenu
            :positioning="{ placement: 'bottom-end', offset: { mainAxis: 6 }, shift: -6 }"
            trigger-element="div"
            @select="
              (id) => {
                switch (id) {
                  case MenuIds.CopyEmail:
                    onCopy(row['email'])
                    break
                  case MenuIds.ResendInvite: {
                    if (isInviteRow(row)) onResendInvite(row)
                    break
                  }
                  case MenuIds.CopyInviteLink: {
                    if (isInviteRow(row) && row.inviteUrl) {
                      onCopy(row.inviteUrl)
                    }
                    break
                  }
                  case MenuIds.RevokeAccess:
                    onRevoke(row)
                    break
                }
              }
            "
          >
            <template #trigger="{ triggerProps }">
              <IconButton
                class="opacity-0 group-hover/row:opacity-100 data-[state=open]:opacity-100"
                icon="more-dots"
                size="sm"
                aria-label="Open context menu"
                variant="transparent"
                v-bind="omit(triggerProps, ['disabled'])"
              />
            </template>
            <template #content="{ contentProps, getItemProps }">
              <ListMenuContainer
                v-bind="contentProps"
                class="flex w-[180px] flex-col gap-0.5 py-0.5"
              >
                <div class="w-full px-0.5">
                  <ListMenuItem
                    v-if="isClipboardSupported"
                    class="w-full p-0.5"
                    label="Copy email to clipboard"
                    icon="handle"
                    v-bind="omit(getItemProps({ value: MenuIds.CopyEmail }), ['onSelect'])"
                  />
                  <ListMenuItem
                    v-if="isClipboardSupported && isInviteRow(row)"
                    class="w-full p-0.5"
                    label="Resend invite"
                    icon="process"
                    v-bind="omit(getItemProps({ value: MenuIds.ResendInvite }), ['onSelect'])"
                  />
                  <ListMenuItem
                    v-if="isClipboardSupported && isInviteRow(row) && row.inviteUrl"
                    class="w-full p-0.5"
                    label="Copy invite link"
                    icon="link"
                    v-bind="omit(getItemProps({ value: MenuIds.CopyInviteLink }), ['onSelect'])"
                  />
                </div>
                <DividerLine
                  v-if="!isOwner(row['role'])"
                  class="w-full"
                  color="subtle"
                  :width="1"
                />
                <div
                  v-if="!isOwner(row['role'])"
                  class="w-full px-0.5"
                >
                  <ListMenuItem
                    label="Revoke access"
                    critical
                    icon="trash"
                    v-bind="omit(getItemProps({ value: MenuIds.RevokeAccess }), ['onSelect'])"
                  />
                </div>
              </ListMenuContainer>
            </template>
          </FloatingMenu>
        </template>
      </SimpleTable>
    </div>
  </div>
</template>
