<script lang="ts">
export const roleLabelMap: Record<InvitationResponse['role'], string> = {
  admin: 'Admin',
  worker: 'Worker',
  editor: 'Editor',
  reader: 'Reader',
}
</script>

<script setup lang="ts">
import type { InvitationResponse } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import type { OffsetOptions } from '@floating-ui/vue'

defineProps<{
  value: InvitationResponse['role']
  offset?: OffsetOptions
}>()

const emit = defineEmits<{
  (e: 'change', value: InvitationResponse['role']): void
}>()

type Option = {
  id: InvitationResponse['role']
  data: {
    label: string
    id: InvitationResponse['role']
    description: string
  }
}

const OPTIONS: Option[] = [
  {
    id: 'admin',
    data: {
      label: roleLabelMap['admin'],
      id: 'admin',
      description: 'Can view, edit and share',
    },
  },
  {
    id: 'worker',
    data: {
      label: roleLabelMap['worker'],
      id: 'worker',
      description: 'Can view only assigned projects',
    },
  },
]

const onSelect = (option: InvitationResponse['role']) => {
  emit('change', option)
}
</script>

<template>
  <SelectDropdown :offset="offset">
    <template #trigger="{ isOpen }">
      <slot
        name="trigger"
        :is-open="isOpen"
      />
    </template>
    <template #dropdown="{ close }">
      <ListMenu
        :items="OPTIONS"
        :initial-active-item-predicate="(item) => item.id === value"
      >
        <template #item="{ item, active, key, setActiveItem }">
          <ListMenuItem
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onSelect(item.data.id), close()"
          >
            <template #prefix>
              <IconSprite
                :icon="value === item.id ? 'check' : 'blank'"
                class="mr-1 size-5 text-icon-subtle"
              />
            </template>
            <div>
              <div class="text-sm-12px-default text-text">{{ item.data.label }}</div>
              <div class="text-sm-12px-light text-text-subtle">{{ item.data.description }}</div>
            </div>
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </SelectDropdown>
</template>
