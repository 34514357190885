import { computed, type ComputedRef, type Ref } from 'vue'

/** All types of file that we can render within Go */
export type FileType = 'image' | 'pdf' | 'audio' | undefined

/**
 * Given a filename or URL, returns the type of file it is..
 */
export const useFileType = (filename: Ref<string | undefined | null>): ComputedRef<FileType> =>
  computed(() => {
    if (!filename.value) {
      return undefined
    }

    // The filename might be a signed URL. Remove the query parameters to get the extension
    const filenameWithoutQuery = filename.value.replace(/\?.*$/, '')
    const extension = filenameWithoutQuery.split('.').pop()?.toLowerCase() ?? ''

    if (extension === 'pdf') {
      return 'pdf'
    }

    const imageExtensions = ['png', 'jpg', 'jpeg']
    if (imageExtensions.includes(extension)) {
      return 'image'
    }

    const audioExtensions = ['mp3', 'wav', 'ogg']
    if (audioExtensions.includes(extension)) {
      return 'audio'
    }

    // It's a different type of file, one that we don't support
    // inline rendering for.
    return undefined
  })
