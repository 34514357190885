<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

import EntityViewEmptyStateImage from '@/illustrations/entity-view.svg'
import { useAnalytics, ANALYTICS_EVENT } from '@/sharedComposables/useAnalytics'

import EntityViewHeader from './EntityViewHeader.vue'
import ProjectTableViewsTabstrip from './ProjectTableViewsTabstrip.vue'
import ProjectTobBar from './ProjectTopBar.vue'
import { useEntity } from './useEntity'
import { useProjectChannel } from './useProjectChannel'
import { useProjectChannelHandlers } from './useProjectChannelHandlers'
import { useProjectSync } from './useProjectSync'

const props = defineProps<{
  projectId: string
  workspaceId: string
  viewId?: string
}>()

useProjectSync(props)

const entityStore = useEntity()

// The checked state of the lock/unlock layout switch
const lockValue = ref(false)

const projectChannelHandlers = useProjectChannelHandlers()
useProjectChannel(props.projectId, projectChannelHandlers)

onUnmounted(() => {
  entityStore.resetEntities()
  entityStore.setEntityId()
})

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_ENTITY_VIEW)
})
</script>
<!-- for reference - in main.css there is style overwriting for the placeholder -->

<template>
  <div class="grid size-full grid-cols-1 grid-rows-[max-content_1fr] bg-surface-secondary pt-0">
    <ProjectTobBar />

    <div
      class="mx-2 flex h-full flex-1 flex-col overflow-y-auto rounded-corner-12 bg-surface-primary-persist shadow-xs"
    >
      <EntityViewHeader
        v-model:is-layout-locked="lockValue"
        :project-id="projectId"
        :view-id="props.viewId"
      />
      <div class="flex size-full items-center justify-center">
        <div class="flex w-[360px] flex-col items-center gap-4">
          <EntityViewEmptyStateImage class="size-[240px]" />
          <div class="flex flex-col items-center gap-1">
            <h4 class="text-md-13px-bold text-text">No entities available</h4>
            <p class="text-center text-sm-12px-light text-text-subtle">
              There are no entities in this view. Add new entities and come back to view them
              individually.
            </p>
          </div>
        </div>
      </div>
    </div>

    <ProjectTableViewsTabstrip class="mt-2" />
  </div>
</template>
