import type { operations } from '@/api'
import type { PropertyLayout } from '@/modules/Project/useProject'
import { put } from './client'

export type UpdateViewPath = `/api/workspaces/${string}/projects/${string}/views/${string}`
export type UpdateViewResponse =
  operations['project-update-view']['responses']['200']['content']['application/json']

export type UpdateViewRequest = Exclude<
  operations['project-update-view']['requestBody'],
  undefined
>['content']['application/json']

export const updateView = ({
  workspaceId,
  projectId,
  viewId,
  name,
  filters,
  propertyIds,
  propertyLayouts,
  propertyOptions,
  assignablePropertyId,
}: {
  workspaceId: string
  projectId: string
  viewId: string
  name?: UpdateViewRequest['name']
  filters: UpdateViewRequest['filters']
  propertyIds: UpdateViewRequest['property_ids']
  propertyLayouts: PropertyLayout[]
  propertyOptions: UpdateViewRequest['property_options']
  assignablePropertyId: UpdateViewRequest['assignable_property_id']
}) =>
  put<UpdateViewRequest, UpdateViewResponse, UpdateViewPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/views/${viewId}`,
    {
      name,
      filters,
      property_ids: propertyIds,
      property_layouts: propertyLayouts.map(({ propertyId, ...rest }) => ({
        property_id: propertyId,
        ...rest,
      })),
      property_options: propertyOptions,
      assignable_property_id: assignablePropertyId,
    },
  )
