<script setup lang="ts">
import BadgeItem from '@/uiKit/BadgeItem.vue'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import { TELEPORTED_CELL_SELECTOR } from './useTableInteractions'
import { SELECTED_CELL_Z_INDEX } from './useTableZIndices'
import { useTableCellFocus } from './useTableCellFocus'
import type { URLField } from './useProject'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { computed, onMounted, ref, toRef } from 'vue'
import { useElementBounding } from '@vueuse/core'

const props = defineProps<{
  isSelected: boolean
  status: URLField['status'] | undefined
  error?: string | null
  manualValue?: string | string[] | null
  metadata?: URLField['metadata']
}>()

defineEmits<{
  (e: 'edit'): void
}>()

const cell = ref<HTMLElement>()

useTableCellFocus({
  cell,
  // This cell can never be focused, only selected
  isFocused: ref(false),
  isSelected: toRef(props, 'isSelected'),
})

onMounted(() => {
  cell.value?.focus()
})

const { height, left, right } = useElementBounding(cell)
const floatingStyles = computed(() => {
  if (!props.isSelected) {
    return {}
  }
  return {
    minWidth: right.value - left.value + 'px',
    width: 'auto',
    height: 'auto',
    maxHeight: '400px',
    minHeight: height.value + 'px',
    overflow: 'auto',
  }
})

const openInNewTab = () => {
  if (props.manualValue && typeof props.manualValue === 'string') {
    window.open(httpUrl.value, '_blank')
  }
}

const httpUrl = computed(() => {
  if (!props.manualValue || typeof props.manualValue !== 'string') return
  const startsWithHttpOrS = props.manualValue.match(/^https?:\/\//)
  return startsWithHttpOrS ? props.manualValue : `https://${props.manualValue}`
})

const shortUrl = computed(() => {
  const value = httpUrl.value
  if (!value) return ''
  const url = new URL(value)
  const path =
    url.pathname.length > 41
      ? url.pathname.slice(0, 20) + '...' + url.pathname.slice(20).slice(-20)
      : url.pathname
  return url.host + (path.length > 1 ? path : '')
})
</script>

<template>
  <div
    ref="cell"
    class="relative h-full shrink grow basis-0 p-1.5 text-sm-12px-light text-text outline-none"
    :class="[status !== 'error' && 'text-text-subtlest', !isSelected && 'line-clamp-1 truncate']"
  >
    <div
      v-if="isSelected"
      ref="containerRef"
      :style="floatingStyles"
      :[`data-${TELEPORTED_CELL_SELECTOR}`]="''"
      class="absolute left-0 top-0 box-border rounded-corner-4 bg-surface-primary shadow-md outline outline-2 outline-border-focused"
      :class="{
        [SELECTED_CELL_Z_INDEX]: true,
      }"
    >
      <div class="p-1.5">
        <div class="flex flex-row justify-between gap-1">
          <BadgeItem
            size="sm"
            :label="shortUrl"
            variant="neutral"
            class="cursor-pointer"
            underline
            @click.stop="openInNewTab"
          >
            <template #leading-icon>
              <CircularProgress
                v-if="status === 'computing'"
                size="xs"
                color="neutral"
              />
              <ObjectURLImage
                v-else-if="metadata?.favicon?.url"
                class="size-4 overflow-hidden rounded-corner-4"
                :url="metadata?.favicon?.url"
                :loading="false"
              >
                <template #fallback>
                  <LoadingSkeleton
                    class="size-4"
                    :status="true"
                  />
                </template>
              </ObjectURLImage>
              <IconSprite
                v-else
                icon="link"
                size="xs"
                color="error"
              />
            </template>
          </BadgeItem>
          <IconButton
            variant="transparent"
            icon="edit"
            size="sm"
            @click="$emit('edit')"
          />
        </div>
        <div
          v-if="metadata?.screenshot?.url"
          class="w-[600px] pt-1 before:-mx-2 before:mb-1 before:block before:border-t before:border-border-subtle"
        >
          <img
            class="rounded-corner-4"
            :src="metadata.screenshot.url"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="inline-block"
    >
      <BadgeItem
        size="sm"
        :label="shortUrl"
        variant="neutral"
      >
        <template #leading-icon>
          <CircularProgress
            v-if="status === 'computing'"
            size="xs"
            color="neutral"
          />
          <ObjectURLImage
            v-else-if="metadata?.favicon?.url"
            class="size-4 shrink-0 overflow-hidden rounded-corner-4"
            :url="metadata?.favicon?.url"
            :loading="false"
          >
            <template #fallback>
              <LoadingSkeleton
                :status="true"
                class="size-4"
              />
            </template>
          </ObjectURLImage>
          <IconSprite
            v-else
            icon="link"
            size="xs"
            color="error"
          />
        </template>
      </BadgeItem>
    </div>
  </div>
</template>
