<script setup lang="ts">
import { computed } from 'vue'

import { type SimpleFilter } from '@/modules/Project/useFilters'
import type { Property } from '@/modules/Project/useProject'

import FilterBarSelectItem from './FilterBarSelectItem.vue'
import FilterBarTextItem from './FilterBarTextItem.vue'

const props = defineProps<{
  filter: SimpleFilter
  index: number
  property: Property | undefined
}>()

defineEmits<{
  (e: 'delete'): void
  (e: 'values', values: string[]): void
}>()

const itemComponent = computed(() => {
  if (!props.property) return 'div'
  if (props.property.type === 'text') {
    return FilterBarTextItem
  }
  if (['single_select', 'multi_select', 'user_select'].includes(props.property.type)) {
    return FilterBarSelectItem
  }
  return 'div'
})
</script>

<template>
  <component
    :is="itemComponent"
    :filter="filter"
    :index="index"
    :property="property"
    @delete="$emit('delete')"
  />
</template>
