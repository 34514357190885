<script lang="ts" setup>
/**
 * Implementation of
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Design-System-(WIP)?type=design&node-id=1696-12342&mode=design&t=wFkzpO9SwKxhAPQZ-0
 *
 * Because some things around it are still unclear, this is built in a very configurable way.
 * As we iron out our scenarios, we may want to have prebuilt versions of this component,
 * that already assemble a dialog from it's building blocks and have a simpler API.
 */
import { onClickOutside, useEventListener } from '@vueuse/core'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { ref } from 'vue'

import TransitionOpacity from './TransitionOpacity.vue'
import IconButton from './IconButton.vue'

const props = withDefaults(
  defineProps<{
    open: boolean
    to?: string
    placement?: 'center' | 'left' | 'right'
    closeOnEscape?: boolean
    outline?: boolean
    disableFocusTrap?: boolean
    fullscreen?: boolean
  }>(),
  {
    to: 'body',
    closeOnEscape: true,
    placement: 'center',
    outline: true,
    disableFocusTrap: false,
    hideHeaderBorder: false,
    hideFooterBorder: false,
    fullscreen: false,
  },
)

const emit = defineEmits<{ (e: 'close', event: Event): void }>()

defineOptions({ inheritAttrs: false })

const container = ref<HTMLElement>()

onClickOutside(container, (e) => emit('close', e))

useEventListener(document, 'keydown', (e) => {
  if (e.key === 'Escape') {
    if (!props.closeOnEscape && props.open) {
      e.preventDefault()
      e.stopPropagation()
    }
    emit('close', e)
  }
})
</script>

<template>
  <Teleport :to="to">
    <TransitionOpacity>
      <div
        v-if="open"
        class="fixed inset-0 bg-blanket-gradient"
        v-bind="$attrs"
      ></div>
    </TransitionOpacity>

    <Transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-200 ease-in"
      enter-from-class="opacity-0 scale-[98%]"
      leave-to-class="opacity-0 scale-[98%]"
    >
      <dialog
        v-if="open"
        class="fixed inset-0 grid size-full bg-background-transparent"
        :class="{
          'place-items-center': placement === 'center',
          'place-items-start': placement === 'left',
          'place-items-end': placement === 'right',
          'p-2': fullscreen,
        }"
        v-bind="$attrs"
        open
        @keydown.escape="emit('close', $event)"
      >
        <component
          :is="disableFocusTrap ? 'div' : UseFocusTrap"
          ref="container"
          :options="{ immediate: true }"
          :class="fullscreen && 'size-full min-h-0'"
        >
          <div
            :class="[
              outline && 'bg-surface-popover shadow-lg outline outline-1 outline-border-subtle',
              placement === 'center' && 'rounded-corner-16',
              placement === 'left' && 'rounded-r-corner-16',
              placement === 'right' && 'rounded-l-corner-16',
              fullscreen && 'size-full',
            ]"
          >
            <slot>
              <div class="relative flex max-w-[480px] flex-col overflow-hidden">
                <IconButton
                  size="lg"
                  variant="transparent"
                  class="absolute right-2 top-2 text-text-subtle"
                  icon="close"
                  rounded
                  @click="emit('close', $event)"
                />
                <div
                  v-if="$slots.header"
                  :class="!!($slots.body || $slots.footer) && 'border-b border-border-subtle'"
                  class="px-5 py-4"
                >
                  <slot name="header" />
                </div>
                <div
                  v-if="$slots.body"
                  :class="!!$slots.footer && 'border-b border-border-subtle'"
                  class="p-5"
                >
                  <slot name="body" />
                </div>
                <div class="flex flex-row items-center justify-end gap-2 p-3">
                  <slot
                    name="footer"
                    :close="(e: Event) => emit('close', e)"
                  />
                </div>
              </div>
            </slot>
          </div>
        </component>
      </dialog>
    </Transition>
  </Teleport>
</template>
