import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * We sometimes need to render a popover over an arbitrary DOM element, in response to a reactive
 * state change, or a network response (e.g. when Ask Go creates a new property, we render a
 * popover over the property header in the project table).
 *
 * This store provides a way to set the target element and the text to display in the popover.
 */
export const usePopoverStore = defineStore('popover', () => {
  /** CSS selector of the element that the popover is anchored to */
  const targetSelector = ref<string | null>(null)

  /** Text to display in the popover */
  const popoverText = ref<string | null>(null)

  return {
    targetSelector,
    popoverText,
  }
})
