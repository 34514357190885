import type { components } from '@/api'
import { put } from './client'

export type SetFieldValuePath = `/api/workspaces/${string}/projects/${string}/entities/${string}`
export type SetFieldValueRequest = components['schemas']['update_entity']

export type UpdateEntityFieldValue = Extract<
  components['schemas']['field_value'],
  Record<string, unknown> | null
>

export type PutEntityResponse = components['schemas']['Projects.Entities.EntityResponse']

export const setFieldValue = ({
  workspaceId,
  projectId,
  entityId,
  propertyId,
  value,
}: {
  workspaceId: string
  projectId: string
  entityId: string
  propertyId: string
  value: UpdateEntityFieldValue
}) => {
  return put<SetFieldValueRequest, PutEntityResponse, SetFieldValuePath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities/${entityId}`,

    { fields: { [propertyId]: value } },
  )
}
