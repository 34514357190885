import type { components, operations } from '@/api'
import { get } from './client'

export type ListEntitiesPath = `/api/workspaces/${string}/projects/${string}/entities`
export type ListEntitiesResponse =
  components['schemas']['Projects.ListEntities.ListEntitiesResponse']
export type ListEntitiesRequest = operations['entity-list']['parameters']['query']

export const listEntities = (
  workspaceId: string,
  projectId: string,
  start: number,
  end: number,
  parent_entity_id?: string,
  viewId?: string,
) =>
  get<ListEntitiesRequest, ListEntitiesResponse, ListEntitiesPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/entities`,
    {
      order_by: ['id'],
      limit: end - start + 1,
      offset: start,
      parent_entity_id,
      active_view_ids: viewId !== undefined ? [viewId] : undefined,
    },
  )
