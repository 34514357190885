<script setup lang="ts">
import { FeatureFlag, useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { useAskGo } from '@/modules/Project/useAskGo'
import AskGoTopbar from '@/modules/Workspaces/AskGoTopbar.vue'
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { computed, ref } from 'vue'
import SupportButton from './SupportButton.vue'
import PermissionsDialog from '@/modules/Project/Permissions/PermissionsDialog.vue'

const props = defineProps<{
  workspaceId: string
  projectId: string
  parentProjectId?: string | null
}>()

const { isOpen } = useSidebar()

const hasIntercomChat = useHasIntercomChat()

const isAskGoEnabled = useFeatureFlags(FeatureFlag.ASK_GO_PROJECT_CREATION)

const isProjectLevelPermissionsEnabled = useFeatureFlags(FeatureFlag.PROJECT_LEVEL_PERMISSIONS)
const isPermissionsDialogOpen = ref(false)
const rootProjectId = computed(() => props.parentProjectId || props.projectId)

const askGoStore = useAskGo()
</script>

<template>
  <div
    class="relative flex w-full flex-row items-center justify-between gap-2 bg-surface-secondary-persist px-[10px] py-2"
  >
    <div
      class="relative flex-1 items-center"
      :style="{
        display: 'grid',
        gridTemplateColumns: '1fr 320px 1fr',
      }"
    >
      <div
        class="absolute flex items-center transition duration-300 ease-in-out-quint"
        :class="
          isOpen ? 'pointer-events-none translate-x-[-8px] opacity-0' : 'translate-x-0 opacity-100'
        "
      >
        <SidebarToggle
          data-test="project-sidebar-toggle"
          :disable-tooltip="isOpen"
        />
        <div class="ml-[9.5px] h-[20px] w-[2px] rounded border border-border-subtle"></div>
      </div>

      <nav
        class="flex flex-row items-center justify-start overflow-hidden transition-all duration-300 ease-in-out-quint [&>*]:grow-0"
        :class="isOpen ? 'pl-0' : 'pl-[48px]'"
        aria-label="crumbs"
      >
        <slot />
      </nav>

      <AskGoTopbar v-if="isAskGoEnabled && askGoStore.isAskGoAvailable" />

      <div class="flex justify-end gap-2">
        <DarwinButton
          v-if="projectId && isProjectLevelPermissionsEnabled"
          size="sm"
          variant="black"
          @click="isPermissionsDialogOpen = true"
          >Share</DarwinButton
        >
        <SupportButton v-if="hasIntercomChat" />
      </div>
    </div>

    <PermissionsDialog
      :open="isPermissionsDialogOpen"
      :root-project-id="rootProjectId"
      :workspace-id="workspaceId"
      @close="isPermissionsDialogOpen = false"
    />
  </div>
</template>
