<script setup lang="ts">
import { ref, watch } from 'vue'
import { usePopoverStore } from '@/sharedComposables/popoverStore'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PopOver from '@/uiKit/PopOver.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'

const popoverStore = usePopoverStore()

const uploadProgress = ref(0)
const PROGRESS_INTERVAL = 5000
const UPDATE_INTERVAL = 100
const interval = ref<NodeJS.Timeout | null>(null)

const popoverLabel = 'app-popover-label'

watch(
  () => popoverStore.targetSelector,
  (newVal) => {
    if (newVal) {
      // Reset progress
      uploadProgress.value = 0
      if (interval.value) {
        clearInterval(interval.value)
      }

      // Simulate progress.
      interval.value = setInterval(() => {
        uploadProgress.value += UPDATE_INTERVAL / PROGRESS_INTERVAL
      }, UPDATE_INTERVAL)
    }
  },
)

watch(
  () => uploadProgress.value,
  (newVal) => {
    if (newVal >= 1) {
      popoverStore.targetSelector = null
      if (interval.value) {
        clearInterval(interval.value)
      }
    }
  },
)
</script>

<template>
  <PopOver
    v-if="popoverStore.targetSelector"
    open
    :target-selector="popoverStore.targetSelector"
    :placement="{ allowedPlacements: ['bottom'] }"
    arrow
    role="dialog"
    :aria-labelledby="popoverLabel"
  >
    <template #content>
      <div
        class="relative flex w-max max-w-[352px] items-center gap-1 overflow-hidden rounded-corner-6 border border-border bg-surface-popover-inverted p-2 text-text-inverted-irreversible"
      >
        <div :id="popoverLabel">
          {{ popoverStore.popoverText }}
        </div>
        <CircularProgress
          class="shrink-0"
          size="sm"
          :value="uploadProgress"
        />
        <DarwinButton
          size="xs"
          variant="black"
          @click="popoverStore.targetSelector = null"
          >Dismiss</DarwinButton
        >
      </div>
    </template>
  </PopOver>
</template>
