/**
 * The horizontal offset is equal to the amount of space to the side of the item,
 * so that the submenu is aligned to the edge of the container, and not the edge
 * of the menu item.
 */
export const HORIZONTAL_OFFSET = 4
/**
 * The vertical offset ensures that the text of the submenu items align with the
 * text of the menu item that opens the submenu. Check with a designer after changing
 * this because it's a complicated number to arrive at and dev eyes (mine included)
 * are not the best.
 *
 * The number is calculated as MENU_ITEM_HEIGHT - SUBMENU_PADDING_TOP.
 */
export const VERTICAL_OFFSET = 26
