import { useRouter } from 'vue-router'

export const useRoutes = () => {
  const router = useRouter()

  const route = router.currentRoute.value

  const currentlyInSubProject =
    route.name === 'WorkspaceSubProjectTable' ||
    route.name === 'WorkspaceSubProjectTableView' ||
    route.name === 'WorkspaceSubProjectEntityView' ||
    route.name === 'WorkspaceSubProjectEntitySubView'

  const currentlyInEntityView =
    route.name === 'WorkspaceProjectEntityView' ||
    route.name === 'WorkspaceProjectEntitySubView' ||
    route.name === 'WorkspaceSubProjectEntityView' ||
    route.name === 'WorkspaceSubProjectEntitySubView'

  return { route, currentlyInSubProject, currentlyInEntityView }
}
