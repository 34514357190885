/**
 * Returns a new object with the specified keys omitted.
 *
 * @export
 * @template {Record<string, unknown>} T
 * @template {keyof T} K
 * @param {T} obj
 * @param {K[]} keys
 * @returns {Omit<T, K>}
 */

import { isNil } from '.'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omit<T extends Record<PropertyKey, any>, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  const result = {} as Record<string, unknown>
  Object.keys(obj).forEach((key) => {
    if (!keys.includes(key as K)) {
      result[key] = obj[key]
    }
  })
  return result as Omit<T, K>
}

/**
 * Map key/value pairs for an object, and construct a new one
 *
 *
 * @category Object
 *
 * Transform:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => [k.toString().toUpperCase(), v.toString()])
 * // { A: '1', B: '2' }
 * ```
 *
 * Swap key/value:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => [v, k])
 * // { 1: 'a', 2: 'b' }
 * ```
 *
 * Filter keys:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => k === 'a' ? undefined : [k, v])
 * // { b: 2 }
 * ```
 */
export function objectMap<K extends PropertyKey, V, NK extends PropertyKey = K, NV = V>(
  obj: Record<K, V>,
  fn: (key: K, value: V) => [NK, NV] | undefined,
): Record<NK, NV> {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => fn(k as K, v as V))
      .filter((entry) => !isNil(entry)) as [NK, NV][],
  ) as unknown as Record<NK, NV>
}

/**
 * Returns an object from another one, containing only properties
 * that, when passed into the callback function, return true.
 *
 * @example
 * ```ts
 * const obj = { a: 1, b: 2, c: 3, d: 4 }
 * const filtered = objectFilter(obj, (key, value) => {
 *  return value % 2 === 0 && key !== 'd'
 * })
 * console.log(filtered) // { b: 2 }
 * ```
 */
export function objectFilter<K extends PropertyKey, V>(
  obj: Record<K, V>,
  fn: (key: K, value: V) => boolean,
): Record<K, V> {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => fn(k as K, v as V)) as [K, V][],
  ) as unknown as Record<K, V>
}

type StripUndefined<Obj extends Record<string, unknown>> = {
  [K in keyof Obj as Obj[K] extends undefined ? never : K]: Obj[K]
}

/**
 * Removes all properties whose value is `undefined`
 */
export function stripUndefined<Obj extends Record<string, unknown>>(obj: Obj) {
  return objectFilter(obj, (_, v) => v !== undefined) as StripUndefined<Obj>
}

type KeysOfUnion<T> = T extends T ? keyof T : never
type ValueOfUnion<T, K extends KeysOfUnion<T>> = T extends {
  [P in K]?: infer U
}
  ? U
  : never
/**
 * Get a property from an object. Useful on union types.
 *
 * @example
 * ```ts
 * // Before
 * const prop = 'a' in obj.deep1 ? obj.deep1.a : 'a' in obj.deep2 ? obj.deep2.a : undefined
 *
 * // After
 * const prop = getProperty('a', [obj.deep1, obj.deep2])
 * ```
 *
 * Accepts a valid fn callback, which by default will return `true` if the value is not `undefined` or `null`.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUnionProperty<T extends Record<string, any>, K extends KeysOfUnion<T>>(
  property: K,
  objects: Array<T | undefined>,
  isValid: (value: ValueOfUnion<T, K>) => boolean = (value) => {
    return value !== undefined && value !== null
  },
): ValueOfUnion<T, K> | undefined {
  for (const obj of objects) {
    if (obj && property in obj && isValid(obj[property])) {
      return obj[property]
    }
  }
  return undefined
}

type ToMap<K extends string> = { [key in K]: string } & { [key: string]: unknown }

/**
 * Grabs an array with objects, and a unique key. Creates an object whose
 * key represents said unique key.
 *
 * @example
 * ```ts
 * const arr = [{id: 0, name: 'a'}, {id: 1, name: 'b'}]
 * const obj = objectMapBy(arr, 'id')
 * console.log(obj) // { 0: { id: 0, name: 'a' }, 1: { id: 1, name: 'b' } }
 * ```
 */
export function objectMapBy<K extends string, T extends ToMap<K>>(
  arr: T[],
  key: K,
): Record<T[K], T> {
  return arr.reduce(
    (acc, item) => {
      acc[item[key] as T[K]] = item
      return acc
    },
    {} as Record<T[K], T>,
  )
}
