import { updateView } from '@/backend/updateView'
import { ITEM_HEIGHT } from '@/modules/Project/ProjectTable.vue'
import { useElementSize, useMouse } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, type Ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useProject } from './useProject'

export const useColumnReordering = (header: Ref<HTMLElement | null>) => {
  const propertyIdBeingDragged = ref<string | null>(null)
  const draggedElementHeader = ref<HTMLElement | null>(null)
  const mouseDragXStartPosition = ref<number | null>(null)
  const hasMoved = ref(false)

  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const size = useElementSize(draggedElementHeader)
  const mousePosition = useMouse()

  const xDragOffset = computed(() => {
    if (!draggedElementHeader.value || !mouseDragXStartPosition.value) {
      return 0
    }
    return mouseDragXStartPosition.value - draggedElementHeader.value.getBoundingClientRect().left
  })

  const dragBoxStyles = computed(() => ({
    width: `${size.width.value}px`,
    height: `${(header.value?.getBoundingClientRect().height ?? 0) + (projectStore.activeView?.entities?.length ?? 0) * ITEM_HEIGHT}px`,
    left: `${mousePosition.x.value - xDragOffset.value}px`,
    top: `${header.value?.getBoundingClientRect().top}px`,
  }))

  const getLinePosition = computed(() => {
    if (!header.value || potentialNewIndex.value === null) {
      return null
    }
    const headerItem =
      header.value.querySelectorAll('[role="columnheader"]')[potentialNewIndex.value]
    return headerItem
      ? headerItem.getBoundingClientRect().left
      : (header.value.querySelector('[role="columnheader"]:last-of-type')?.getBoundingClientRect()
          .right ?? null)
  })

  const lineStyles = computed(() => ({
    left: `${getLinePosition.value ?? 0}px`,
    top: `${header.value?.getBoundingClientRect().top}px`,
    height: `${(header.value?.getBoundingClientRect().height ?? 0) + (projectStore.activeView?.entities?.length ?? 0) * ITEM_HEIGHT}px`,
  }))

  const onHeaderItemMouseDown = (propId: string, event: MouseEvent) => {
    propertyIdBeingDragged.value = propId
    draggedElementHeader.value = event.currentTarget as HTMLElement
    mouseDragXStartPosition.value = mousePosition.x.value
  }

  const shiftPropertiesAndSave = async () => {
    if (potentialNewIndex.value === null || !propertyIdBeingDragged.value || hasMoved.value) {
      return
    }
    // optimistically update the properties in the store for instant feedback
    const { ok, oldPropertyIds } = projectStore.shiftPropertyToIndex(
      propertyIdBeingDragged.value,
      potentialNewIndex.value,
    )
    if (
      projectStore.activeView &&
      workspacesStore.currentWorkspace &&
      projectStore.projectId &&
      ok
    ) {
      const result = await updateView({
        workspaceId: workspacesStore.currentWorkspace.id,
        projectId: projectStore.projectId,
        viewId: projectStore.activeView.id,
        name: projectStore.activeView.view.name,
        propertyIds: projectStore.activeView.view.propertyIds ?? [],
        propertyLayouts: projectStore.activeView.view.propertyLayouts,
        filters: projectStore.activeView.view.filters,
        propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
        assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
      })
      if (!result.ok && oldPropertyIds) {
        // rollback the change if the update failed
        projectStore.upsertView({
          id: projectStore.activeView.id,
          propertyIds: oldPropertyIds,
          propertyLayouts: projectStore.activeView.view.propertyLayouts,
          filters: projectStore.activeView.view.filters,
          name: projectStore.activeView.view.name,
          propertyOptions: projectStore.activeView.view.propertyOptions,
          assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
        })
      }
    }
  }

  const potentialNewIndex = computed(() => {
    if (!draggedElementHeader.value) {
      return null
    }
    const centersOfColumns = header.value
      ? Array.from(header.value.querySelectorAll('[role="columnheader"]')).map((el) => {
          const rect = el.getBoundingClientRect()
          return rect.left + rect.width / 2
        })
      : []

    const newIndex = centersOfColumns.findIndex((center) => mousePosition.x.value < center)
    const result = newIndex === -1 ? centersOfColumns.length : newIndex
    const existingIndex = projectStore.visibleProperties.findIndex(
      (prop) => prop.id === propertyIdBeingDragged.value,
    )
    if (result === existingIndex) {
      return null
    }
    return result
  })

  const onWindowMouseMove = () => {
    if (propertyIdBeingDragged.value) {
      hasMoved.value = true
    }
  }

  const onWindowMouseUp = () => {
    hasMoved.value = false
    if (propertyIdBeingDragged.value) {
      shiftPropertiesAndSave()
      propertyIdBeingDragged.value = null
    }
  }

  onMounted(() => {
    window.addEventListener('mousemove', onWindowMouseMove)
    window.addEventListener('mouseup', onWindowMouseUp)
  })

  onUnmounted(() => {
    window.removeEventListener('mousemove', onWindowMouseMove)
    window.removeEventListener('mouseup', onWindowMouseUp)
  })

  return {
    propertyIdBeingDragged,
    onHeaderItemMouseDown,
    hasMoved,
    dragBoxStyles,
    potentialNewIndex,
    lineStyles,
  }
}
