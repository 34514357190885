import { listEntities } from '@/backend/listEntities'
import { listFilteredEntities } from '@/backend/listFilteredEntities'
import { useFilters } from './useFilters'

/**
 * This composable returns a function that can be used to list entities in a
 * project, taking into account the current filter state.
 */
export const useListEntities = () => {
  const filterStore = useFilters()

  return async ({
    end,
    projectId,
    start,
    viewId,
    workspaceId,
    parentEntityId,
  }: {
    workspaceId: string
    projectId: string
    start: number
    end: number
    parentEntityId?: string
    viewId?: string
  }) => {
    if (filterStore.currentFilter?.filters.length) {
      let filter = filterStore.currentFilter
      if (parentEntityId) {
        filter = {
          conjunction: 'and',
          filters: [
            {
              subject: 'parent_entity_id',
              matcher: {
                name: 'any_of',
                values: [parentEntityId],
              },
            },
            filter,
          ],
        }
      }

      return listFilteredEntities({
        workspaceId,
        projectId,
        offset: start,
        limit: end - start,
        filter,
      })
    }

    return listEntities(workspaceId, projectId, start, end, parentEntityId, viewId)
  }
}
