<script setup lang="ts">
import { computed } from 'vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import { storeToRefs } from 'pinia'
import { useProject } from './useProject'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ProjectTableActionBarSetValues from './ProjectTableActionBarSetValues.vue'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import ToolTip from '@/uiKit/ToolTip.vue'

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'setValue', propertyId: string, value: Partial<Record<string, string[] | null>>): void
}>()
const onSetValue = (propertyId: string, value: Partial<Record<string, string[] | null>>) =>
  emit('setValue', propertyId, value)

const projectStore = useProject()

const { selectedEntityIds, activeView, allEntitiesSelected } = storeToRefs(projectStore)

const isPartialSelection = computed(
  () =>
    selectedEntityIds.value.size > 0 &&
    activeView.value &&
    activeView.value.entities &&
    selectedEntityIds.value.size < activeView.value.entities.length,
)

const canBulkEditEntities = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
const { canBulkDeleteEntities } = useWorkspacePermissions()
</script>

<template>
  <div
    class="inline-flex items-center overflow-hidden rounded-xl bg-surface-tertiary shadow-sm outline outline-1 outline-border-subtle"
  >
    <div class="rounded-lg pl-3 pr-4 pt-px text-sm-12px-default">
      {{ selectedEntityIds.size }} Selected
    </div>
    <div
      class="flex gap-1.5 rounded-xl bg-surface-primary p-1 outline outline-1 outline-border-subtle"
    >
      <CheckBox
        label="Select All"
        class="px-2 py-1 text-sm-12px-default"
        :checked="allEntitiesSelected"
        :indeterminate="isPartialSelection"
        @change="projectStore.toggleAllEntities"
      />
      <ProjectTableActionBarSetValues
        v-if="canBulkEditEntities"
        @set-value="onSetValue"
      />
      <DarwinButton
        size="sm"
        :disabled="!canBulkDeleteEntities"
        variant="critical-subtle"
        data-test="action-bar-delete-button"
        @click="$emit('delete')"
      >
        <ToolTip
          title="The permissions for your user role do not allow you to delete entities"
          :arrow="true"
          :disabled="canBulkDeleteEntities"
          :placement="{ allowedPlacements: ['top'] }"
        >
          <div class="flex gap-1">
            <IconSprite icon="trash" />
            Delete
          </div>
        </ToolTip>
      </DarwinButton>
    </div>
  </div>
</template>
