import { useBilling } from '../Billing/useBilling'
import { useLimitedAction } from '../Billing/useLimitedAction'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { serializeEntity, useProject } from './useProject'

export function useCreateEntity() {
  const { addEntity } = useLimitedAction()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const billingStore = useBilling()

  async function createEntity(): Promise<string | null> {
    if (!projectStore.projectId || !workspacesStore.currentWorkspace) {
      return null
    }
    const result = await addEntity(workspacesStore.currentWorkspace.id, projectStore.projectId)
    if (!result.ok) return null
    projectStore.upsertEntity(serializeEntity(result.data))

    if (billingStore.fieldUsage) {
      billingStore.fieldUsage.limitUsage += projectStore.properties.length
    }

    return result.data.id
  }

  return {
    createEntity,
  }
}
