<script setup lang="ts">
import DividerLine from '@/uiKit/DividerLine.vue'
import WorkspaceSettingsAiModelsProvider from './WorkspaceSettingsAiModelsProvider.vue'
import { useModelProviders } from './modelProvidersStore'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DotIllustration from '@/illustrations/dots.svg'
import { useBilling } from '../Billing/useBilling'
import { computed } from 'vue'
import { setIntegrationEnabled } from '@/backend/setIntegrationEnabled'
import type { ToolProvider } from '@/backend/types'
const props = defineProps<{ workspaceId: string }>()

const billingStore = useBilling()
const isEnterprisePlan = computed(() => billingStore.activePlan?.name === 'enterprise')

const providerStore = useModelProviders()
const onToggleProvider = async (provider: ToolProvider) => {
  const newEnabled = !providerStore.providers[provider].enabled
  providerStore.providers[provider].enabled = newEnabled
  const res = await setIntegrationEnabled({
    enabled: newEnabled,
    provider,
    workspaceId: props.workspaceId,
  })

  if (!res.ok) {
    providerStore.providers[provider].enabled = !newEnabled
  }
}
</script>

<template>
  <div class="mx-auto my-16 w-full max-w-[760px] px-4">
    <h1 class="text-xl-18px-bold text-text">AI Models</h1>
    <p class="text-sm-12px-light text-text-subtle">
      Connect models to your own API Key instead of consuming Go Tokens, and restrict which models
      are shown in the list of AI tools.
    </p>
    <DividerLine
      class="my-8"
      color="subtle"
      direction="horizontal"
    />
    <div
      v-if="!isEnterprisePlan"
      class="mb-2 flex h-16 items-center justify-between gap-4 rounded-xl bg-background-gray-subtlest pr-4"
    >
      <DotIllustration />
      <div class="grow text-md-13px-default text-text">
        Model management is only available to Enterprise users.
      </div>
      <DarwinButton
        size="sm"
        variant="black"
        target="_blank"
        href="http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upg[…]m_medium=go&utm_term=contact-sales&utm_content=ai-models-menu"
        >Contact Sales</DarwinButton
      >
    </div>
    <WorkspaceSettingsAiModelsProvider
      :enabled="providerStore.providers.open_ai.enabled"
      :disable-editing="!isEnterprisePlan"
      provider="open_ai"
      @toggle="onToggleProvider('open_ai')"
    />
    <DividerLine
      class="pl-24"
      color="subtle"
    />
    <WorkspaceSettingsAiModelsProvider
      provider="google_ai"
      :disable-editing="!isEnterprisePlan"
      :enabled="providerStore.providers.google_ai.enabled"
      @toggle="onToggleProvider('google_ai')"
    />
    <DividerLine
      class="pl-24"
      color="subtle"
    />
    <WorkspaceSettingsAiModelsProvider
      :enabled="providerStore.providers.anthropic.enabled"
      :disable-editing="!isEnterprisePlan"
      provider="anthropic"
      @toggle="onToggleProvider('anthropic')"
    />
    <WorkspaceSettingsAiModelsProvider
      v-if="providerStore.providers.azure_open_ai.enabled"
      :enabled="providerStore.providers.azure_open_ai.enabled"
      :disable-editing="true"
      provider="azure_open_ai"
      @toggle="onToggleProvider('azure_open_ai')"
    />
  </div>
</template>
