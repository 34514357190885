import type { Field } from '../useProject'

export const getCaseInsensitiveValue = (v: Field['manualValue']): Field['manualValue'] => {
  if (v === null) {
    return v
  }

  if (Array.isArray(v)) {
    return v.map((s) => s.toLowerCase())
  }

  return v.toLowerCase()
}
