<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'
import * as tabs from '@zag-js/tabs'
import { normalizeProps, useMachine } from '@zag-js/vue'

/**
 * https://www.figma.com/design/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?node-id=618-51619&t=QV6cbLyrzLb9xVLA-0
 *
 * Renders a horizontal list of tabs. Follows the spec for the
 * ARIA tablist role: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tablist_role
 *
 */

type Item = {
  value: string
  label: string
  leadingIcon?: IconName
}

const props = defineProps<{
  items: Item[]
  value: string
  /**
   * Unique value, required so that the zag-js state machine works correctly.
   */
  id: string
}>()
const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const [state, send] = useMachine(
  tabs.machine({
    id: props.id,
    value: props.value,
    activationMode: 'manual',
    orientation: 'horizontal',
    onValueChange: ({ value }) => {
      emit('change', value)
    },
    loopFocus: true,
  }),
)
const api = computed(() => tabs.connect(state.value, send, normalizeProps))

watch(
  () => props.value,
  (newValue) => {
    api.value.setValue(newValue)
  },
)

/**
 * Helper function. Returns true if the item is the currently active tab.
 */
const isActive = (item: Item): boolean => api.value.value === item.value

const tabListRef = ref<HTMLElement | null>(null)

// When there are 2 unselected items next to each other, there should be a small
// divider between them
const hasRightBorder = (item: Item): boolean => {
  const thisIndex = props.items.findIndex((i) => i.value === item.value)

  const nextItem = props.items[thisIndex + 1]
  if (!nextItem) {
    return false
  }

  const thisItemIsSelected = isActive(item)
  const nextItemIsSelected = isActive(nextItem)

  return !thisItemIsSelected && !nextItemIsSelected
}
</script>

<template>
  <div
    :id="id"
    ref="tabListRef"
    v-bind="api.getRootProps()"
  >
    <div
      class="flex items-center"
      v-bind="api.getListProps()"
    >
      <template
        v-for="(item, i) in items"
        :key="i"
      >
        <button
          class="mx-1 line-clamp-1 flex cursor-pointer items-center gap-1 whitespace-nowrap rounded-lg px-3 py-1.5 text-sm-12px-default transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-border-focused"
          :class="[
            isActive(item)
              ? 'bg-surface-primary text-text shadow-xs'
              : 'bg-background-transparent text-text-subtle hover:bg-background-transparent-hovered',
          ]"
          v-bind="api.getTriggerProps({ value: item.value })"
        >
          <IconSprite
            v-if="item.leadingIcon"
            :icon="item.leadingIcon"
            :class="isActive(item) ? 'text-icon-subtle' : 'text-icon-subtlest'"
          />
          <div>
            {{ item.label }}
          </div>
        </button>
        <div
          class="h-4 w-0.5 bg-border-subtle"
          :class="!hasRightBorder(item) && 'opacity-0'"
        />
      </template>
    </div>
  </div>
</template>
