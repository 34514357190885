<script setup lang="ts">
import ToolTip from '@/uiKit/ToolTip.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'

defineProps<{
  fieldHasGroundTruth?: boolean
  isCorrectedModelOutput?: boolean
  hasGroundingClaim?: boolean
  lastCorrectedBy?: {
    name?: string | null
    avatarUrl?: string | null
  }
  tool?: {
    name: string
    icon: IconName
  }
}>()

defineEmits<{
  (e: 'open' | 'reset' | 'star' | 'open-mousedown' | 'reset-mousedown' | 'star-mousedown'): void
}>()

const labelId = 'project-table-cell-tooltip-label'
</script>

<template>
  <div class="flex rounded-corner-8 bg-surface-popover-inverted">
    <div class="flex items-center">
      <div
        class="p-1.5"
        data-theme="dark"
      >
        <AvatarIcon
          v-if="isCorrectedModelOutput"
          :url="lastCorrectedBy?.avatarUrl"
          :full-text="lastCorrectedBy?.name"
          shape="circle"
          size="xs"
        />
        <IconSprite
          v-else-if="tool"
          class="text-text-inverted-irreversible"
          :icon="tool.icon"
          size="md"
        />
      </div>
      <div
        v-if="lastCorrectedBy"
        :id="labelId"
        class="grow cursor-default py-1.5 pr-2 text-sm-12px-light text-text-inverted-irreversible"
      >
        Corrected by {{ lastCorrectedBy.name }}
      </div>
      <div
        v-else-if="tool"
        :id="labelId"
        class="grow cursor-default py-1.5 pr-2 text-sm-12px-light text-text-inverted-irreversible"
      >
        Computed by {{ tool.name }}
      </div>
    </div>
    <div
      class="flex"
      data-theme="dark"
    >
      <DividerLine
        direction="vertical"
        color="default"
      />
      <DarwinButton
        v-if="isCorrectedModelOutput"
        size="sm"
        variant="transparent"
        class="w-full rounded-none"
        @click="$emit('reset')"
        @mousedown="$emit('reset-mousedown')"
      >
        Reset
      </DarwinButton>
      <DividerLine
        v-if="isCorrectedModelOutput"
        direction="vertical"
        color="default"
      />
      <div class="flex size-7 items-center justify-center p-0.5">
        <IconButton
          aria-label="Star this field"
          size="md"
          variant="transparent"
          :icon="fieldHasGroundTruth ? 'rating-fill' : 'rating'"
          @click="$emit('star')"
          @mousedown="$emit('star-mousedown')"
        />
      </div>
      <template v-if="hasGroundingClaim && !isCorrectedModelOutput">
        <DividerLine
          direction="vertical"
          color="default"
        />
        <div class="flex size-7 min-w-7 items-center justify-center p-0.5">
          <ToolTip
            title="Open citations for this field"
            :placement="{ allowedPlacements: ['top'] }"
          >
            <IconButton
              aria-label="AI citations"
              icon="idea"
              size="md"
              variant="transparent"
              @click="$emit('open')"
              @mousedown="$emit('open-mousedown')"
            />
          </ToolTip>
        </div>
      </template>
    </div>
  </div>
</template>
