<script setup lang="ts">
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/vue'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { PropertyType } from '@/backend/types'
import { TYPE_ICON } from './icons'

defineProps<{
  value: PropertyType
}>()

defineEmits<{
  (e: 'change', value: PropertyType): void
}>()

const open = ref(false)
const target = ref<HTMLElement>()
const floatingMenu = ref<HTMLElement>()

const { floatingStyles: floatingMenuStyles } = useFloating(target, floatingMenu, {
  placement: 'top-start',
  middleware: [flip(), offset({ crossAxis: -6 })],
  whileElementsMounted: autoUpdate,
})

onClickOutside(floatingMenu, () => {
  open.value = false
})
</script>

<template>
  <div
    v-bind="$attrs"
    ref="target"
    tabindex="0"
    aria-label="Property Type Picker"
    class="flex size-6 cursor-pointer items-center justify-center gap-2 rounded-md p-1.5 text-icon-subtle hover:bg-background-transparent-hovered focus-visible:bg-background-transparent-pressed focus-visible:outline-none"
    :class="open && 'bg-background-transparent-pressed'"
    @click="open = !open"
    @keydown.enter="open = true"
  >
    <IconSprite
      :icon="TYPE_ICON[value]"
      size="sm"
    />
  </div>

  <Teleport
    v-if="open"
    to="body"
  >
    <UseFocusTrap
      id="property-type-menu"
      ref="floatingMenu"
      aria-label="Property Type Menu"
      class="grid h-auto shrink grow-0 select-none grid-flow-row grid-cols-2 rounded-xl bg-surface-popover p-1 shadow-lg outline outline-1 outline-border-subtle"
      :style="floatingMenuStyles"
    >
      <div
        v-for="propertyType in [PropertyType.text, PropertyType.json]"
        :key="propertyType"
        tabindex="0"
        role="button"
        class="flex size-7 cursor-pointer items-center justify-center rounded-lg text-icon-subtle hover:bg-background-gray-subtlest-hovered focus-visible:bg-background-gray-subtlest-pressed focus-visible:outline-none"
        :class="value === propertyType && 'bg-background-gray-subtlest'"
        @click="$emit('change', propertyType), (open = false)"
        @keydown.enter="$emit('change', propertyType), (open = false)"
        @keydown.escape="open = false"
      >
        <IconSprite
          :icon="TYPE_ICON[propertyType]"
          size="sm"
        />
      </div>
    </UseFocusTrap>
  </Teleport>
</template>
