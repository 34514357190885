import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { computed, ref, type Ref } from 'vue'
import { PropertyTool, PropertyType } from '../../backend/types'
import { GROUNDED_TOOLS } from '../WorkspaceSettings/propertyConfig'
import { useProject } from './useProject'
import { useProperty } from './useProperty'
/**
 * When toggling grounding for a property, we must change the models that are
 * selected and available. This composable helps manage the state of grounding,
 * and updates the selected model when grounding is toggled.
 */
export const useGroundingToggle = () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const propertyStore = useProperty()
  const projectStore = useProject()

  /**
   * Holds the tool that was selected before the user enabled grounding, so
   * we can revert back to it when grounding is disabled.
   */
  const previousUngroundedTool = ref<PropertyTool | null>(null)

  /**
   * There is no state we can mutate to enable/disable grounding explicitly.
   * Instead, grounding is 'enabled' when the selected tool is one of the
   * grounded tools. This ensures that we can never be in a state where
   * grounding is enabled but the selected tool is not a grounded tool.
   */
  const isGroundingEnabled = computed(() => GROUNDED_TOOLS.includes(propertyStore.editedTool))

  /**
   * Sets the grounding state for the current property. This will update the
   * property store, updating the selected tool to one that runs with grounding.
   */
  const setGroundingState = (enabled: boolean) => {
    if (enabled === isGroundingEnabled.value) {
      return
    }

    if (enabled) {
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_ENABLED)
      previousUngroundedTool.value = propertyStore.editedTool
      propertyStore.editedTool =
        propertyStore.editedTool === PropertyTool.gpt_4o_azure
          ? PropertyTool.gpt_4o_azure_grounded
          : PropertyTool.gpt_4o_grounded
    } else {
      // In the future we should be more clever and map a tool to its grounded
      // equivalent, but for now we only have one grounded tool.
      const fallback =
        propertyStore.editedTool === PropertyTool.gpt_4o_grounded
          ? PropertyTool.gpt_4o
          : PropertyTool.gpt_4o_azure
      propertyStore.editedTool = previousUngroundedTool.value ?? fallback
    }
  }

  type Condition = {
    condition: Ref<boolean>
    label: string
  }

  const groundingConditions: Condition[] = [
    {
      condition: computed(() => {
        return propertyStore.editedInputs.some((input) => {
          const property = projectStore.properties.find((p) => p.id === input.propertyId)
          return property?.type === PropertyType.file
        })
      }),
      label: 'At least one input is a file property',
    },
    {
      condition: computed(() => {
        return (
          propertyStore.editedTool === 'gpt_4o_grounded' ||
          propertyStore.editedTool === 'gpt_4o' ||
          propertyStore.editedTool === 'gpt_4o_azure_grounded' ||
          propertyStore.editedTool === 'gpt_4o_azure'
        )
      }),
      label: 'Tool is GPT 4 Omni',
    },
    {
      condition: computed(() => {
        return propertyStore.editedType === PropertyType.text
      }),
      label: 'Property type is text',
    },
  ]

  const areConditionsFilled = computed(() => {
    return groundingConditions.every((c) => c.condition.value)
  })

  return {
    isGroundingEnabled,
    setGroundingState,
    groundingConditions,
    areConditionsFilled,
  }
}
