import { setFieldValue } from '@/backend/setFieldValue'
import { PropertyType } from '@/backend/types'
import { useEntity } from '@/modules/Project/useEntity'
import { serializeFields, useProject, type Field } from '@/modules/Project/useProject'

/**
 * For a given field, returns the object that should be
 * sent to the backend when saving the field's manual value.
 */
const getBackendValue = (field: Field): Parameters<typeof setFieldValue>[0]['value'] => {
  if (field.manualValue === null) {
    return null
  }

  switch (field.type) {
    case PropertyType.single_select:
    case PropertyType.multi_select:
    case PropertyType.user_select:
    case PropertyType.collection: {
      return {
        options: typeof field.manualValue === 'string' ? [field.manualValue] : field.manualValue,
      }
    }
    case PropertyType.file:
    case PropertyType.pdf: {
      return { file_url: field.manualValue }
    }
    case PropertyType.json: {
      return { json: field.manualValue }
    }
    case PropertyType.url: {
      return { url: field.manualValue }
    }
    case PropertyType.text: {
      return { text: field.manualValue }
    }
  }
}

type SaveValueParams = {
  workspaceId: string
  projectId: string
  propertyId: string
  newValue: string | string[] | null
  field: Field
}

/**
 * Composable function that returns a function that will save a new field value
 * on the backend. The function will optimistically update the field's value in
 * the store, and revert the change if the backend request fails.
 */
export const useSetFieldValue = () => {
  const entityStore = useEntity()
  const projectStore = useProject()

  const saveValue = async ({
    field,
    projectId,
    propertyId,
    newValue,
    workspaceId,
  }: SaveValueParams) => {
    const oldField = { ...field }
    const newField = { ...field }
    newField.manualValue = newValue
    if (newField.type === PropertyType.file && newValue === null) {
      newField.manualFilename = null
    }
    entityStore.updateField(newField)
    projectStore.updateField(newField)
    const result = await setFieldValue({
      workspaceId: workspaceId,
      projectId: projectId,
      entityId: field.entityId,
      propertyId: propertyId,
      value: getBackendValue(newField),
    })

    if (result.ok) {
      /**
       * Although we sent an optimistic update, the optimistic field might not contain
       * all the information that the backend returned. Now that we have all the updated
       * information, we update the field in the store again.
       */
      Array.from(serializeFields(result.data.fields).values()).forEach((f) => {
        projectStore.updateField(f)
        entityStore.updateField(f)
      })
    } else {
      entityStore.updateField(oldField)
      projectStore.updateField(oldField)
    }

    return result.ok
  }

  return saveValue
}
