<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import { useTheme } from '@/modules/App/useTheme'

import { createHighlighter } from 'shiki'

const props = defineProps<{
  value: string
  variant?: 'default' | 'error' | 'warning' | 'info'
  error?: string
  size: 'md' | 'lg'
  placeholder?: string
  rounded?: boolean
  ariaLabel?: string
}>()

const emit = defineEmits<{
  (e: 'change' | 'input', payload: string): void
  (e: 'customCaretPosition', position: { top: number; left: number; height: number }): void
}>()

const highlighter = ref<Awaited<ReturnType<typeof createHighlighter>>>()
const textAreaRef = ref<HTMLTextAreaElement | null>(null)
const currentSingleCaretSelection = ref<number>(0)
const highlightContainerRef = ref<HTMLSpanElement>()
const output = ref<string>('<pre></pre>')
const { actualTheme } = useTheme()

watch(() => props.value, run, { immediate: true })
watch(() => actualTheme.value, run)

onMounted(async () => {
  highlighter.value = await createHighlighter({
    themes: ['nord', 'min-light'],
    langs: ['python'],
  })
  run()
})

function run() {
  if (!highlighter.value) return
  output.value = highlighter.value.codeToHtml(props.value, {
    // why do we need those if configured top?
    lang: 'python',
    theme: actualTheme.value === 'light' ? 'min-light' : 'nord',
    transformers: [
      {
        preprocess(code) {
          // When last span is empty, it's height is 0px
          // so add a newline to render it correctly
          // otherwise you get a scrollbar desync
          if (code.endsWith('\n')) return `${code}\n`
        },
      },
    ],
  })

  // sync background color based on theme
  nextTick(() => {
    const preEl = highlightContainerRef.value as HTMLElement
    if (!preEl) return
    const backgroundColor = preEl.querySelector('pre')?.style.backgroundColor
    if (!backgroundColor) return
    preEl.style.backgroundColor = backgroundColor
  })
}

function syncScroll() {
  if (!highlightContainerRef.value || !textAreaRef.value) return
  const preEl = highlightContainerRef.value as HTMLElement
  if (!preEl) return
  preEl.scrollTop = textAreaRef.value.scrollTop
  preEl.scrollLeft = textAreaRef.value.scrollLeft
}

const indentOnTab = (e: KeyboardEvent) => {
  const el = textAreaRef.value
  if (!el) return
  if (e.key == 'Escape') {
    // reset the selection to start of the selection
    // and prevent from bubbling
    el.selectionEnd = el.selectionStart
    e.stopPropagation()
  }

  // Tab key and shift-tab key indenting support
  if (e.key === 'Tab') {
    e.preventDefault()
    const text = el.value
    // selection?
    if (el.selectionStart == el.selectionEnd) {
      // These single character operations are undoable
      if (!e.shiftKey) {
        document.execCommand('insertText', false, '\t')
      } else {
        if (el.selectionStart > 0 && text[el.selectionStart - 1] == '\t') {
          document.execCommand('delete')
        }
      }
    } else {
      // Block indent/unindent trashes undo stack.
      // Select whole lines
      let selStart = el.selectionStart
      let selEnd = el.selectionEnd
      while (selStart > 0 && text[selStart - 1] != '\n') selStart--
      while (selEnd > 0 && text[selEnd - 1] != '\n' && selEnd < text.length) selEnd++

      // Get selected text
      var lines = text.substr(selStart, selEnd - selStart).split('\n')

      // Insert tabs
      for (var i = 0; i < lines.length; i++) {
        // Don't indent last line if cursor at start of line
        if (i == lines.length - 1 && lines[i].length == 0) continue

        // Tab or Shift+Tab?
        if (e.shiftKey) {
          if (lines[i].startsWith('\t')) lines[i] = lines[i].substr(1)
          else if (lines[i].startsWith('    ')) lines[i] = lines[i].substr(4)
        } else lines[i] = '\t' + lines[i]
      }
      const linesresult = lines.join('\n')

      // Update the text area
      el.value = text.substr(0, selStart) + linesresult + text.substr(selEnd)
      el.selectionStart = selStart
      el.selectionEnd = selStart + linesresult.length
    }

    if (e.target) {
      emit('input', (e.target as HTMLTextAreaElement).value)
    }
  }
}

async function onInput() {
  setTimeout(() => {
    syncScroll()
  })
}

const getLineAndColumnInsidePreElement = (caretPosition: number) => {
  const preEl = highlightContainerRef.value as HTMLElement
  const text = preEl.innerText
  if (!preEl || text === undefined) return { line: 0, column: 0 }

  const lines = text.split('\n')
  let line = 0
  let column = 0
  for (let i = 0; i < lines.length; i++) {
    if (caretPosition <= lines[i].length) {
      line = i
      column = caretPosition
      break
    }
    caretPosition -= lines[i].length + 1
  }

  return { line, column }
}

const updateCaretPosition = async () => {
  // for some reason it's a bit flaky with one nextTick()
  await nextTick()
  await nextTick()
  currentSingleCaretSelection.value = textAreaRef.value?.selectionStart || 0

  const lineAndColumnInsidePreElement = getLineAndColumnInsidePreElement(
    currentSingleCaretSelection.value,
  )
  const emitPayload = {
    top: lineAndColumnInsidePreElement.line * 18 - (highlightContainerRef.value?.scrollTop || 0),
    left: lineAndColumnInsidePreElement.column * 7 - (highlightContainerRef.value?.scrollLeft || 0),
    height: 18,
  }
  emit('customCaretPosition', emitPayload)
}
</script>

<template>
  <div class="relative">
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="highlightContainerRef"
      class="pointer-events-none absolute inset-0 overflow-auto whitespace-pre-line rounded-lg"
      :class="[size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2']"
      v-html="output"
    />
    <!-- eslint-enable vue/no-v-html -->

    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <textarea
      ref="textAreaRef"
      type="text"
      class="invisible-textarea relative block h-[150px] min-h-[50px] w-full bg-background-gray-subtlest px-2 text-md-13px-default caret-text transition-colors placeholder:text-text-subtlest focus:outline-none disabled:cursor-not-allowed hover:[&:not(:disabled):not(:focus)]:bg-background-gray-subtlest-hovered"
      :class="[
        [variant === 'error' && ['border-border-critical'], variant !== error && []],
        [size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2'],
        [rounded ? 'rounded-corner-10' : 'rounded-corner-8'],
      ]"
      :placeholder="placeholder"
      :value="value"
      :ariaLabel="ariaLabel"
      v-bind="$attrs"
      @change="$event.target && $emit('change', ($event.target as HTMLTextAreaElement).value)"
      @input="
        (event) => {
          updateCaretPosition()
          event.target && $emit('input', (event.target as HTMLTextAreaElement).value)
          onInput()
        }
      "
      @scroll="syncScroll"
      @keydown="indentOnTab"
    />

    <!-- eslint-enable tailwindcss/no-custom-classname -->
  </div>
</template>

<style type="text/css" scoped>
.invisible-textarea {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  line-height: 18px;
  color: transparent;
  font-size: 12px;
  scrollbar-color: transparent transparent;
  white-space: pre;
  overscroll-behavior: none;
  max-height: calc(100vh - 400px);
}
</style>
