import { addEntity } from '@/backend/addEntity'
import { deleteEntity } from '@/backend/deleteEntity'
import { setFieldValue } from '@/backend/setFieldValue'
import { updateLibraryItem } from '@/backend/updateLibraryItem'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { captureException } from '@sentry/vue'
import { useProject } from '../Project/useProject'
import { useLibraryStore, type LibraryDocument } from './libraryStore'

/**
 * This composable provides functions that send HTTP requests
 * to the backend to update library data, updating the Library
 * store as well (with optimistic UI).
 *
 * These functions are intentionally separate to the store since
 * we also receive library updates via a websocket connection,
 * and we want to make sure we don't "double count" updates by
 * sending API requests when we receive a websocket update.
 */
export const useLibraryBackend = () => {
  const libraryStore = useLibraryStore()
  const projectStore = useProject()

  /**
   * Library items are just entities in the library project. When uploading a file,
   * we are actually setting a file field value. When creating a text item, we are
   * setting a text field value. This function returns the ID of an entity that
   * can be used to 'create' a new library item.
   */
  const getLibraryItemToCreateId = async ({
    libraryId,
    workspaceId,
  }: {
    libraryId: string
    workspaceId: string
  }): Promise<string> => {
    if (libraryStore.emptyItemIds.length === 0) {
      const createEntityRes = await addEntity(workspaceId, libraryId)
      if (!createEntityRes.ok) {
        throw new Error('Failed to create library item')
      }
      return createEntityRes.data.id
    }

    const id = libraryStore.emptyItemIds[0]
    libraryStore.emptyItemIds = libraryStore.emptyItemIds.slice(1)
    return id
  }

  const deleteItem = async ({ itemId, workspaceId }: { itemId: string; workspaceId: string }) => {
    assertIsNotNullOrUndefined(libraryStore.libraryId)

    const revert = libraryStore.deleteItem(itemId)
    const res = await deleteEntity({
      entityId: itemId,
      projectId: libraryStore.libraryId,
      workspaceId,
    })

    if (res.ok) {
      // Now that the item has been deleted we need to remove it from any inputs that were
      // using it
      projectStore.properties = projectStore.properties.map((property) => {
        const newInputs = property.inputs.filter((input) => input.entityId !== itemId)
        return {
          ...property,
          inputs: newInputs,
        }
      })
    } else {
      revert()
      captureException(new Error('Failed to delete library item'), {
        data: { workspaceId, itemId, ...res.error },
      })
    }

    return res
  }

  const renameItem = async ({
    id,
    newName,
    workspaceId,
  }: {
    id: string
    newName: string
    workspaceId: string
  }) => {
    const item = libraryStore.getItem(id)
    const revert = libraryStore.setItem({
      ...item,
      name: newName,
    })
    const res = await updateLibraryItem({
      libraryItemId: id,
      name: newName,
      workspaceId,
    })

    if (!res.ok) {
      revert()
      captureException(new Error('Failed to rename library item'), {
        data: { workspaceId, id, ...res.error },
      })
    }
  }

  const saveTextValue = async ({
    content,
    id,
    workspaceId,
    name,
  }: Pick<LibraryDocument, 'id' | 'content' | 'name'> & { workspaceId: string }) => {
    assertIsNotNullOrUndefined(libraryStore.library, 'The library has not been loaded')

    const item = libraryStore.getItem(id)
    if (item.type !== 'text') {
      throw new Error('Library is not a document')
    }

    // Keep a copy of the old values in case we need to revert
    const oldName = item.name
    const oldContent = item.content

    libraryStore.setItem({
      ...item,
      content,
      name,
    })

    // The content and name are updated in 2 separate requests
    const [contentResponse, nameResponse] = await Promise.all([
      setFieldValue({
        entityId: item.id,
        projectId: libraryStore.library.id,
        propertyId: libraryStore.library.textProperty.id,
        value: { text: content },
        workspaceId,
      }),
      updateLibraryItem({
        workspaceId,
        libraryItemId: item.id,
        name,
      }),
    ])

    if (!contentResponse.ok) {
      libraryStore.setItem({
        ...item,
        content: oldContent,
      })
      captureException(new Error('Failed to save text value'), {
        data: { workspaceId, id, ...contentResponse.error },
      })
    }

    if (!nameResponse.ok) {
      libraryStore.setItem({
        ...item,
        name: oldName,
      })
      captureException(new Error('Failed to save library item name'), {
        data: { workspaceId, id, ...nameResponse.error },
      })
    }

    return [contentResponse, nameResponse]
  }

  return {
    deleteItem,
    renameItem,
    saveTextValue,
    getLibraryItemToCreateId,
  }
}
