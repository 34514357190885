<script lang="ts" setup generic="T extends unknown = { id: string }">
import { computed, nextTick, ref, type HTMLAttributes } from 'vue'

import InlineTextField from './InlineTextField.vue'
import ListMenuContainer from './ListMenuContainer.vue'
import DividerLine from './DividerLine.vue'

type Item = { id: string; data: T }

const props = withDefaults(
  defineProps<{
    items: Item[]
    /** used to decide whether "create" option should be shown, when createEnabled */
    allItems?: { id: string; data: T }[]
    searchByField?: keyof T
    noResultsText?: string
    createEnabled?: boolean
    createEnabledWhenEmpty?: boolean
    /** use () => true to select the first item by default */
    initialActiveItemPredicate?: (item: Item) => boolean
    /** Function to get an item's group, if grouping items */
    groupByPredicate?: (item: Item) => string
    groupOrder?: string[]
    /** When true, will render each group's key as a title above the group */
    hasGroupTitles?: boolean
    /**
     * Controls whether each group has its own scrollbar, or if the entire list
     * has a single scrollbar. Defaults to 'list'.
     */
    scrollLevel?: 'group' | 'list'
    ariaLabel?: string
    autofocus?: boolean
    /** The placeholder that appears in the search box when search is enabled */
    searchPlaceholder?: string
    listBoxAttrs?: HTMLAttributes
  }>(),
  {
    allItems: () => [],
    searchByField: undefined,
    noResultsText: 'Sorry, no matching options',
    createEnabled: false,
    initialActiveItemPredicate: undefined,
    groupByPredicate: undefined,
    groupOrder: undefined,
    ariaLabel: undefined,
    autofocus: true,
    searchPlaceholder: 'Search',
    scrollLevel: 'list',
    listBoxAttrs: undefined,
  },
)

const emit = defineEmits<{
  (e: 'delete' | 'close'): void
  (e: 'create' | 'input', value: string): void
  (e: 'select', value: T): void
}>()

const listRef = ref<null | HTMLElement>(null)
const searchText = ref('')
const searchInput = ref<typeof InlineTextField | null>(null)
const initialActiveItemIndex = props.items.findIndex(
  (x) => props.initialActiveItemPredicate && props.initialActiveItemPredicate(x),
)
const activeItem = ref<null | number>(initialActiveItemIndex !== -1 ? initialActiveItemIndex : null)

const visibleItems = computed(() =>
  props.items.filter((item) => {
    const itemValue = props.searchByField !== undefined && item.data[props.searchByField]
    if (itemValue && typeof itemValue === 'string') {
      return itemValue.toLowerCase().includes(searchText.value.toLowerCase())
    } else {
      return true
    }
  }),
)

/**
 * Groups the visible items by their groupByPredicate. If not grouping
 * then all items are placed in a single group.
 */
const groupedItems = computed(() => {
  const groupByPredicate = props.groupByPredicate
  if (!groupByPredicate) {
    return [{ key: '', items: visibleItems.value }]
  }

  const grouped = visibleItems.value.reduce(
    (acc, item) => {
      const key = groupByPredicate(item)
      const groupIndex = acc.findIndex((x) => x.key === key)
      if (groupIndex === -1) {
        acc.push({ key, items: [item] })
      } else {
        acc[groupIndex].items.push(item)
      }
      return acc
    },
    [] as { key: string; items: Item[] }[],
  )

  // Define the order of the groups
  if (props.groupOrder) {
    // Sort the grouped items based on the predefined order
    grouped.sort((a, b) => {
      // This assertion can be disabled because we are evaluating the groupOrder prop two lines above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const indexA = props.groupOrder!.indexOf(a.key)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const indexB = props.groupOrder!.indexOf(b.key)
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
    })
  }

  return grouped
})

const focusActiveElement = async () => {
  if (props.searchByField) return
  await nextTick()
  ;(listRef.value?.querySelector('[aria-selected="true"]') as HTMLElement)?.focus()
}

const emitCreateOrSelect = () => {
  if (createEnabled.value && activeItem.value === visibleItems.value.length) {
    emit('create', searchText.value)
  } else if (visibleItems.value.length > 0 && activeItem.value !== null) {
    emit('select', visibleItems.value[activeItem.value].data)
    activeItem.value = Math.max(0, Math.min(activeItem.value, visibleItems.value.length - 2))
  }
  resetText()
  searchInput.value?.focus()
}

const createEnabled = computed(
  () =>
    props.createEnabled &&
    (props.createEnabledWhenEmpty ||
      (searchText.value.length > 0 &&
        // if there is exact match, don't show create option and force the user to select it
        !props.allItems.find(
          (i) =>
            props.searchByField &&
            String(i.data[props.searchByField]).toLowerCase() === searchText.value.toLowerCase(),
        ))),
)

const resetText = () => {
  searchText.value = ''
}

const setActiveItem = (idx: number) => {
  if (activeItem.value === idx) return
  activeItem.value = idx
  focusActiveElement()
}

const decActiveItem = () => {
  activeItem.value =
    ((activeItem.value ?? 0) - 1 + (visibleItems.value.length + (createEnabled.value ? 1 : 0))) %
    (visibleItems.value.length + (createEnabled.value ? 1 : 0))
  focusActiveElement()
}

const incActiveItem = () => {
  activeItem.value =
    ((activeItem.value ?? -1) + 1) % (visibleItems.value.length + (createEnabled.value ? 1 : 0))
  focusActiveElement()
}

const onInput = (event: string) => {
  searchText.value = event
  activeItem.value = 0
  emit('input', event)
}

const getItemIndex = (groupIndex: number, itemIndex: number): number => {
  return (
    groupedItems.value.slice(0, groupIndex).reduce((acc, group) => acc + group.items.length, 0) +
    itemIndex
  )
}
</script>

<template>
  <ListMenuContainer
    @keydown.up.stop="decActiveItem"
    @keydown.down.stop="incActiveItem"
    @keydown.tab.exact.prevent.stop="incActiveItem"
    @keydown.shift.tab.exact.prevent.stop="decActiveItem"
  >
    <div
      v-if="searchByField"
      class="w-full p-0.5"
    >
      <InlineTextField
        ref="searchInput"
        :autofocus="autofocus"
        :placeholder="searchPlaceholder"
        aria-label="Search"
        size="sm"
        class="w-full"
        :value="searchText"
        @input="onInput"
        @backspace="searchText.length === 0 && $emit('delete')"
        @submit="emitCreateOrSelect"
        @keydown.left.stop
        @keydown.right.stop
      />
    </div>
    <DividerLine
      v-if="searchByField"
      class="w-full"
      color="subtle"
      :width="1"
    />
    <div
      v-if="visibleItems.length > 0"
      ref="listRef"
      class="w-full"
      :class="
        scrollLevel === 'list' &&
        'overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md'
      "
      role="listbox"
      :aria-label="ariaLabel"
      v-bind="listBoxAttrs"
    >
      <div
        v-for="(group, groupIndex) in groupedItems"
        :key="group.key"
        class="contents"
      >
        <DividerLine
          v-if="groupIndex > 0"
          color="subtle"
        />
        <slot
          name="group-title"
          :group="group"
        >
          <div
            v-if="hasGroupTitles"
            class="flex h-7 items-center px-1.5 py-0.5"
          >
            <h4 class="text-xs-11px-bold text-text-subtlest">
              {{ group.key }}
            </h4>
          </div>
        </slot>
        <div
          class="p-0.5"
          :class="
            scrollLevel === 'group' &&
            'max-h-60 overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md'
          "
        >
          <slot
            v-for="(item, itemIndex) in group.items"
            :key="getItemIndex(groupIndex, itemIndex)"
            name="item"
            :item="item"
            :active="activeItem === getItemIndex(groupIndex, itemIndex)"
            :set-active-item="setActiveItem"
            :focus="() => searchInput?.focus()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full p-2 text-text-subtlest"
    >
      {{ noResultsText }}
    </div>
    <template v-if="createEnabled">
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />

      <slot
        name="create"
        :active="activeItem === visibleItems.length"
        :search-text="searchText"
        :reset-text="resetText"
        :set-active-item="() => (activeItem = visibleItems.length)"
        :focus="() => searchInput?.focus()"
      />
    </template>
  </ListMenuContainer>
</template>
