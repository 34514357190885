<script setup lang="ts">
import getCaretPosition from 'textarea-caret'
import { computed, onMounted, onUnmounted, onUpdated, ref, watch, nextTick } from 'vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import type { IconName } from '@/uiKit/IconName'
/**
 * Copied and modified from https://github.com/Akryum/vue-mention/blob/next/packages/vue-mention/src/Mentionable.vue
 * (the license permits it)
 *
 * This component wraps a text input, area or contenteditable element and provides a dropdown that is used to insert 'mentions',
 * e.g. "@Will can you look at this please?".
 *
 * This file can be mostly ignored. As part of this spike we wanted to use the vue-mention package directly but there were blocking issues:
 * - We couldn't style the dropdown
 * - The dropdown used the floating-vue library and not floating-ui

 * The steps taken to create this component were:
 * - Copy and paste from the vue-mention repo
 * - Convert from defineComponent to script setup
 * - Fix TypeScript errors
 * - Replace floating-vue dropdown with our own PopupMenu component

 * I fully accept that this component is a bit crap - too many custom event listeners, not enough Vue.
 * It's all very poorly documented, but I don't think I fully understand it enough yet to document it well.
 *
 * The aim here is to just get a functioning mentionable component that we can update and improve later.
 * It's all behind a feature flag so shouldn't impact on staging/prod.
 */

export interface MentionItem {
  label?: string
  id: string
  icon?: IconName
  group?: string
}

const props = withDefaults(
  defineProps<{
    keys: string[]
    items: MentionItem[]
    omitKey?: boolean
    filteringDisabled?: boolean
    insertSpace?: boolean
    mapInsert?: ((item: MentionItem, key: string) => string) | null
    theme?: string
    caretHeight?: number
    /** When true, mentions will only be allowed when there is a space before the @ */
    requirePreceedingSpace?: boolean
    customCaretPosition?: { top: number; left: number; height: number } | null
  }>(),
  {
    mapInsert: null,
    theme: 'mentionable',
    caretHeight: 0,
    customCaretPosition: null,
  },
)

const emit = defineEmits<{
  (e: 'search', searchText: string, oldValue: string | null): void
  (e: 'open' | 'close', key: string): void
  (e: 'apply', item: MentionItem, key: string, value: string): void
}>()

const currentKey = ref<string | null>(null)
let currentKeyIndex: number
const oldKey = ref<string | null>(null)

const searchText = ref<string>('')
watch(searchText, (value, oldValue) => {
  if (value) {
    emit('search', value, oldValue)
  }
})

/**
 * The items that match the current search text.
 */
const filteredItems = computed(() => {
  if (!searchText.value || props.filteringDisabled) {
    return props.items
  }

  if (searchText.value === ' ') {
    return []
  }

  const finalSearchText = searchText.value.toLowerCase()

  return props.items.filter((item) => {
    let text: string
    if (item.label) {
      text = item.label
    } else {
      text = ''
      for (const key in item) {
        text += item[key as keyof MentionItem]
      }
    }
    return text.toLowerCase().includes(finalSearchText)
  })
})

const selectedIndex = ref(0)

watch(
  filteredItems,
  () => {
    selectedIndex.value = 0
  },
  {
    deep: true,
  },
)

let input = ref<HTMLElement | null | undefined>(null)
const el = ref<HTMLDivElement | null>(null)

function getInput() {
  return (
    el.value?.querySelector('input') ??
    el.value?.querySelector('textarea') ??
    el.value?.querySelector('[contenteditable="true"]')
  )
}

onMounted(() => {
  input.value = getInput()
  attach()
})

onUpdated(() => {
  const newInput = getInput()
  if (newInput !== input.value) {
    detach()
    input.value = newInput
    attach()
  }
})

onUnmounted(() => {
  detach()
})

function attach() {
  if (input.value) {
    input.value.addEventListener('input', onInput)
    input.value.addEventListener('keydown', onKeyDown)
    input.value.addEventListener('keyup', onKeyUp)
    input.value.addEventListener('scroll', onScroll)
    input.value.addEventListener('blur', onBlur)
  }
}

function detach() {
  if (input.value) {
    input.value.removeEventListener('input', onInput)
    input.value.removeEventListener('keydown', onKeyDown)
    input.value.removeEventListener('keyup', onKeyUp)
    input.value.removeEventListener('scroll', onScroll)
    input.value.removeEventListener('blur', onBlur)
  }
}

function onInput() {
  checkKey()
}

function onBlur() {
  closeMenu()
}

function onKeyDown(e: KeyboardEvent) {
  if (currentKey.value) {
    if (e.key === 'ArrowDown') {
      selectedIndex.value++
      if (selectedIndex.value >= filteredItems.value.length) {
        selectedIndex.value = 0
      }
      cancelEvent(e)
    }
    if (e.key === 'ArrowUp') {
      selectedIndex.value--
      if (selectedIndex.value < 0) {
        selectedIndex.value = filteredItems.value.length - 1
      }
      cancelEvent(e)
    }
    if ((e.key === 'Enter' || e.key === 'Tab') && filteredItems.value.length > 0) {
      applyMention(selectedIndex.value)
      cancelEvent(e)
    }
    if (e.key === 'Escape') {
      closeMenu()
      cancelEvent(e)
    }
  }
}

let cancelKeyUp: string | null = null

function onKeyUp(e: KeyboardEvent) {
  if (cancelKeyUp && e.key === cancelKeyUp) {
    cancelEvent(e)
  }
  cancelKeyUp = null
}

/**
 * Stop the current event from propagating and prevent the default action.
 */
function cancelEvent(e: KeyboardEvent | MouseEvent) {
  e.preventDefault()
  e.stopPropagation()

  if (e instanceof KeyboardEvent) {
    cancelKeyUp = e.key
  }
}

function onScroll() {
  updateCaretPosition()
}

function getSelectionStart() {
  return input.value?.isContentEditable
    ? window.getSelection()?.anchorOffset
    : (input.value as HTMLInputElement).selectionStart
}

function setCaretPosition(index: number) {
  nextTick(() => {
    ;(input.value as HTMLInputElement).selectionEnd = index
  })
}

function getValue() {
  return input.value?.isContentEditable
    ? (window.getSelection()?.anchorNode?.textContent ?? '')
    : (input.value as HTMLInputElement).value
}

function setValue(value: string) {
  ;(input.value as HTMLInputElement).value = value
  emitInputEvent('input')
}

function emitInputEvent(type: string) {
  input.value?.dispatchEvent(new Event(type))
}

let lastSearchText: string | null = null

function checkKey() {
  const index = getSelectionStart()
  if (index && index >= 0) {
    const { key, keyIndex } = getLastKeyBeforeCaret(index)
    const text = (lastSearchText = getLastSearchText(index, keyIndex))

    if (!props.requirePreceedingSpace) {
      if (!(keyIndex < 1 || /\s/.test(getValue()[keyIndex - 1]))) {
        return false
      }
    }
    if (text !== null) {
      openMenu(key, keyIndex)
      searchText.value = text
      return true
    }
  }
  closeMenu()
  return false
}

function getLastKeyBeforeCaret(caretIndex: number) {
  const [keyData] = props.keys
    .map((key) => ({
      key,
      keyIndex: getValue().lastIndexOf(key, caretIndex - 1),
    }))
    .sort((a, b) => b.keyIndex - a.keyIndex)
  return keyData
}

/**
 * Returns the text between the last '@' character and the caret, or null if
 * the text doesn't match any mentionables.
 */
function getLastSearchText(caretIndex: number, keyIndex: number): string | null {
  if (keyIndex === -1) {
    return null
  }

  const text = getValue().substring(keyIndex + 1, caretIndex)

  if (text && filteredItems.value.length === 0) {
    return null
  }

  return text
}

watch(
  () => filteredItems.value.length,
  (numberOfItems) => {
    if (numberOfItems === 0) {
      closeMenu()
    }
  },
)

// Position used to anchor the dropdown menu
const caretPosition = ref<{ top: number; left: number; height: number } | null>(null)

function updateCaretPosition() {
  const selection = window.getSelection()
  if (!(input.value instanceof HTMLElement) || !selection) {
    return
  }

  if (currentKey.value) {
    if (props.customCaretPosition) {
      caretPosition.value = props.customCaretPosition
      return
    }
    if (input.value?.isContentEditable) {
      const rect = selection.getRangeAt(0).getBoundingClientRect()
      const inputRect = input.value.getBoundingClientRect()
      caretPosition.value = {
        left: rect.left - inputRect.left,
        top: rect.top - inputRect.top,
        height: rect.height,
      }
    } else {
      caretPosition.value = getCaretPosition(input.value, currentKeyIndex)
    }
    caretPosition.value.top -= input.value.scrollTop
    if (props.caretHeight) {
      caretPosition.value.height = props.caretHeight
    } else if (isNaN(caretPosition.value.height)) {
      caretPosition.value.height = 16
    }
  }
}

// Open/close

function openMenu(key: string, keyIndex: number) {
  if (currentKey.value !== key) {
    currentKey.value = key
    currentKeyIndex = keyIndex
    updateCaretPosition()
    selectedIndex.value = 0
    emit('open', currentKey.value)
  }
}

function closeMenu() {
  if (currentKey.value != null) {
    oldKey.value = currentKey.value
    currentKey.value = null
    emit('close', oldKey.value)
  }
}

/**
 * Triggered when selecting a mention from the dropdown. Adds the full
 * mention to the text and emits an 'apply' event.
 */
function applyMention(itemIndex: number) {
  const selection = window.getSelection()
  if (!selection || !currentKey.value) {
    return
  }
  const item = filteredItems.value[itemIndex]
  const value =
    (props.omitKey ? '' : currentKey.value) +
    String(props.mapInsert ? props.mapInsert(item, currentKey.value ?? '') : item.id) +
    (props.insertSpace ? ' ' : '')
  if (input.value?.isContentEditable) {
    const range = selection.getRangeAt(0)
    range.setStart(
      range.startContainer,
      range.startOffset - currentKey.value.length - (lastSearchText ? lastSearchText.length : 0),
    )
    range.deleteContents()
    range.insertNode(document.createTextNode(value))
    range.setStart(range.endContainer, range.endOffset)
    emitInputEvent('input')
  } else {
    setValue(replaceText(getValue(), searchText.value, value, currentKeyIndex))
    setCaretPosition(currentKeyIndex + value.length)
  }
  emit('apply', item, currentKey.value, value)
  closeMenu()
}

/**
 * Replace the text between the last '@' character and the caret with the
 * @param text - Original text
 * @param searchString - substring to replace
 * @param newText - substring to replace with
 * @param index - index of the first character of the substring to replace
 */
function replaceText(text: string, searchString: string, newText: string, index: number) {
  return text.slice(0, index) + newText + text.slice(index + searchString.length + 1, text.length)
}

const isGrouped = computed(() => props.items.some((i) => i.group))
</script>

<template>
  <div
    ref="el"
    :class="$attrs.class"
    style="position: relative"
    class="z-1"
  >
    <slot />
    <PopupMenu
      :open="!!currentKey && filteredItems.length > 0"
      class="absolute"
      :auto-placement="{ allowedPlacements: ['bottom-start', 'top-start'] }"
      :offset="{ mainAxis: 20 }"
      :style="
        caretPosition
          ? {
              top: `${caretPosition.top}px`,
              left: `${caretPosition.left}px`,
            }
          : {}
      "
      disable-focus-trap
      enable-nested-teleport
    >
      <template #dropdown>
        <ListMenu
          :items="filteredItems.map((i) => ({ data: i, id: i.id }))"
          :group-by-predicate="(item) => item.data.group ?? ''"
          :scroll-level="isGrouped ? 'group' : 'list'"
          :has-group-titles="isGrouped"
        >
          <template #group-title="{ group }">
            <slot
              name="group-title"
              :group="group"
            />
          </template>
          <template #item="{ item, key }">
            <slot
              :key="key"
              name="mentionable-item"
              :item="item.data"
              :apply-mention="applyMention"
              :active="selectedIndex === key"
              :set-active="() => (selectedIndex = key)"
            />
          </template>
        </ListMenu>
      </template>
    </PopupMenu>
  </div>
</template>
