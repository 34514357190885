import type { Plan } from '@/modules/Billing/types'
import { useBilling } from '@/modules/Billing/useBilling'
import { useUser, type User } from '@/modules/IdentityAndAccess/useUser'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { watch } from 'vue'
import { useHasLoadedIntercom } from './useHasLoadedIntercom'

export const INTERCOM_LAUNCHER_ID = 'intercom-launcher'

const INTERCOM_APP_ID: string | undefined = import.meta.env.VITE_INTERCOM_APP_ID

/**
 * Adds a script tag to the document to load the Intercom script. Returns a promise
 * that resolves when the script has loaded.
 */
const loadAsyncScript = (appId: string) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://widget.intercom.io/widget/${appId}`
    script.onload = resolve
    script.onerror = reject

    const firstScript = document.getElementsByTagName('script')[0]
    const parentNode = firstScript.parentNode
    if (!parentNode) {
      throw new Error('Something went wrong when loading the Intercom script')
    }
    parentNode.insertBefore(script, firstScript)
  })

/**
 * Watches the active plan and initializes Intercom when the user is on a paid plan.
 */
export const useLoadIntercom = () => {
  const workspacesStore = useWorkspaces()
  const hasLoadedIntercom = useHasLoadedIntercom()
  /**
   * Attach the Intercom script to the document and boot Intercom so that
   * the chat widget is available to the user.
   */
  const init = async (plan: Plan, user: User) => {
    if (!INTERCOM_APP_ID) {
      return
    }

    await loadAsyncScript(INTERCOM_APP_ID)
    if (!window.Intercom) {
      throw new Error('Intercom script failed to load')
    }

    const name = [user.firstName, user.lastName].filter(Boolean).join(' ')

    /**
     * Intercom requires the `created_at` field to be a Unix timestamp in seconds.
     * @see https://developers.intercom.com/docs/references/rest-api/api.intercom.io/Companies/company/
     */
    const createdAt = workspacesStore.currentWorkspace?.createdAt
      ? new Date(workspacesStore.currentWorkspace.createdAt).getTime() / 1000
      : undefined

    window.Intercom('boot', {
      hide_default_launcher: true, // we have a custom button to launch intercom
      custom_launcher_selector: `#${INTERCOM_LAUNCHER_ID}`,
      app_id: INTERCOM_APP_ID,
      email: user.email,
      name,
      product: 'Go',
      freemium: plan.name === 'free',
      company: {
        // We have no way of finding out what the company name is, but Intercom
        // requires a value here. The workspace name is the next best thing.
        name: workspacesStore.currentWorkspace?.name ?? plan.id,
        company_id: plan.id,
        plan: plan.name,
        created_at: createdAt,
      },
    })
    hasLoadedIntercom.value = true
  }

  const billingStore = useBilling()
  const userStore = useUser()
  /**
   * Wait until both the active plan and the user have been loaded into the stores
   * before initializing Intercom.
   */
  watch(
    () => [billingStore.activePlan, userStore.user] as const,
    ([plan, user]) => {
      if (!plan || !user) {
        return
      }

      if (!window.Intercom) {
        init(plan, user)
      }
    },
    {
      immediate: true,
    },
  )
}
