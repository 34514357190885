<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import type { ProjectMemberRole } from './types'
import { computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import IconSprite from '@/uiKit/IconSprite.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import type { ResourceRole } from '@/backend/types'

const props = defineProps<{
  workspaceMembers: Array<{
    member: WorkspaceMember
    projectRole?: ResourceRole | 'owner'
  }>
}>()

const emit = defineEmits<{
  (
    e: 'invite',
    args: { existingUsers: WorkspaceMember[]; role: ProjectMemberRole; newUserEmails: string[] },
  ): void
}>()

const roleTitleMap: Record<ProjectMemberRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
}
const getRoleTitle = (role?: ResourceRole | 'owner'): string | undefined => {
  if (role !== 'editor' && role !== 'reader') {
    return
  }

  return roleTitleMap[role].toLowerCase()
}

const LIST_ITEMS: Array<{ title: string; description: string; role: ProjectMemberRole }> = [
  {
    role: 'editor',
    title: roleTitleMap.editor,
    description: 'Can access the project and modify properties or data.',
  },
  {
    role: 'reader',
    title: roleTitleMap.reader,
    description: 'Can access the project but cannot modify properties or data.',
  },
]

const selectedRole = ref<ProjectMemberRole>('editor')
const dropdownTriggerText = computed(() => roleTitleMap[selectedRole.value].toLowerCase())

const textAndDropdowownTrigger = ref<HTMLElement | null>(null)
const { width: dropdownWidth } = useElementSize(textAndDropdowownTrigger)

const selectedWorkspaceMembers = ref<WorkspaceMember[]>([])
const onUnselectMember = (member: WorkspaceMember) => {
  selectedWorkspaceMembers.value = selectedWorkspaceMembers.value.filter((m) => m.id !== member.id)
}

const textInputIsFocused = ref(false)
const textValue = ref('')
const filteredWorkspaceMembers = computed(() =>
  props.workspaceMembers.filter(
    ({ member }) =>
      (member.email?.toLowerCase().includes(textValue.value) ||
        member.firstName?.toLowerCase().includes(textValue.value) ||
        member.lastName?.toLowerCase().includes(textValue.value)) &&
      !selectedWorkspaceMembers.value.some((m) => m.id === member.id),
  ),
)

const getBadgeLabel = (member: WorkspaceMember) => {
  if (member.firstName) {
    let name = member.firstName
    if (member.lastName) {
      name += ` ${member.lastName.slice(0, 1)}`
    }
    return name
  }

  if (member.lastName) {
    return member.lastName
  }

  // We should never really end up returning the ID, but it's the only
  // string we can guarantee to have
  return member.email ?? member.id
}

const onSubmitForm = (e: Event) => {
  e.preventDefault()
  emit('invite', {
    existingUsers: selectedWorkspaceMembers.value,
    role: selectedRole.value,
    newUserEmails: [],
  })
  selectedWorkspaceMembers.value = []
}

const dropdownIsOpen = computed(() =>
  Boolean(textValue.value && filteredWorkspaceMembers.value.length),
)

/** For a11y - the HTML ID of the dropdown listbox */
const LISTBOX_ID = 'workspace-member-listbox'

/** For a11y - create a HTML ID for each workspace member's dropdown option */
const getItemLabelId = (item: WorkspaceMember) => `workspace-member-option-${item.id}`
</script>

<template>
  <form
    class="flex items-stretch justify-stretch gap-2"
    @submit="onSubmitForm"
  >
    <div
      ref="textAndDropdowownTrigger"
      class="flex grow items-center gap-0.5 rounded-lg bg-background-gray-subtlest p-0.5"
    >
      <PopupMenu
        :open="dropdownIsOpen"
        :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
        disable-focus-trap
        class="grow"
        trigger-element="div"
        :placement="'bottom-start'"
        :offset="{ mainAxis: 5 }"
      >
        <template #trigger>
          <div class="flex flex-wrap items-center gap-1">
            <div
              v-if="selectedWorkspaceMembers.length > 0"
              class="inline-flex"
            >
              <BadgeItem
                v-for="member in selectedWorkspaceMembers"
                :key="member.id"
                variant="neutral"
                size="sm"
                :label="getBadgeLabel(member)"
                trailing-icon="close"
                @trailing-icon-click="onUnselectMember(member)"
              >
                <template #leading-icon>
                  <AvatarIcon
                    :full-text="`${member.fullName}`"
                    shape="circle"
                    size="2xs"
                  />
                </template>
              </BadgeItem>
            </div>
            <InlineTextField
              :value="textValue"
              placeholder="Add people, emails..."
              size="sm"
              class="min-w-6 grow"
              aria-label="Name/email"
              :input-attrs="{
                role: 'combobox',
                'aria-expanded': dropdownIsOpen ? 'true' : 'false',
                'aria-controls': LISTBOX_ID,
              }"
              @input="textValue = $event"
              @focus="textInputIsFocused = true"
              @blur="textInputIsFocused = false"
            />
          </div>
        </template>
        <template #dropdown>
          <ListMenu
            :items="filteredWorkspaceMembers.map((data) => ({ id: data.member.id, data }))"
            :style="{ width: dropdownWidth + 'px' }"
            :list-box-attrs="{ id: LISTBOX_ID }"
            class="max-h-96"
          >
            <template #item="{ item, active, key, setActiveItem }">
              <ListMenuItem
                :active="active"
                :aria-selected="active"
                :aria-labelledby="getItemLabelId(item.data.member)"
                @mousemove="setActiveItem(key)"
                @select="
                  () => {
                    selectedWorkspaceMembers.push(item.data.member)
                    textValue = ''
                  }
                "
              >
                <template #prefix>
                  <AvatarIcon
                    :full-text="`${item.data.member.fullName}`"
                    shape="circle"
                    size="sm"
                  />
                </template>
                <div
                  :id="getItemLabelId(item.data.member)"
                  class="px-1"
                >
                  <div class="text-sm-12px-default text-text">{{ item.data.member.fullName }}</div>
                  <div class="text-xs-11px-light text-text-subtle">
                    {{ item.data.member.email }}
                  </div>
                </div>
                <template #suffix>
                  <div class="grow text-right text-xs-11px-default text-text-subtlest">
                    {{ getRoleTitle(item.data.projectRole) }}
                  </div>
                </template>
              </ListMenuItem>
            </template>
          </ListMenu>
        </template>
      </PopupMenu>
      <SelectDropdown
        :offset="{ mainAxis: 5 }"
        :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
      >
        <template #trigger>
          <SelectDropdownTrigger inline
            ><div class="w-max text-text">{{ dropdownTriggerText }}</div></SelectDropdownTrigger
          >
        </template>
        <template #dropdown="{ close }">
          <ListMenu
            :items="LIST_ITEMS.map((data) => ({ id: data.role, data }))"
            :style="{ width: dropdownWidth + 'px' }"
          >
            <template #item="{ item, active, key, setActiveItem }">
              <ListMenuItem
                :active="active"
                :aria-selected="active"
                @mousemove="setActiveItem(key)"
                @select="
                  () => {
                    selectedRole = item.data.role
                    close()
                  }
                "
              >
                <div class="flex items-start">
                  <div class="flex size-5 min-w-5 items-center justify-center">
                    <IconSprite
                      v-if="selectedRole === item.data.role"
                      class="text-icon-subtle"
                      icon="check"
                    />
                  </div>
                  <div class="px-1 py-0.5">
                    <div>{{ item.data.title }}</div>
                    <div class="text-text-subtle">{{ item.data.description }}</div>
                  </div>
                </div>
              </ListMenuItem>
            </template>
          </ListMenu>
        </template>
      </SelectDropdown>
    </div>

    <DarwinButton
      variant="black"
      size="md"
      rounded
      type="submit"
      >Invite</DarwinButton
    >
  </form>
</template>
