<script setup lang="ts">
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'
import type { Plan } from '@/modules/Billing/types'
import { useBilling } from '@/modules/Billing/useBilling'
import { computed, ref } from 'vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PaywallDialogTableCell from './PaywallDialogTableCell.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import PaywallDialogVisual from '@/illustrations/PaywallDialog.vue'
import { useStripeCheckout } from '@/modules/Billing/useStripeCheckout'
import { formatLimitValue } from './formatLimitValue'
import IconButton from '@/uiKit/IconButton.vue'
import { PLAN_PRICES } from './constants'

defineProps<{
  open: boolean
  workspaceId: string
}>()

defineEmits<{
  (e: 'close'): void
}>()

const billingStore = useBilling()

const billingPeriod = ref<Plan['billingPeriod']>('monthly')
const billingPeriodOptions: SegmentedControlItem<Plan['billingPeriod']>[] = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annually',
    value: 'yearly',
  },
]

const { isLoadingCheckoutUrl, startStripeCheckout } = useStripeCheckout()

const demoFormLink = computed(() => {
  if (!billingStore.activePlan?.name || billingStore.activePlan.name === 'enterprise') {
    return
  }

  const map = {
    free: 'http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=free-limit-reached',
    pro: 'http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=pro-limit-reached',
  }

  return map[billingStore.activePlan.name]
})
</script>

<template>
  <ModalDialog
    :open="open"
    to="body"
    aria-label="Upgrade your plan"
    class="overflow-hidden"
    @close="$emit('close')"
  >
    <div
      class="relative max-h-[640px] max-w-[540px] overflow-y-auto overflow-x-hidden rounded-corner-16 pb-6 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
    >
      <PaywallDialogVisual class="pointer-events-none absolute top-0" />
      <IconButton
        size="lg"
        variant="neutral"
        class="absolute right-2 top-2 text-text-subtle"
        icon="close"
        rounded
        @click="$emit('close')"
      />
      <div class="px-6">
        <div class="mt-32 w-full">
          <div class="mb-12 flex flex-col items-center gap-4">
            <div class="text-center text-display-sm-24px-bold text-text">
              <div>Go {{ billingStore.activePlan?.name === 'pro' ? 'Enterprise' : 'Pro' }} and</div>
              <div>unleash the power of AI</div>
            </div>
            <SegmentedControl
              :items="billingPeriodOptions"
              :value="billingPeriod"
              name="billing-period"
              @change="billingPeriod = $event"
            >
              <template #item:yearly:trailing
                ><BadgeItem
                  label="-20%"
                  variant="neutral"
                  size="xs" /></template
            ></SegmentedControl>
          </div>
          <table
            class="[tr:first-child>td]:bg-background-success w-full table-fixed border-collapse"
          >
            <thead>
              <tr>
                <th
                  class="rounded-t-xl bg-surface-tertiary-persist px-4 pt-4 text-xs-11px-default text-text-subtlest"
                >
                  Pro
                </th>
                <th class="rounded-t-xl px-4 pt-4 text-xs-11px-default text-text-subtlest">
                  Enterprise
                </th>
              </tr>
              <tr>
                <td
                  class="mb-8 bg-surface-tertiary-persist px-4 pb-4 text-center text-xl-18px-bold text-text"
                >
                  ${{ PLAN_PRICES['pro'][billingPeriod]
                  }}<span class="text-xs-11px-default text-text-subtlest">
                    / {{ billingPeriod === 'monthly' ? 'month' : 'year' }}</span
                  >
                </td>
                <td class="mb-8 px-4 pb-4 text-center text-xl-18px-bold text-text">
                  Talk to Sales
                </td>
              </tr>
              <tr>
                <td class="bg-surface-tertiary-persist p-4">
                  <DarwinButton
                    variant="black"
                    size="sm"
                    class="w-full"
                    :disabled="billingStore.activePlan?.name === 'pro'"
                    :loading="isLoadingCheckoutUrl"
                    @click="
                      startStripeCheckout({
                        workspaceId: workspaceId,
                        billingPeriod: billingPeriod,
                      })
                    "
                  >
                    {{
                      billingStore.activePlan?.name === 'pro' ? 'Current plan' : 'Upgrade to Pro'
                    }}
                  </DarwinButton>
                </td>
                <td class="p-4">
                  <DarwinButton
                    variant="outline"
                    size="sm"
                    class="w-full"
                    target="_blank"
                    :href="demoFormLink"
                  >
                    Contact Sales
                  </DarwinButton>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="h-7 bg-surface-tertiary-persist px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell
                  >{{
                    formatLimitValue(
                      billingStore.getLimit('pro', billingPeriod, 'field_count', 'total'),
                      true,
                    )
                  }}
                  Fields</PaywallDialogTableCell
                >
                <PaywallDialogTableCell>Custom limit on fields</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell
                  >{{
                    formatLimitValue(
                      billingStore.getLimit(
                        'pro',
                        billingPeriod,
                        'tool_token_usage',
                        'billing_period',
                      ),
                      true,
                    )
                  }}
                  tokens per
                  {{ billingPeriod === 'monthly' ? 'month' : 'year' }}</PaywallDialogTableCell
                >
                <PaywallDialogTableCell>Custom limit on tokens</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell
                  >{{
                    formatLimitValue(
                      billingStore.getLimit('pro', billingPeriod, 'property_count', 'total'),
                      true,
                    )
                  }}
                  Properties</PaywallDialogTableCell
                >
                <PaywallDialogTableCell>Unlimited Properties</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell bad
                  >{{
                    formatLimitValue(
                      billingStore.getLimit('pro', billingPeriod, 'member_count', 'total'),
                      true,
                    )
                  }}
                  users</PaywallDialogTableCell
                >
                <PaywallDialogTableCell>Custom limit of users</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell bad>1 workspace</PaywallDialogTableCell>
                <PaywallDialogTableCell>Custom limit on workspaces</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 bg-surface-tertiary-persist px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Command queue priority</PaywallDialogTableCell>
                <PaywallDialogTableCell>Command queue priority</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell bad>Bring your own key</PaywallDialogTableCell>
                <PaywallDialogTableCell>Bring your own key</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 bg-surface-tertiary-persist px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell bad>SAML Single Sign-On (SSO)</PaywallDialogTableCell>
                <PaywallDialogTableCell>SAML Single Sign-On (SSO)</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell bad>Custom data retention</PaywallDialogTableCell>
                <PaywallDialogTableCell>Custom data retention</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 bg-surface-tertiary-persist px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Card only billing</PaywallDialogTableCell>
                <PaywallDialogTableCell>Invoice or card billing</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Monthly or annual payment</PaywallDialogTableCell>
                <PaywallDialogTableCell>Annual payment</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell bad>Enterprise SLAs</PaywallDialogTableCell>
                <PaywallDialogTableCell>Enterprise SLAs</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 bg-surface-tertiary-persist px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Chat support</PaywallDialogTableCell>
                <PaywallDialogTableCell>Customer success manager</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 rounded-b-xl bg-surface-tertiary-persist"></td>
                <td class="h-7"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
