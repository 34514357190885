import { updateView } from '@/backend/updateView'
import { objectMapBy } from '@/shared/utils'
import { computed } from 'vue'
import { useEntity } from './useEntity'
import { useProject, type PropertyLayout } from './useProject'

export function useEntityView() {
  const entityStore = useEntity()
  const projectStore = useProject()

  const layoutInfo = computed(() => {
    const getNewCoords = (idx: number) => {
      const x = (idx * 4) % 12
      const y = Math.floor((idx + 4) / 12)
      return { x, y, w: 4, h: 4 }
    }

    return projectStore.properties.map((property, idx) => {
      return {
        i: property.id,
        readonly:
          projectStore.activeView?.view.propertyOptions?.find(
            (option) => option.property_id === property.id,
          )?.block_workers_edits ?? false,
        property,
        ...(projectStore.activeView?.view.propertyLayouts
          .map((layout) => ({
            ...layout,
            x: layout.x,
            y: layout.y,
            w: layout.width,
            h: layout.height,
          }))
          .find((layout) => layout.propertyId === property?.id) ?? getNewCoords(idx)),
      }
    })
  })

  const layoutItems = computed(() => {
    const mapped = objectMapBy(layoutInfo.value, 'i')
    return Array.from(entityStore.entity?.fields ?? [], ([propertyId, field]) => {
      const layout = mapped[propertyId]
      return {
        ...layout,
        field: field,
      }
    }).filter((item) =>
      projectStore.activeView?.view.propertyIds?.includes(item.property?.id ?? ''),
    )
  })

  type UpdateItemSizeArgs = {
    propertyId: string | number
    workspaceId: string
    projectId: string
    layout?: Pick<PropertyLayout, 'x' | 'y' | 'width' | 'height'>
  }

  const updateItemSize = async ({
    propertyId,
    workspaceId,
    projectId,
    layout: newLayout,
  }: UpdateItemSizeArgs) => {
    const item = layoutInfo.value.find((i) => i.i === propertyId)
    if (!item) return

    if (!projectStore.activeView) return

    // Build new layouts list
    const newLayouts = projectStore.activeView.view.propertyLayouts.map((layout) => {
      if (newLayout) {
        return {
          ...layout,
          ...newLayout,
        }
      }

      const layoutItem = layoutItems.value.find((item) => item.property?.id === layout.propertyId)
      if (!layoutItem) {
        return layout
      }

      return {
        ...layout,
        x: layoutItem.x,
        y: layoutItem.y,
        width: layoutItem.w,
        height: layoutItem.h,
      }
    })

    await updateView({
      workspaceId,
      projectId,
      viewId: projectStore.activeView.view.id,
      name: projectStore.activeView.view.name,
      propertyIds: projectStore.activeView.view.propertyIds ?? [],
      propertyLayouts: newLayouts,
      filters: projectStore.activeView.view.filters,
      propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    })
  }

  return { layoutItems, updateItemSize }
}
