import { listTemplates, type ListTemplatesResponse } from '@/backend/listTemplates'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import { useCloneProject } from './useCloneProject'
import { useCreateProject } from './useCreateProject'

/**
 * Stores the project templates that are used when creating a new project.
 */
export const useTemplates = defineStore('templates', () => {
  const allTemplates = ref<ListTemplatesResponse['data']>([])
  const emptyTemplateUuid = useFeatureFlags(FeatureFlag.EMPTY_TEMPLATE_UUID, '')

  const templates = computed(() =>
    allTemplates.value.filter((t) => t.id !== emptyTemplateUuid.value),
  )
  const emptyTemplate = computed(() => {
    return allTemplates.value.find((t) => t.id === emptyTemplateUuid.value)
  })

  const { captureAnalyticsEvent } = useAnalytics()
  const { cloneProject } = useCloneProject()
  const { createNewProject } = useCreateProject()
  const startFromScratch = async (workspaceId: string) => {
    if (emptyTemplate.value) {
      await cloneProject(emptyTemplate.value.id, workspaceId, 'empty')
    } else {
      await createNewProject(workspaceId)
    }
    captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_CREATED)
  }

  const refreshTemplates = async () => {
    const res = await listTemplates()
    if (!res.ok) return
    allTemplates.value = res.data.data
  }

  return {
    templates,
    emptyTemplate,
    startFromScratch,
    refreshTemplates,
    _allTemplates: allTemplates, // is only used in tests, so we can set the state
  }
})
