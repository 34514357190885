import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useProject, type Property } from './useProject'

type SubProject = {
  name: string | null
  id: string
  workspaceId: string
  properties: Property[]
  parentPropertyId: string
}

export const useSubProject = defineStore('subProject', () => {
  const projectStore = useProject()
  const projects = ref<Partial<Record<string, SubProject>>>({})

  const setProject = (id: string, project: SubProject) => {
    projects.value[id] = project
  }

  const removeProject = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: _, ...remainingProjects } = projects.value
    projects.value = remainingProjects
  }

  const properties = computed<Property[]>(() => {
    const getFullPropertyName = (project: SubProject, property: Property) => {
      const parentPropertyIndex = projectStore.propIdToIndex[project.parentPropertyId]
      const parentProperty = projectStore.properties[parentPropertyIndex]
      return `${parentProperty?.name}/${property.name}`
    }

    const processProjectProperties = (project: SubProject | undefined) => {
      if (!project) return []

      return project.properties.map((property) => ({
        ...property,
        name: getFullPropertyName(project, property),
      }))
    }

    return Object.values(projects.value)
      .map(processProjectProperties)
      .flat()
      .filter((p): p is Property => p !== undefined)
  })

  return {
    projects,
    properties,
    setProject,
    removeProject,
  }
})
