<script setup lang="ts">
import { TYPE_ICON } from '@/modules/Project/icons'
import type { SelectOptionValueFilter } from '@/modules/Project/useFilters'
import { useFilters, type SimpleFilter } from '@/modules/Project/useFilters'
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/useProject'
import IconSprite from '@/uiKit/IconSprite.vue'

import FilterBarItemMatcher, { type ID } from './FilterBarItemMatcher.vue'
import FilterBarSelectItemValuePicker from './FilterBarSelectItemValuePicker.vue'
import { computed } from 'vue'

const props = defineProps<{
  filter: SimpleFilter
  index: number
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
}>()

defineEmits<{
  (e: 'delete'): void
}>()

const filtersStore = useFilters()

const onNewMatcher = (matchId: ID) => {
  if (!filtersStore.currentFilter) return

  if (['property_contains_any_of', 'property_contains_all_of'].includes(matchId)) {
    return
  }

  const oldFilter = filtersStore.currentFilter.filters[props.index] as SelectOptionValueFilter

  const name = (matchId === 'property_not_set' ? 'property_none_of' : matchId) as
    | 'property_any_of'
    | 'property_none_of'
    | 'property_all_of'

  filtersStore.updateFilter(props.index, {
    ...oldFilter,
    matcher: {
      ...oldFilter.matcher,
      // 'property_not_set' is not a valid backend filter matcher, so we swap it out for
      // 'property_none_of' with all options selected.
      name,
      values:
        matchId === 'property_not_set'
          ? props.property.config?.options.map((option) => option.value) || []
          : oldFilter.matcher.values,
    },
  })
}

const optionCount = computed<number | undefined>(() => props.property.config?.options.length)
const hasSelectedIsNotSet = computed<boolean>(() => {
  if (!filtersStore.currentFilter) return false

  const currentItemFilter = filtersStore.currentFilter.filters[props.index]

  return (
    'matcher' in currentItemFilter &&
    currentItemFilter.matcher.name === 'property_none_of' &&
    currentItemFilter.matcher.values.length === optionCount.value
  )
})

const onNewValues = (values: string[]) => {
  if (!filtersStore.currentFilter) return

  const currentItemFilter = filtersStore.currentFilter.filters[
    props.index
  ] as SelectOptionValueFilter

  filtersStore.updateFilter(props.index, {
    ...currentItemFilter,
    matcher: { ...currentItemFilter.matcher, values },
  })
}
</script>

<template>
  <div
    class="flex h-6 shrink-0 cursor-default items-center rounded-corner-6 border border-border-subtle bg-surface-primary"
  >
    <div class="flex gap-0.5 px-1.5 py-1">
      <IconSprite
        class="text-icon-subtle"
        :icon="TYPE_ICON[property.type]"
      />
      <p class="text-sm-12px-default text-text-subtle">{{ property.name }}</p>
    </div>
    <FilterBarItemMatcher
      :filter="filter"
      :type="property.type"
      :option-count="optionCount"
      @match="onNewMatcher"
    />
    <FilterBarSelectItemValuePicker
      v-if="!hasSelectedIsNotSet"
      :filter="filter"
      :property="property"
      @delete="$emit('delete')"
      @values="(values: string[]) => onNewValues(values)"
    />
    <button
      class="cursor-pointer overflow-hidden rounded-corner-6 rounded-l-none p-1.5 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      aria-label="Remove filter"
      @click="$emit('delete')"
    >
      <IconSprite
        class="text-icon-subtle"
        icon="close"
        size="xs"
      />
    </button>
  </div>
</template>
