<script setup lang="ts">
import { useMeterColor } from '@/shared/useMeterColor'
import LinearProgress from '@/uiKit/LinearProgress.vue'
import { toRef } from 'vue'
/**
 * This component is used across the app to show the usage of a feature towards its limit.
 */
const props = withDefaults(
  defineProps<{
    title: string
    titleTag?: 'h3' | 'h2' | 'h1' | 'div'
    currentUsage: number
    maxUsage: number
    /** ID to give to the root element - required for a11y */
    id: string
  }>(),
  { titleTag: 'div' },
)

const numberFormatter = new Intl.NumberFormat()

const meterColor = useMeterColor({
  current: toRef(props, 'currentUsage'),
  max: toRef(props, 'maxUsage'),
  defaultColor: 'blue',
})

const meterLabelId = `${props.id}-meter-label`
</script>

<template>
  <div :id="id">
    <component
      :is="titleTag"
      class="mb-0.5 text-sm-12px-default text-text"
      >{{ title }}</component
    >
    <p
      :id="meterLabelId"
      class="mb-2 text-sm-12px-light text-text-subtlest"
    >
      <span class="text-text">{{ numberFormatter.format(currentUsage) }}</span> /
      {{ numberFormatter.format(maxUsage) }} included
    </p>
    <LinearProgress
      :aria-labelledby="meterLabelId"
      :min="0"
      :max="maxUsage"
      :value="currentUsage"
      :color="meterColor"
      role="meter"
    />
  </div>
</template>
