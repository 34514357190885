<script setup lang="ts">
import { computed, ref } from 'vue'

import type { Project } from '@/modules/Projects/useProjects'

import ProjectCard from './ProjectCard.vue'
import ProjectListItem from './ProjectListItem.vue'
import { useElementSize } from '@vueuse/core'
import { LIMIT_RECENT_PROJECTS } from '@/modules/Projects/useRecentProjectsDataLoader'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'

defineProps<{ workspaceId: string; projects: Project[]; loading: boolean }>()

const container = ref<HTMLDivElement | null>(null)
const { width: containerWidth } = useElementSize(container)

const fittingCardsCount = computed(() => {
  return Math.floor(containerWidth.value / 200)
})
</script>

<template>
  <nav
    class="contents"
    aria-label="Projects"
  >
    <template v-if="!loading">
      <div
        ref="container"
        class="flex w-full justify-start gap-4"
      >
        <ProjectCard
          v-for="project in projects.slice(0, fittingCardsCount)"
          ref="card"
          :key="project.id"
          :project="project"
        />
      </div>
      <div>
        <ProjectListItem
          v-for="project in projects.slice(fittingCardsCount, LIMIT_RECENT_PROJECTS)"
          :key="project.id"
          :project="project"
        />
      </div>
    </template>
    <!-- Loading Skeletons-->
    <template v-if="loading">
      <div
        ref="container"
        class="flex w-full justify-start gap-4"
      >
        <LoadingSkeleton
          v-for="n in fittingCardsCount"
          :key="n"
          :status="true"
          class="h-[260px] min-w-[200px] max-w-[360px]"
        />
      </div>
      <div>
        <div
          v-for="(cls, index) in [
            ['opacity-100', 'w-1/6'],
            ['opacity-100', 'w-1/4'],
            ['opacity-75', 'w-1/6'],
            ['opacity-75', 'w-1/4'],
            ['opacity-50', 'w-1/6'],
            ['opacity-25', 'w-1/4'],
          ]"
          :key="index"
          :class="cls[0]"
        >
          <div class="flex items-center p-4">
            <LoadingSkeleton
              :status="true"
              class="h-12 w-16"
            />
            <LoadingSkeleton
              :status="true"
              class="ml-4 h-6"
              :class="cls[1]"
            />
          </div>
        </div>
      </div>
    </template>
  </nav>
</template>
