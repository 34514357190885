import { captureException } from '@sentry/vue'
import type { PostHog } from 'posthog-js'
import { computed, getCurrentInstance, type ComputedRef } from 'vue'

/**
 * Enum of all ab tests used in PostHog.
 */
export const AbTest = {
  SIGNUP_LANDING_PAGE: 'signup-landing-page',
  REMOVE_WELCOME_TOUR_SKIP: 'remove-welcome-tour-skip',
} as const

export type AbTest = (typeof AbTest)[keyof typeof AbTest]

/** Maps each test to its possible values */
const TEST_VALUES = {
  'signup-landing-page': ['control', 'empty-project'],
  'remove-welcome-tour-skip': ['control', 'remove-skip'],
} as const satisfies Record<AbTest, Array<unknown>>

type AbTestValues = {
  [K in keyof typeof TEST_VALUES]: (typeof TEST_VALUES)[K][number]
}

/** Maps each test to its default value */
const DEFAULT_VALUES = {
  'signup-landing-page': 'control',
  'remove-welcome-tour-skip': 'control',
} as const satisfies AbTestValues

/**
 * Composable function that will return a given feature flag's active variation for
 * the current user.
 */
export const useAbTest = <FlagName extends AbTest>(
  flagName: FlagName,
): ComputedRef<AbTestValues[FlagName]> => {
  const instance = getCurrentInstance()
  const posthog = computed<PostHog | undefined>(
    () => instance?.appContext.config.globalProperties.$posthog,
  )

  return computed(() => {
    if (!posthog.value) {
      return DEFAULT_VALUES[flagName]
    }

    const flagValue = posthog.value.getFeatureFlag(flagName)

    const acceptedValues: readonly unknown[] = TEST_VALUES[flagName]
    if (!acceptedValues.includes(flagValue)) {
      captureException(new Error('Unexpected flag value'), { data: { flagName, flagValue } })
    }

    return flagValue as AbTestValues[FlagName]
  })
}
