import { PropertyTool, PropertyType, ToolProvider } from '@/backend/types'

export const FIELD_TYPES_OPTIONS = [
  { type: PropertyType.file, label: 'File' },
  { type: PropertyType.text, label: 'Text' },
  { type: PropertyType.single_select, label: 'Single Select' },
  { type: PropertyType.multi_select, label: 'Multi Select' },
  { type: PropertyType.user_select, label: 'User' },
  { type: PropertyType.json, label: 'JSON' },
  { type: PropertyType.pdf, label: 'PDF' },
  { type: PropertyType.collection, label: 'Collection' },
  { type: PropertyType.url, label: 'URL' },
]

export const FIELD_TYPES_LABELS: Record<PropertyType, string> = {
  [PropertyType.file]: 'File',
  [PropertyType.text]: 'Text',
  [PropertyType.single_select]: 'Single Select',
  [PropertyType.multi_select]: 'Multi Select',
  [PropertyType.user_select]: 'User',
  [PropertyType.pdf]: 'PDF',
  [PropertyType.json]: 'JSON',
  [PropertyType.collection]: 'Collection',
  [PropertyType.url]: 'URL',
} as const

export const FIELD_TOOL_LABELS: Record<PropertyTool, string> = {
  [PropertyTool.manual]: 'User input',
  [PropertyTool.go]: 'Go',
  [PropertyTool.gpt_4o_grounded]: 'GPT 4 Omni',
  [PropertyTool.gpt_4o]: 'GPT 4 Omni',
  [PropertyTool.gpt_4o_mini]: 'GPT 4 Omni Mini',
  [PropertyTool.gpt_4]: 'GPT 4 Turbo',
  [PropertyTool.gpt_3_5]: 'GPT 3.5 Turbo',
  [PropertyTool.whisper]: 'Whisper',
  [PropertyTool.ocr]: 'OCR',
  [PropertyTool.dall_e_3]: 'DALL-E 3',
  [PropertyTool.gemini_1_5_pro]: 'Gemini 1.5 Pro',
  [PropertyTool.gemini_1_5_flash]: 'Gemini 1.5 Flash',
  [PropertyTool.gemini_pro]: 'Gemini Pro',
  [PropertyTool.gemini_pro_vision]: 'Gemini Pro Vision',
  [PropertyTool.pdf_split]: 'PDF Split',
  [PropertyTool.claude_3_opus]: 'Claude 3 Opus',
  [PropertyTool.claude_3_5_sonnet]: 'Claude 3.5 Sonnet',
  [PropertyTool.claude_3_sonnet]: 'Claude 3 Sonnet',
  [PropertyTool.claude_3_haiku]: 'Claude 3 Haiku',
  [PropertyTool.bing_search]: 'Web Search',
  [PropertyTool.url_scrape]: 'URL Scrape',
  [PropertyTool.code]: 'Python Tool',
  [PropertyTool.gpt_3_5_azure]: 'GPT 3.5 Turbo (Azure)',
  [PropertyTool.gpt_4o_azure]: 'GPT 4 Omni (Azure)',
  [PropertyTool.gpt_4o_azure_grounded]: 'GPT 4 Omni (Azure)',
  [PropertyTool.aws_ocr]: 'AWS OCR',
  [PropertyTool.imagen]: 'Imagen',
} as const

export const FIELD_TOOLS_OPTIONS = Object.entries(FIELD_TOOL_LABELS).map(([tool, label]) => ({
  tool: tool as PropertyTool,
  label,
}))

/**
 * Maps each property type to a default tool that will be selected when
 * creating a new poperty of that type.
 */
export const FIELD_DEFAULT_TOOL: Record<PropertyType, PropertyTool> = {
  [PropertyType.file]: PropertyTool.manual,
  [PropertyType.text]: PropertyTool.gpt_4o,
  [PropertyType.single_select]: PropertyTool.gpt_4o,
  [PropertyType.multi_select]: PropertyTool.gpt_4o,
  [PropertyType.user_select]: PropertyTool.manual,
  [PropertyType.pdf]: PropertyTool.manual,
  [PropertyType.json]: PropertyTool.gpt_4o,
  [PropertyType.collection]: PropertyTool.gpt_4o,
  [PropertyType.url]: PropertyTool.manual,
}

export const FIELD_TOOL_OUTPUT_TYPES: Record<PropertyTool, PropertyType[]> = {
  [PropertyTool.manual]: [
    PropertyType.text,
    PropertyType.file,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.url,
    PropertyType.pdf,
  ],
  [PropertyTool.go]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.gpt_4]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.gpt_4o]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.gpt_4o_mini]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.gpt_3_5]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.url,
  ],
  [PropertyTool.gpt_4o_azure]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.gpt_3_5_azure]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.url,
  ],
  [PropertyTool.gpt_4o_azure_grounded]: [],
  [PropertyTool.whisper]: [PropertyType.text],
  [PropertyTool.ocr]: [PropertyType.text, PropertyType.json],
  [PropertyTool.aws_ocr]: [PropertyType.text, PropertyType.json],
  [PropertyTool.dall_e_3]: [PropertyType.file],
  [PropertyTool.gemini_pro]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
  ],
  [PropertyTool.gemini_pro_vision]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
  ],
  [PropertyTool.gemini_1_5_pro]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
  ],
  [PropertyTool.gemini_1_5_flash]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
  ],
  [PropertyTool.imagen]: [PropertyType.file],
  [PropertyTool.pdf_split]: [],
  [PropertyTool.claude_3_opus]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.claude_3_5_sonnet]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.claude_3_sonnet]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.claude_3_haiku]: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  [PropertyTool.url_scrape]: [PropertyType.text, PropertyType.json],
  [PropertyTool.bing_search]: [PropertyType.json],
  [PropertyTool.code]: [PropertyType.text, PropertyType.json],
  [PropertyTool.gpt_4o_grounded]: [],
}

const GPT4O_DESCRIPTION =
  "GPT-4 Omni is the most powerful version of the OpenAI's GPT suite of models, supporting text, images and audio."

export const TOOL_DESCRIPTIONS: Record<PropertyTool, string> = {
  [PropertyTool.claude_3_haiku]:
    "Haiku is the fastest and cheapest version of Anthropic's Claude 3.",
  [PropertyTool.claude_3_opus]: "Opus is the most powerful version of Anthropic's Claude 3.",
  [PropertyTool.claude_3_5_sonnet]:
    'Claude 3.5 Sonnet is the latest and most powerful model released by Anthropic with the speed and cost of the mid-tier model.',
  [PropertyTool.claude_3_sonnet]:
    "Sonnet is the mid-tier version of Anthropic's Claude 3, more powerful than Haiku, but faster and cheaper than Opus.",
  [PropertyTool.dall_e_3]: 'Dall-E 3 is a text-to-image model by OpenAI.',
  [PropertyTool.gemini_1_5_pro]: 'Gemini 1.5 is the most powerful version of Google Gemini.',
  [PropertyTool.gemini_1_5_flash]:
    'Gemini 1.5 Flash is the fastest and cheapest version of Google Gemini 1.5.',
  [PropertyTool.gemini_pro]: "Gemini Pro is Google's most powerful Gemini 1.0 model.",
  [PropertyTool.gemini_pro_vision]:
    "Gemini Pro Vision is the multimodal version of Google's Gemini 1.0.",
  [PropertyTool.go]: 'Dynamically adjusts the model to find the best answer.',
  [PropertyTool.gpt_3_5]:
    "GPT-3.5 is the fastest and cheapest version of the OpenAI's GPT suite of models.",
  [PropertyTool.gpt_4o]: GPT4O_DESCRIPTION,
  [PropertyTool.gpt_4o_mini]:
    "GPT-4 Omni Mini is a faster and cheaper version of OpenAI's GPT-4 Omni.",
  [PropertyTool.gpt_3_5_azure]:
    "GPT-3.5 is the fastest and cheapest version of the OpenAI's GPT suite of models.",
  [PropertyTool.gpt_4o_azure]: GPT4O_DESCRIPTION,
  [PropertyTool.gpt_4o_azure_grounded]: GPT4O_DESCRIPTION,
  [PropertyTool.gpt_4]:
    "GPT4 Turbo is OpenAI's second most powerful model, with a 128k context window.",
  [PropertyTool.manual]: 'Manual user input.',
  [PropertyTool.ocr]:
    'OCR is our state-of-the-art multi-language Optical Character Recognition model.',
  [PropertyTool.aws_ocr]:
    'Provided by Amazon Textract, a state-of-the-art multi-language Optical Character Recognition model.',
  [PropertyTool.whisper]: "Whisper is OpenAI's text transcription model for audio and video files.",
  [PropertyTool.pdf_split]: 'Split PDFs into individual pages.',
  [PropertyTool.url_scrape]: 'Scrape data from a URL.',
  [PropertyTool.bing_search]:
    'Web Search retrives information from the web to empower your Go projects.',
  [PropertyTool.code]: 'Run code in Python',
  [PropertyTool.gpt_4o_grounded]: GPT4O_DESCRIPTION,
  [PropertyTool.imagen]: "Imagen is Google's text-to-image model.",
}

/**
 * Mapping of all tools to their respective providers.
 */
export const TOOL_PROVIDERS: Record<PropertyTool, ToolProvider> = {
  [PropertyTool.bing_search]: ToolProvider.bing_search,
  [PropertyTool.claude_3_opus]: ToolProvider.anthropic,
  [PropertyTool.claude_3_5_sonnet]: ToolProvider.anthropic,
  [PropertyTool.claude_3_sonnet]: ToolProvider.anthropic,
  [PropertyTool.claude_3_haiku]: ToolProvider.anthropic,
  [PropertyTool.gemini_1_5_pro]: ToolProvider.google_ai,
  [PropertyTool.gemini_1_5_flash]: ToolProvider.google_ai,
  [PropertyTool.gemini_pro]: ToolProvider.google_ai,
  [PropertyTool.gemini_pro_vision]: ToolProvider.google_ai,
  [PropertyTool.go]: ToolProvider.open_ai,
  [PropertyTool.gpt_4]: ToolProvider.open_ai,
  [PropertyTool.gpt_4o]: ToolProvider.open_ai,
  [PropertyTool.gpt_4o_mini]: ToolProvider.open_ai,
  [PropertyTool.gpt_4o_grounded]: ToolProvider.open_ai,
  [PropertyTool.gpt_3_5]: ToolProvider.open_ai,
  [PropertyTool.dall_e_3]: ToolProvider.open_ai,
  [PropertyTool.whisper]: ToolProvider.open_ai,
  [PropertyTool.manual]: ToolProvider.internal,
  [PropertyTool.ocr]: ToolProvider.azure_ocr,
  [PropertyTool.pdf_split]: ToolProvider.internal,
  [PropertyTool.url_scrape]: ToolProvider.internal,
  [PropertyTool.code]: ToolProvider.internal,
  [PropertyTool.gpt_3_5_azure]: ToolProvider.azure_open_ai,
  [PropertyTool.gpt_4o_azure]: ToolProvider.azure_open_ai,
  [PropertyTool.gpt_4o_azure_grounded]: ToolProvider.azure_open_ai,
  [PropertyTool.aws_ocr]: ToolProvider.amazon_textract,
  [PropertyTool.imagen]: ToolProvider.google_ai,
}

export const TOOL_PROVIDER_LABELS: Record<ToolProvider, string> = {
  [ToolProvider.anthropic]: 'Anthropic',
  [ToolProvider.google_ai]: 'Google',
  [ToolProvider.open_ai]: 'OpenAI',
  [ToolProvider.internal]: 'V7',
  [ToolProvider.azure_ocr]: 'Azure OCR',
  [ToolProvider.bing_search]: 'Bing Search',
  [ToolProvider.fire_crawl]: 'Firecrawl',
  [ToolProvider.azure_open_ai]: 'Azure OpenAI',
  [ToolProvider.amazon_textract]: 'Amazon Textract',
}

/**
 * Maps each tool to a boolean indicating whether it is compatible with grounding.
 */
const TOOL_GROUNDING_COMPATIBILITY: Record<PropertyTool, boolean> = {
  [PropertyTool.bing_search]: false,
  [PropertyTool.claude_3_opus]: false,
  [PropertyTool.claude_3_5_sonnet]: false,
  [PropertyTool.claude_3_sonnet]: false,
  [PropertyTool.claude_3_haiku]: false,
  [PropertyTool.gemini_1_5_pro]: false,
  [PropertyTool.gemini_1_5_flash]: false,
  [PropertyTool.gemini_pro]: false,
  [PropertyTool.gemini_pro_vision]: false,
  [PropertyTool.go]: false,
  [PropertyTool.gpt_4]: false,
  [PropertyTool.gpt_4o]: false,
  [PropertyTool.gpt_4o_mini]: false,
  [PropertyTool.gpt_4o_grounded]: true,
  [PropertyTool.gpt_3_5]: false,
  [PropertyTool.dall_e_3]: false,
  [PropertyTool.whisper]: false,
  [PropertyTool.manual]: false,
  [PropertyTool.ocr]: false,
  [PropertyTool.pdf_split]: false,
  [PropertyTool.url_scrape]: false,
  [PropertyTool.code]: false,
  [PropertyTool.gpt_3_5_azure]: false,
  [PropertyTool.gpt_4o_azure]: false,
  [PropertyTool.gpt_4o_azure_grounded]: true,
  [PropertyTool.aws_ocr]: false,
  [PropertyTool.imagen]: false,
}

/**
 * List of all tools that are compatible with grounding.
 */
export const GROUNDED_TOOLS: PropertyTool[] = Object.entries(TOOL_GROUNDING_COMPATIBILITY).reduce<
  PropertyTool[]
>((acc, [tool, value]) => {
  if (value) {
    acc.push(tool as PropertyTool)
  }

  return acc
}, [])

/**
 * List of all tools that are not compatible with grounding.
 */
export const UNGROUNDED_TOOLS: PropertyTool[] = Object.values(PropertyTool).filter(
  (tool) => !GROUNDED_TOOLS.includes(tool),
)
