import { computed, ref, watchEffect, type ComputedRef, type Ref } from 'vue'
import { useLDClient } from './useLDClient'

export const FeatureFlag = {
  // add your feature flags here, the value on the right is the key defined in the LaunchDarkly dashboard for example:
  MAX_OWNED_WORKSPACES: 'max_owned_workspaces',
  PDF_PROPERTY_TYPE: 'pdf-property-type',
  WHISPER_TOOL: 'whisper-tool',
  EMPTY_TEMPLATE_UUID: 'empty-project-template-uuid',
  BLOCK_MOBILE_USE: 'block-mobile-use',
  BING_SEARCH_ENABLED: 'show-bing-search',
  URL_PROPERTY_TOOL: 'url-property-tool',
  AB_PROPERTY_EDITOR: 'ab-property-editor',
  GO_TOOL_ENABLED: 'show-go-model',
  /**
   * If true, then the 'worker' role is available. Users can then be created as
   * workers, and updated to have this role.
   */
  WORK_ASSIGNENT: 'work-assignment',
  GROUNDING: 'visual-grounding',
  // When true, the user can see the AWS OCR tool
  AMAZON_TEXTRACT: 'amazon-textract-tool',
  /**
   * When true, all users will see the Intercom chat widget. When false, only
   * pro and enterprise users will see the chat widget.
   */
  INTERCOM_FOR_ALL_USERS: 'intercom-for-all-users',
  ASK_GO_PROJECT_CREATION: 'ask-go-project-creation',
  PDF_DOWNLOAD: 'pdf-download',
  WELCOME_TOUR: 'welcome-tour',
  /**
   * When true, non enterprise users will see the total estimated value of the tokens for the given plan.
   */
  PLAN_TOKENS_VALUE: 'plan-tokens-value',
  /**
   * When true, users will be able to view and update permissions for each project.
   */
  PROJECT_LEVEL_PERMISSIONS: 'project-level-permissions',
} as const

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag]

export const useFeatureFlags = <T = boolean>(
  feature: FeatureFlag,
  defaultValue?: T,
): ComputedRef<T | false> => {
  const ld = useLDClient()
  const value = ref(defaultValue ?? false) as Ref<T | false>

  watchEffect(() => {
    if (!ld.isReady) return
    value.value = ld.client?.variation(feature, defaultValue)

    function change() {
      value.value = ld.client?.variation(feature, defaultValue)
    }
    change()

    ld.client?.on(`change`, change)

    return () => {
      ld.client?.off(`change`, change)
    }
  })

  return computed(() => value.value)
}
