/**
 * It sucks but we need to hardcode these prices because the backend currently
 * doesn't return them. Enterprise plans never have prices that are included
 * in the UI, so we don't need to worry about them.
 */
export const PLAN_PRICES = {
  free: {
    monthly: 0,
    yearly: 0,
  },
  pro: {
    monthly: 249,
    yearly: 2390,
  },
} as const
