import type { components, paths } from '@/api.d.ts'
import type { EmptyObject } from '@/shared/types'
import type { CreateInvitationsResponse } from './createInvitations'

/**
 * The shape of the single record we get when
 * fetching a workspace or a list of workspaces from the backend
 */
export type WorkspaceResponse = components['schemas']['Workspaces.WorkspaceResponse']

/**
 * The shape of the single record we get when
 * fetching a project or a list of projects from the backend
 */
export type ProjectResponse = components['schemas']['Projects.ProjectResponse']

export type ViewResponse = components['schemas']['Projects.ViewResponse']

export type SessionResponse = components['schemas']['AskGo.SessionResponse']

export type APIError = components['schemas']['Common.APIError']

export type APIResult<T> = { ok: true; data: T } | { ok: false; error: APIError }

export type PropertyResponse = components['schemas']['Projects.PropertyResponse']

export type SingleSelectPropertyConfig =
  components['schemas']['Projects.SingleSelectPropertyConfigResponse']

export type MultiSelectPropertyConfig =
  components['schemas']['Projects.MultiSelectPropertyConfigResponse']

export type PdfPropertyConfig = components['schemas']['Projects.PDFPropertyConfigResponse']

export type CollectionPropertyConfig = components['schemas']['Projects.TablePropertyConfigResponse']

export type PropertyConfig =
  | SingleSelectPropertyConfig
  | MultiSelectPropertyConfig
  | PdfPropertyConfig
  | CollectionPropertyConfig

export type PropertyType = components['schemas']['Projects.Common.PropertyType']

export const PropertyType: { [T in PropertyType]: T } = {
  file: 'file',
  text: 'text',
  url: 'url',
  single_select: 'single_select',
  multi_select: 'multi_select',
  user_select: 'user_select',
  pdf: 'pdf',
  json: 'json',
  collection: 'collection',
} as const

export type PropertyTool = components['schemas']['Projects.Common.PropertyTool']
export const PropertyTool: { [T in PropertyTool]: T } = {
  gpt_3_5: 'gpt_3_5',
  gpt_4: 'gpt_4',
  gpt_4o: 'gpt_4o',
  gpt_4o_mini: 'gpt_4o_mini',
  gpt_4o_grounded: 'gpt_4o_grounded',
  gpt_4o_azure_grounded: 'gpt_4o_azure_grounded',
  go: 'go',
  whisper: 'whisper',
  manual: 'manual',
  ocr: 'ocr',
  dall_e_3: 'dall_e_3',
  gemini_pro: 'gemini_pro',
  gemini_pro_vision: 'gemini_pro_vision',
  gemini_1_5_pro: 'gemini_1_5_pro',
  gemini_1_5_flash: 'gemini_1_5_flash',
  pdf_split: 'pdf_split',
  claude_3_opus: 'claude_3_opus',
  claude_3_5_sonnet: 'claude_3_5_sonnet',
  claude_3_sonnet: 'claude_3_sonnet',
  claude_3_haiku: 'claude_3_haiku',
  bing_search: 'bing_search',
  url_scrape: 'url_scrape',
  code: 'code',
  gpt_3_5_azure: 'gpt_3_5_azure',
  gpt_4o_azure: 'gpt_4o_azure',
  aws_ocr: 'aws_ocr',
  imagen: 'imagen',
} as const

export type FieldStatus = components['schemas']['Projects.Entities.FieldResponse']['status']
export const FieldStatus: { [T in FieldStatus]: T } = {
  complete: 'complete',
  computing: 'computing',
  error: 'error',
  idle: 'idle',
  uploading: 'uploading',
  waiting: 'waiting',
}

export type EntityResponse = components['schemas']['Projects.Entities.EntityResponse']
export type LibraryItemResponse = components['schemas']['Library.EntityResponse']

export type UserMeResponse = components['schemas']['Users.UserMeResponse']

export type InvitationResponse = components['schemas']['Invitations.WorkspaceInvitationResponse']

export type User = components['schemas']['Users.User']
export type WorkspaceMemberResponse =
  components['schemas']['Users.ListResourceMembersResponse']['data'][number]

export type CreateInvitationResponse = CreateInvitationsResponse['data'][number]

export type ApiKeyResponse = components['schemas']['ApiKeys.ApiKeyResponse']

export type TriggerResponse = components['schemas']['Automations.TriggerResponse']

export type SupportedMimeType = components['schemas']['Common.MimeType']

/** This object internally enforces we always list out all the mime types supported on the backend */
const SUPPORTED_MIME_TYPES_HELPER = {
  'application/pdf': 'application/pdf',
  'application/json': 'application/json',
  'image/bmp': 'image/bmp',
  'image/gif': 'image/gif',
  'image/jpeg': 'image/jpeg',
  'image/png': 'image/png',
  'image/tiff': 'image/tiff',
  'image/webp': 'image/webp',
  'text/csv': 'text/csv',
  'text/html': 'text/html',
  'text/markdown': 'text/markdown',
  'text/plain': 'text/plain',
  'audio/mp4': 'audio/mp4',
  'audio/mpeg': 'audio/mpeg',
  'audio/ogg': 'audio/ogg',
  // mozilla uses audio/x-wav, but the backend uses audio/wav
  'audio/wav': ['audio/wav', 'audio/x-wav' as 'audio/wav'],
  'audio/x-flac': 'audio/x-flac',
  // 'video/mp4': 'video/mp4',
  // 'video/mpeg': 'video/mpeg',
  // 'video/webm': 'video/webm',
} as const satisfies Partial<{ [K in SupportedMimeType]: K | K[] }>

/**
 * A list of all mime types one could upload into an entity file cell, that the platform supports
 */
export const SUPPORTED_MIME_TYPES = Object.values(SUPPORTED_MIME_TYPES_HELPER).flat()

export type SupportedFileExtensions = components['schemas']['Common.FileExtension']

/** This object internally enforces we always list out all the file extensions supported on the backend */
export const SUPPORTED_FILE_EXTENSIONS_HELPER: { [k in SupportedFileExtensions]: k } = {
  bmp: 'bmp',
  csv: 'csv',
  gif: 'gif',
  html: 'html',
  jpeg: 'jpeg',
  jpg: 'jpg',
  webp: 'webp',
  md: 'md',
  pdf: 'pdf',
  png: 'png',
  tiff: 'tiff',
  txt: 'txt',
  json: 'json',
} as const

/**
 * A list of all file extensions one could upload into an entity file cell, that the platform supports
 */
export const SUPPORTED_FILE_EXTENSIONS = Object.values(SUPPORTED_FILE_EXTENSIONS_HELPER)

export type PlanTemplate = components['schemas']['Billing.PlanTemplate']
export type Plan = components['schemas']['Billing.Plan']
export type Limit = components['schemas']['Billing.UsageLimit']
export type LimitWithUsage = components['schemas']['Billing.UsageLimitWithUsage']

/* Helper types */

// For every occurence of {some_string} in a string, replaces it with `string`.
// example: `/api/workspaces/{workspace_id}` -> `/api/workspaces/${string}`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ReplacePathParams<T extends string> = T extends `${infer L}{${infer _}}${infer R}`
  ? ReplacePathParams<`${L}${string}${R}`>
  : T

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtractParams<S extends string> = S extends `${infer _Start}{${infer Param}}${infer Rest}`
  ? { [K in Param | keyof ExtractParams<Rest>]: string }
  : EmptyObject

type GetOperation<P extends keyof paths, K extends keyof paths[P]> = paths[P][K]

export type BackendMeta<P extends keyof paths, K extends keyof paths[P]> = {
  path: ReplacePathParams<P>
  pathParams: GetOperation<P, K> extends { parameters?: { path?: infer P } } ? P : ExtractParams<P>
  queryParams: GetOperation<P, K> extends { parameters?: { query?: infer Q } } ? Q : never
  operation: GetOperation<P, K>
  responses: GetOperation<P, K> extends { responses: infer R } ? R : never
  successResponse: GetOperation<P, K> extends {
    responses: { 200: { content: { 'application/json': infer R } } }
  }
    ? R
    : never
  body: GetOperation<P, K> extends { requestBody?: { content: { 'application/json': infer T } } }
    ? T
    : never
}

export type ProjectTemplate = BackendMeta<
  '/api/project_templates',
  'get'
>['successResponse']['data'][number]

export type ToolKey = Exclude<
  BackendMeta<'/api/workspaces/{workspace_id}/tool_keys', 'get'>['successResponse']['data'],
  undefined
>[number]

export type ToolProvider = components['schemas']['Tools.KeyManager.Integration']
export const ToolProvider: { [T in ToolProvider]: T } = {
  open_ai: 'open_ai',
  google_ai: 'google_ai',
  anthropic: 'anthropic',
  internal: 'internal',
  azure_ocr: 'azure_ocr',
  bing_search: 'bing_search',
  fire_crawl: 'fire_crawl',
  azure_open_ai: 'azure_open_ai',
  amazon_textract: 'amazon_textract',
} as const

export type IntegrationToolName = components['schemas']['Tools.Integrations.ToolName']

export type ToolIntegration = components['schemas']['Tools.Integrations.Tool']

export type OcrPage = components['schemas']['Projects.Entities.OCRPage']

export type ResourceRole = components['schemas']['Users.AddMemberRequest']['role']
