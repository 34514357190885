import type { MultiSelectProperty, Property, SingleSelectProperty, View } from './useProject'

export const getSelectPropertyOptionColor = (
  property: SingleSelectProperty | MultiSelectProperty,
  optionValue: string,
) => {
  return property.config?.options.find((option) => option.value === optionValue)?.color
}

export const getViewColors = (view: View, properties: Property[]) => {
  return view.filters.flatMap((filter) => {
    const property = properties.find((property) => property.id === filter.property_id)
    if (!property) return []

    return getSelectPropertyOptionColor(
      property as SingleSelectProperty,
      filter.select_option_value,
    )
  })
}

export const getViewColor = (view: View, properties: Property[]) => {
  const colors = getViewColors(view, properties)

  if (colors.length === 1) {
    return colors[0]
  }

  return 'rainbow-17'
}
