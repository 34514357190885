<script setup lang="ts">
import IconSprite from './IconSprite.vue'
import type { IconName } from './IconName'
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    icon?: IconName
    iconColor?: string
    active?: boolean
    critical?: boolean
    /**
     * HTML aria role to apply to the list item.
     *
     * - 'option' if the list item is a selectable option in a listbox
     * - 'combobox' if the list item will open another dropdown when clicked
     * - 'button' if the list item is a button and does neither of the above
     *
     * @default 'option'
     */
    role?: string
    autoScrollToActive?: boolean
    defaultHoverDisabled?: boolean
    /* The element to use as the trigger. Defaults to a div, to encapsulate interact-able elements.
     * But can also be used as a button, to correctly handle keyboard events.
     *
     * @default 'div'
     */
    element?: 'div' | 'button'
  }>(),
  {
    role: 'option',
    label: undefined,
    icon: undefined,
    iconColor: undefined,
    iconSize: undefined,
    autoScrollToActive: undefined,
    defaultHoverDisabled: undefined,
    element: 'div',
  },
)

defineEmits<{
  (e: 'select'): void
}>()

const el = ref<HTMLElement | null>(null)

watch(
  () => props.active,
  () => {
    if (props.autoScrollToActive && props.active) {
      el.value?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      })
    }
  },
)
</script>

<template>
  <component
    :is="element"
    ref="el"
    :tabindex="active ? 1 : 0"
    class="flex min-h-7 w-full flex-row items-center justify-start gap-1 self-stretch rounded-corner-8 px-1.5 py-1 text-sm-12px-default text-icon outline-none transition data-[highlighted]:bg-background-transparent-hovered"
    :class="[
      critical ? 'text-text-critical' : 'text-text',
      active && ['bg-background-transparent-hovered active:bg-background-transparent-pressed'],
      !active &&
        !defaultHoverDisabled && [
          'hover:bg-background-transparent-hovered active:bg-background-transparent-pressed',
          // Avoid hover state when the container has a highlighted item
          'group-has-[[data-highlighted]]:hover:[&:not([data-highlighted])]:bg-[unset]',
        ],
      // Avoid focus-based hover state when explicitly passing in an active state
      active === undefined &&
        `[&:focus:not([data-scope='menu'])]:bg-background-transparent-hovered`,
    ]"
    :role="role"
    @click="$emit('select')"
    @keydown.enter="$emit('select')"
  >
    <slot name="prefix" />
    <div
      v-if="icon"
      class="flex size-5 items-center justify-center"
    >
      <IconSprite
        :icon="icon"
        :class="critical ? 'text-icon-critical' : 'text-icon-subtle'"
        :style="iconColor ? { color: iconColor } : undefined"
      />
    </div>
    <slot>
      <span
        v-if="label"
        class="ml-1 max-w-64 grow truncate"
        :title="label"
        :aria-label="label"
        >{{ label }}</span
      >
    </slot>
    <slot name="suffix" />
  </component>
</template>
