import { getEntity } from '@/backend/getEntity'
import { FeatureFlag, useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { toast } from '@/shared/toast'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { computed, type ComputedRef } from 'vue'
import { useGroundingStore } from './useGroundingStore'
import {
  serializeEntity,
  useProject,
  type Entity,
  type Field,
  type FileField,
  type Property,
} from './useProject'

type Params = {
  field: ComputedRef<Field | undefined>
  entity: ComputedRef<Entity | undefined>
  property: ComputedRef<Property | undefined>
}

/**
 * Composable function that returns a function used to open the grounding modal. It will make a request
 * to the backend to load the full entity (with grounding+ocr data) if the entity doesn't have this data
 * when calling the function (e.g. when called from the table view, there the entities were loaded
 * with the paginated listEntities call which returns entities without grounding data).
 */
export const useOpenGrounding = ({ field, entity, property }: Params) => {
  const groundingStore = useGroundingStore()
  const workspacesStore = useWorkspaces()
  const projectStore = useProject()

  const openGroundingModal = async ({
    entity,
    property,
    projectId,
    workspaceId,
  }: {
    entity: Entity
    property: Property
    workspaceId: string
    projectId: string
  }) => {
    let groundingField = entity.fields.get(property.id)
    if (groundingField?.type !== 'text') {
      throw new Error('Grounding field must be of type text')
    }

    if (!groundingField.grounding) {
      const res = await getEntity(workspaceId, projectId, entity.id)

      if (!res.ok) {
        toast.error('Failed to load grounding for this field. Please try again.')
        return
      }

      entity = serializeEntity(res.data)
      groundingField = entity.fields.get(property.id)
      if (groundingField?.type !== 'text') {
        throw new Error('Grounding field must be of type text')
      }
    }

    assertIsNotNullOrUndefined(groundingField.grounding, 'Field has no grounding data')

    const inputPropertyIds = property.inputs.map((input) => input.propertyId)
    const inputFields = Array.from(entity.fields.values()).reduce<FileField[]>((acc, curr) => {
      if (curr.type !== 'file') {
        return acc
      }

      if (inputPropertyIds.some((id) => id === curr.propertyId)) {
        return [...acc, curr]
      }

      return acc
    }, [])

    groundingStore.inputFields = inputFields
    groundingStore.field = groundingField
    groundingStore.activeInputId = inputFields[0].propertyId
  }

  const onOpenGroundingModal = async () => {
    assertIsNotNullOrUndefined(
      workspacesStore.currentWorkspace,
      'No current workspace found when opening grounding modal',
    )
    assertIsNotNullOrUndefined(
      projectStore.projectId,
      'No project ID found when opening grounding modal',
    )
    assertIsNotNullOrUndefined(entity.value, 'No entity found when opening grounding modal')
    assertIsNotNullOrUndefined(property.value, 'No property found when opening grounding modal')
    await openGroundingModal({
      entity: entity.value,
      projectId: projectStore.projectId,
      workspaceId: workspacesStore.currentWorkspace.id,
      property: property.value,
    })
  }

  const isGroundingFeatureEnabled = useFeatureFlags(FeatureFlag.GROUNDING)

  const hasGroundingClaim = computed(() => {
    return Boolean(
      isGroundingFeatureEnabled.value && field.value?.type === 'text' && field.value.hasGrounding,
    )
  })

  return { openGroundingModal, onOpenGroundingModal, hasGroundingClaim, isGroundingFeatureEnabled }
}
