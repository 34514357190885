<script setup lang="ts">
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import type { ResourceRole } from '@/backend/types'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import { computed, ref } from 'vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import type { ProjectMemberRole } from './types'
import { PERMISSIONS_DIALOG_ID } from './consts'
import IconSprite from '@/uiKit/IconSprite.vue'

const props = defineProps<{
  workspaceMember: WorkspaceMember
  isCurrentUser: boolean
  role?: ResourceRole | 'owner'
}>()

const emit = defineEmits<{
  (e: 'change:role', role: ResourceRole | null): void
}>()

const isOpen = ref(false)

const roleTitleMap: Record<ProjectMemberRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
}
const getRoleTitle = (role?: ResourceRole | 'owner'): string | undefined => {
  if (role === 'owner') {
    return 'Owner'
  }

  if (role !== 'editor' && role !== 'reader') {
    return
  }

  return roleTitleMap[role].toLowerCase()
}
const LIST_ITEMS: Array<{ title: string; description?: string; role: ProjectMemberRole | null }> = [
  {
    role: 'editor',
    title: roleTitleMap.editor,
    description: 'Can open the project and edit, recompute and download data',
  },
  {
    role: 'reader',
    title: roleTitleMap.reader,
    description: 'Can open the project but cannot edit any property or data',
  },
  {
    role: null,
    title: 'Reset access',
    description: 'Remove this user from the project.',
  },
]

const onRoleChange = (role: ProjectMemberRole | null) => {
  emit('change:role', role)
  isOpen.value = false
}

/** For a11y. ID to give the element that has this item's label */
const LABEL_ID = computed(() => `permissions-dialog-project-member-${props.workspaceMember.id}`)
</script>

<template>
  <li
    class="w-full rounded-lg focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered"
    :class="isOpen && 'bg-background-transparent-hovered'"
    :aria-labelledby="LABEL_ID"
  >
    <PopupMenu
      :open="isOpen"
      :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
      class="flex h-8 w-full items-center gap-2 px-1.5"
      :offset="{ mainAxis: 3 }"
      :disabled="role === 'owner'"
      @change:open="isOpen = $event"
    >
      <template #trigger>
        <AvatarIcon
          :full-text="workspaceMember.fullName"
          shape="circle"
          size="sm"
        />
        <div
          :id="LABEL_ID"
          class="contents"
        >
          <div class="w-max grow text-sm-12px-default text-text">
            {{ workspaceMember.fullName }}
            <template v-if="isCurrentUser"> (You)</template>
          </div>
          <div
            class="flex items-center gap-2"
            :class="role === 'owner' ? 'text-text-subtlest' : 'text-text-subtle'"
          >
            <div>{{ getRoleTitle(role) }}</div>
            <IconSprite icon="chevron-select" />
          </div>
        </div>
      </template>
      <template #dropdown>
        <ListMenu
          :items="LIST_ITEMS.map((data) => ({ id: data.title, data }))"
          :group-by-predicate="(item) => (item.data.role ? 'Role' : 'Reset')"
        >
          <template #item="{ item, active, key, setActiveItem }">
            <ListMenuItem
              v-if="item.data.role"
              :active="active"
              :aria-selected="active"
              @mousemove="setActiveItem(key)"
              @select="onRoleChange(item.data.role)"
            >
              <div class="flex">
                <div class="flex size-5 items-center justify-center">
                  <IconSprite
                    v-if="role === item.data.role"
                    icon="check"
                    class="text-icon-subtle"
                    size="sm"
                  />
                </div>
                <div class="px-1 py-0.5">
                  <div class="text-sm-12px-default text-text">
                    {{ item.data.title }}
                  </div>
                  <div class="text-xs-11px-light text-text-subtle">
                    {{ item.data.description }}
                  </div>
                </div>
              </div>
            </ListMenuItem>
            <ListMenuItem
              v-else
              critical
              :active="active"
              :aria-selected="active"
              icon="trash"
              @mousemove="setActiveItem(key)"
              @select="onRoleChange(item.data.role)"
            >
              <div>
                {{ item.data.title }}
              </div>
            </ListMenuItem>
          </template>
        </ListMenu>
      </template>
    </PopupMenu>
  </li>
</template>
