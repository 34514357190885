<script setup lang="ts">
import { useMeterColor } from '@/shared/useMeterColor'
import { computed } from 'vue'
import LinearProgress from '@/uiKit/LinearProgress.vue'
import type { LimitWithUsage, Plan } from '../Billing/types'
import { formatLimitValue } from '../Billing/formatLimitValue'
import NumberCounter from '@/uiKit/NumberCounter.vue'

const props = defineProps<{ usage: LimitWithUsage; billingPeriod: Plan['billingPeriod'] }>()
const { format: formatNumber } = new Intl.NumberFormat()
const meterColor = useMeterColor({
  current: computed(() => props.usage.limitUsage),
  max: computed(() => props.usage.limitValue),
  defaultColor: 'neutral',
})
const meterLabelId = computed(
  () => `workspace-sidebar-usage-${props.usage.name.replace(/\s/g, '-')}`,
)

const label = computed(() => {
  if (props.usage.name === 'tool_token_usage') {
    if (props.usage.aggregation === 'total') {
      return 'Tokens'
    }

    return props.billingPeriod === 'monthly' ? 'Monthly tokens' : 'Yearly tokens'
  }

  if (props.usage.name === 'field_count') {
    return 'Fields'
  }

  throw new Error('Unsupported usage - must be either token or field usage')
})
</script>

<template>
  <div class="flex flex-col gap-2 p-3">
    <div class="flex justify-between">
      <div :id="meterLabelId">
        <div class="text-xs-11px-light text-text-subtlest">{{ label }}</div>
        <div class="text-sm-12px-default text-text-subtle">
          <NumberCounter :value="formatNumber(usage.limitUsage)" /> /
          {{ formatLimitValue(usage.limitValue, true) }}
        </div>
      </div>
    </div>
    <LinearProgress
      :aria-labelledby="meterLabelId"
      :value="usage.limitUsage"
      :min="0"
      :max="usage.limitValue"
      :color="meterColor"
      role="meter"
    />
  </div>
</template>
