<script setup lang="ts">
import BadgeItem from '@/uiKit/BadgeItem.vue'
import {
  type SingleSelectProperty,
  type MultiSelectProperty,
  type UserSelectProperty,
} from './useProject'
import SelectEditor from './SelectEditor.vue'
import { computed, nextTick, ref, toRef, watch } from 'vue'
import { PropertyType } from '@/backend/types'
import { useTableCellFocus } from './useTableCellFocus'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'

const props = defineProps<{
  value?: string | string[] | null
  property?: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
  isFocused: boolean
  isSelected: boolean
  isModelOutput?: boolean
}>()

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
  (e: 'submit', option: string[] | null): void
  (e: 'blur' | 'focus'): void
}>()

const selectOptions = ref<
  {
    id: string
    data: {
      label: string
      color?: string | null
    }
  }[]
>([])

const valueToColorMap = computed(() =>
  Object.fromEntries(props.property?.config?.options.map((o) => [o.value, o.color]) ?? []),
)

const isMulti = computed(() => props.property?.type === PropertyType.multi_select)
const isUser = computed(() => props.property?.type === PropertyType.user_select)

const badgesWrapper = ref<HTMLDivElement>()

const localValue = ref(props.value ? [props.value].flat() : [])

const workspaceMembersStore = useWorkspaceMembers()

const userValue = (option: { color?: string | null | undefined; value: string }) => {
  const member = workspaceMembersStore.workspaceMembers.find((member) => member.id === option.value)
  return member ? `${member.firstName} ${member.lastName}` : option.value
}

watch(
  () => props.property,
  (newValue) => {
    selectOptions.value = isUser.value
      ? (newValue?.config?.options.map((option) => ({
          id: option.value,
          data: {
            label: userValue(option),
            color: option.color,
          },
        })) ?? [])
      : (newValue?.config?.options.map((option) => ({
          id: option.value,
          data: {
            label: option.value,
            color: option.color,
          },
        })) ?? [])
  },
  { immediate: true },
)

watch(
  () => props.value,
  () => {
    localValue.value = props.value ? [props.value].flat() : []
  },
)

watch(
  () => props.isFocused,
  async (isFocused, wasFocused) => {
    await nextTick()
    if (isFocused && !wasFocused) {
      badgesWrapper.value?.focus()
    }
  },
  { immediate: true },
)

/**
 * When in edit mode, typing a character should focus the select editor and
 * populate the search input with the typed character.
 */
const onKeydown = (e: KeyboardEvent) => {
  // This is the only way I could find to differentiate between
  // 1. Keys that should be taken as input values for the text cell
  // 2. Keys that should be used for navigation or other browser commands
  // Hopefully we can come up with a better way.
  const shouldOverwriteValue =
    e.key.length === 1 && e.key !== ' ' && !e.ctrlKey && !e.metaKey && !e.altKey
  if (shouldOverwriteValue) {
    // The event will continue propagating, so the keypress will be registered by the
    // browser against the contenteditable div
    emit('focus')
  }

  const shouldDeleteValue = e.key === 'Backspace' || e.key === 'Delete'
  if (shouldDeleteValue) {
    localValue.value = []
    emit('submit', null)
  }
}

const root = ref<HTMLElement>()
useTableCellFocus({
  cell: root,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const badgeLabel = (v: string) => {
  if (isUser.value) {
    const option = selectOptions.value.find((o) => o.id === v)
    return option ? option.data.label : v
  }
  return v
}
</script>

<template>
  <div
    ref="root"
    class="size-full min-w-0 overflow-hidden outline-none"
    @keydown="onKeydown"
  >
    <SelectEditor
      v-if="isFocused || isSelected"
      :is-focused="isFocused"
      :is-selected="isSelected"
      :is-multi="isMulti"
      :is-user="isUser"
      :value="localValue"
      :options="selectOptions"
      :value-to-color-map="valueToColorMap"
      :is-model-output="isModelOutput"
      class="absolute left-0 top-0 z-10 pr-2"
      @click="$emit('click', $event)"
      @submit="
        (value) => {
          $emit('submit', value)
          // optimistic update in both cases
          localValue = value
        }
      "
    />

    <div
      v-else
      ref="badgesWrapper"
      role="button"
      tabindex="0"
      class="p-1 pr-0"
      @click="$emit('click', $event)"
    >
      <div class="size-full overflow-hidden rounded-r-corner-6">
        <div
          class="flex size-full cursor-pointer flex-row items-center gap-1 overflow-hidden focus:outline-none"
        >
          <BadgeItem
            v-for="v in localValue"
            :key="v"
            :leading-icon="isUser ? 'user-fill' : undefined"
            :label="badgeLabel(v)"
            size="sm"
            :variant="isUser ? 'blue' : 'warning'"
            class="whitespace-nowrap"
            :rainbow-color="valueToColorMap[v]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
