import { createEventHook } from '@vueuse/core'
import { ANALYTICS_EVENT, useAnalytics } from './useAnalytics'

const clickClaim = createEventHook<string>()
const clickSource = createEventHook<string>()

type UseGroundingInteractionsArgs = {
  onClaimClick?: (n: string) => void
  onSourceClick?: (n: string) => void
}

export function useGroundingInteractions({
  onClaimClick,
  onSourceClick,
}: UseGroundingInteractionsArgs = {}) {
  const { captureAnalyticsEvent } = useAnalytics()

  if (onClaimClick) {
    clickClaim.on(onClaimClick)
  }
  if (onSourceClick) {
    clickSource.on(onSourceClick)
  }

  return {
    clickClaim: (n: string) => {
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_CLAIM_CLICKED)
      return clickClaim.trigger(n)
    },
    clickSource: (n: string) => {
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_BOX_CLICKED)
      return clickSource.trigger(n)
    },
  }
}
