import { watch, type Ref } from 'vue'

type Params = {
  isFocused: Ref<boolean>
  isSelected: Ref<boolean>
  cell: Ref<HTMLElement | undefined | null>
}

/**
 * Composable function used by every type of cell to focus the element (browser focus) when its selected/focused state (Pinia) changes.
 */
export const useTableCellFocus = ({ cell, isFocused, isSelected }: Params) => {
  // This is simple - when a cell becomes selected we should focus it, and when it becomes unselected we should blur it.
  watch(
    () => isSelected.value,
    (isSelected) => {
      if (isSelected) {
        cell.value?.focus()
      } else {
        cell.value?.blur()
      }
    },
  )

  /**
   * In some cases, when a cell is 'focused' and in edit mode, the browser is actually focusing
   * on an element that has been teleported outside of the table. This watcher
   * ensures that the table cell is focused when the user escapes out of the
   * teleported edit mode 'cell'.
   */
  watch(
    () => [isFocused.value, isSelected.value],
    ([isFocused, isSelected]) => {
      if (!isFocused && isSelected) {
        cell.value?.focus()
      }
    },
  )
}
