import { listProjects } from '@/backend/listProjects'
import { useVerticalVirtualList } from '@/sharedComposables/useVerticalVirtualList'
import { debouncedWatch } from '@vueuse/core'
import { ref, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { serializeProperty } from '../Project/useProject'
import { useProjects, type Project } from './useProjects'

/**
 * We paginate projects in the sidebar to improve performance by not loading
 * hundreds of projects at once. This composable handles:
 * - Loading projects from the server as the user scrolls
 * - Updating the Pinia store with new projects as they are loaded
 * - Calculating which projects to render based on the scroll position
 *
 * @returns {Object} returnValue
 * @returns {Object} returnValue.containerProps - Props to bind to the container element
 * @returns {Object} returnValue.wrapperProps - Props to bind to the wrapper element
 * @returns {Ref<Project[]>} returnValue.sidebarProjects - Projects to render
 */
export const usePaginateProjects = (workspaceId: Ref<string>) => {
  const alphabeticalProjects = ref<(Project | undefined)[]>([])
  const router = useRouter()
  const loadingState = ref<'idle' | 'loading' | 'loaded'>('idle')

  /**
   * Load a range of projects from the server and update the paginated state
   */
  const loadRange = async ({ start, end }: { start: number; end: number }) => {
    const projectsResponse = await listProjects(workspaceId.value, {
      order_by: ['name'],
      limit: end - start,
      offset: start,
      only_parents: true,
    })
    if (projectsResponse.ok) {
      const projectsFromServer: Project[] = projectsResponse.data.data.map((p) => ({
        id: p.id,
        name: p.name,
        workspaceId: p.workspace_id,
        propertyCount: p.properties.length,
        parentProperty: p.parent_property ? serializeProperty(p.parent_property) : undefined,
        coverImageUrls: p.cover_image_urls,
      }))

      const totalCountFromServer =
        projectsResponse.data.metadata.total_count ?? projectsFromServer.length
      const newProjects =
        totalCountFromServer === alphabeticalProjects.value.length
          ? alphabeticalProjects.value.slice()
          : Array.from<Project>({ length: totalCountFromServer })
      newProjects.splice(start, projectsFromServer.length, ...projectsFromServer)
      alphabeticalProjects.value = newProjects
    } else if (projectsResponse.error.code === 'not_found') {
      router.push({
        name: 'ErrorPage',
        query: {
          title: ' Workspace not found',
          message: 'We could not find the page you’re looking for.',
        },
      })
    }
  }

  const projectsStore = useProjects()

  // Clear the projects and load new projects when the workspace changes
  watch(
    () => workspaceId.value,
    async () => {
      projectsStore.setProjects([])
      loadingState.value = 'loading'
      await loadRange({ start: 0, end: 50 })
      loadingState.value = 'loaded'
    },
    { immediate: true },
  )

  /**
   * Watch as projects are added or removed from the store and update the paginated state accordingly.
   */
  watch(
    () => projectsStore.mainProjects,
    async (newProjects, oldProjects) => {
      const hasRemovedProjects = oldProjects.length > newProjects.length
      const newIds = newProjects.map((p) => p.id)
      const oldIds = oldProjects.map((p) => p?.id)
      if (hasRemovedProjects) {
        // If a project is removed from the store, remove it from the paginated state
        const removedIds = oldIds.filter((id) => !newIds.includes(id))
        removedIds.forEach((id) => {
          alphabeticalProjects.value = alphabeticalProjects.value.filter((p) => p?.id !== id)
        })

        return
      }

      /**
       * If a project has been added to the Pinia store and we already have it here
       * then there is nothing to do.
       */
      const addedIds = newIds.filter((id) => !oldIds.includes(id))
      if (
        addedIds.length > 0 &&
        addedIds.every((id) => alphabeticalProjects.value.some((p) => p?.id === id))
      ) {
        return
      }

      /**
       * If we are here then a project has either been added or updated.
       * In both cases:
       * 1. If we have loaded all projects we are able to reset the
       *    alphabetical list by sorting the store projects
       * 2. If we haven't loaded all projects, then we need to load
       *    the current range from the backend (because we don't know
       *    which position in the list the new project should have)
       */
      if (alphabeticalProjects.value.every(Boolean)) {
        // If all projects are loaded, we can just sort the projects
        // from the store
        alphabeticalProjects.value = projectsStore.mainProjects.toSorted((a, b) =>
          (a.name || 'Untitled Project').localeCompare(b.name || 'Untitled Project'),
        )
        return
      }

      // There are unloaded projects, so we need to load the current
      // 'page' of alphabetically sorted projects from the backend.
      await loadRange(rangeState.value)
    },
  )

  const {
    list,
    containerProps,
    wrapperProps,
    state: rangeState,
  } = useVerticalVirtualList(alphabeticalProjects, {
    itemHeight: 28,
    overscan: 10,
  })
  // When the user scrolls, fetch new projects
  debouncedWatch(
    () => rangeState.value,
    async (newRange) => {
      // Don't reload projects at the start of the range that are already loaded
      let start: number | undefined
      for (let i = newRange.start; i < newRange.end; i++) {
        if (!alphabeticalProjects.value[i]) {
          start = i
          break
        }
      }

      // Don't reload projects at the end of the range that are already loaded
      let end: number | undefined = newRange.end
      for (let i = newRange.end; i >= newRange.start; i--) {
        if (!projectsStore.projects[i]) {
          end = i
          break
        }
      }

      if (start === undefined || end === undefined || start === end) {
        // If we are in this block, then all items in the range are already loaded
        // and we don't need to fetch more.
        return
      }
      await loadRange({ start, end })
    },
    { debounce: 100 },
  )

  // When we fetch new paginated projects, add them to the store
  watch(
    () => alphabeticalProjects.value,
    (projects) => {
      const definedProjects = projects.filter((p): p is Project => !!p)
      const projectIds = definedProjects.map((p) => p.id)
      const storeIds = projectsStore.projects.map((p) => p.id)
      if (projectIds.every((id, i) => id === storeIds[i])) {
        return
      }
      projectsStore.addProjects(definedProjects)
    },
  )

  return {
    sidebarProjects: list,
    containerProps,
    wrapperProps,
    loadingState,
  }
}
