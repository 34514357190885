import { ref } from 'vue'
import type { UpdatedProperty } from './PropertyEditMenu.vue'
import { usePropertySidebarIntegration } from './usePropertySidebarIntegration'

/**
 * This composable provides logic, state and event handlers for the popover menu
 * used to configure properties.
 */
export const usePropertyDropdown = () => {
  const menuIsOpen = ref(false)
  const {
    deleteProperty,
    deletePropertyConfirmationOpen,
    deselectProperty,
    hideProperty,
    reprocessColumn,
    saveProperty,
  } = usePropertySidebarIntegration()

  const onCloseMenu = () => {
    deselectProperty()
  }

  /**
   * Called when saving changes to a property.
   */
  const onUpdate = async ({
    updatedProperty,
    keepMenuOpen,
  }: {
    updatedProperty: UpdatedProperty
    keepMenuOpen?: boolean
  }) => {
    const isPropertySaved = await saveProperty(updatedProperty, 'menu')

    if (!keepMenuOpen && isPropertySaved) {
      menuIsOpen.value = false
      deselectProperty()
    }
  }

  const onReprocessColumn = async (updatedProperty: UpdatedProperty) => {
    const isPropertySaved = await saveProperty(updatedProperty, 'menu')
    if (!isPropertySaved) return // leave the menu open if the save failed
    await reprocessColumn()
    deselectProperty()
    menuIsOpen.value = false
  }

  const openDeleteDialog = () => {
    menuIsOpen.value = false
    deletePropertyConfirmationOpen.value = true
  }

  const triggerRef = ref<HTMLElement>()
  // Close the menu by deselecting the property when a user clicks outside of the menu
  const onClickOutside = (e: PointerEvent) => {
    deselectProperty()

    // If the user is clicking on the trigger when the menu is open, then we want to close
    // the menu and stop propagation so that the trigger doesn't open it again.
    const isClickingTrigger =
      e.target instanceof HTMLElement &&
      triggerRef.value instanceof HTMLElement &&
      triggerRef.value?.contains(e.target)
    if (isClickingTrigger) {
      e.stopPropagation()
    }
  }

  return {
    deleteProperty,
    deletePropertyConfirmationOpen,
    hideProperty,
    menuIsOpen,
    onClickOutside,
    onCloseMenu,
    onReprocessColumn,
    onUpdate,
    openDeleteDialog,
    triggerRef,
  }
}
