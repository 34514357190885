<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import { useProjects } from '@/modules/Projects/useProjects'
import { computed, ref } from 'vue'
import PermissionsDialogInviteForm from './PermissionsDialogInviteForm.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import {
  useWorkspaceMembers,
  type WorkspaceMember,
} from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import type { ProjectMemberRole } from './types'
import { useProjectPermissionsStore } from './projectPermissionsStore'
import PermissionsDialogProjectMember from './PermissionsDialogProjectMember.vue'
import type { ResourceRole } from '@/backend/types'
import { useUser } from '@/modules/IdentityAndAccess/useUser'

const props = defineProps<{
  workspaceId: string
  open: boolean
  rootProjectId: string
}>()

defineEmits<{
  (e: 'close'): void
}>()

/** For a11y - HTML ID for the dialog's label */
const DIALOG_LABEL_ID = 'permissions-dialog-label'

const projectsStore = useProjects()
const rootProject = computed(() => projectsStore.projects.find((p) => p.id === props.rootProjectId))
const coverImageUrl = computed(() => rootProject.value?.coverImageUrls.high)

const urlStart = computed(() => `${window.location.host}/${props.workspaceId}/projects/`)
const urlEnd = computed(() => `/${props.rootProjectId}`)

/**
 * Text to render on the copy link button. The text changes to 'Copied' for a short
 * time after the link is copied to the clipboard.
 */
const copyButtonText = ref<'Copy link' | 'Copied'>('Copy link')

/** Copy a link to the current project to the clipboard */
const onCopyLink = () => {
  const url = `${window.location.protocol}//${urlStart.value}${props.rootProjectId}`
  navigator.clipboard.writeText(url)
  copyButtonText.value = 'Copied'
  setTimeout(() => {
    copyButtonText.value = 'Copy link'
  }, 2000)
}

const workspaceMemberStore = useWorkspaceMembers()
const projectPermissionsStore = useProjectPermissionsStore()
const onInviteUsers = async ({
  existingUsers,
  role,
}: {
  existingUsers: WorkspaceMember[]
  role: ProjectMemberRole
  newUserEmails: string[]
}) => {
  await Promise.all(
    existingUsers.map((user) =>
      projectPermissionsStore.setProjectRole({
        projectId: props.rootProjectId,
        role,
        userId: user.id,
        workspaceId: props.workspaceId,
      }),
    ),
  )
}

const userStore = useUser()
const projectMembers = computed(() =>
  Object.entries(projectPermissionsStore.workspaceMemberRoles)
    .map(([userId, role]) => ({
      ...workspaceMemberStore.getMember(userId),
      projectRole: role,
      isCurrentUser: userId === userStore.user?.id,
    }))
    .filter(({ projectRole }) => projectRole),
)

const workspaceMembers = computed(() =>
  workspaceMemberStore.workspaceMembers.map((member) => ({
    member,
    projectRole: projectPermissionsStore.workspaceMemberRoles[member.id],
  })),
)

const onChangeRole = (userId: string, role: ResourceRole | null) => {
  if (role === null || role === 'editor' || role === 'reader') {
    projectPermissionsStore.setProjectRole({
      projectId: props.rootProjectId,
      userId,
      workspaceId: props.workspaceId,
      role,
    })
  }
}
</script>

<template>
  <ModalDialog
    :open="open"
    placement="right"
    class="overflow-hidden"
    :outline="false"
    :aria-labelledby="DIALOG_LABEL_ID"
    @close="$emit('close')"
  >
    <div class="h-screen p-2">
      <div
        :id="PERMISSIONS_DIALOG_ID"
        class="relative flex h-full w-[400px] flex-col overflow-hidden rounded-corner-10 border-0 bg-surface-tertiary-persist shadow-lg"
      >
        <div class="relative flex items-stretch justify-start gap-4 p-4">
          <div
            class="size-20 min-h-20 min-w-20 rounded-corner-10 bg-gradient-to-b from-surface-primary-persist via-surface-primary-persist to-background-gray-sunken"
          >
            <ObjectURLImage
              v-if="coverImageUrl"
              class="size-full rounded-corner-10 object-cover"
              :url="coverImageUrl"
              :loading="false"
            />
          </div>
          <div class="flex min-w-0 flex-col items-start justify-between">
            <div class="max-w-full">
              <p
                :id="DIALOG_LABEL_ID"
                class="text-md-13px-default text-text"
              >
                Share this project
              </p>
              <div class="flex max-w-full truncate text-xs-11px-light text-text-subtlest">
                <div class="shrink truncate">{{ urlStart }}</div>
                <div class="grow">{{ urlEnd }}</div>
              </div>
            </div>
            <DarwinButton
              rounded
              variant="outline"
              class="w-[88px] max-w-[88px]"
              size="md"
              @click="onCopyLink"
              >{{ copyButtonText }}</DarwinButton
            >
          </div>
          <IconButton
            size="lg"
            variant="transparent"
            class="absolute right-2 top-2 text-text-subtle"
            icon="close"
            @click="$emit('close')"
          />
        </div>
        <div class="flex grow flex-col gap-4 rounded-lg bg-surface-primary p-4">
          <PermissionsDialogInviteForm
            :workspace-members="workspaceMembers"
            @invite="onInviteUsers"
          />
          <ul>
            <PermissionsDialogProjectMember
              v-for="member in projectMembers"
              :key="member.id"
              :workspace-member="member"
              :is-current-user="member.isCurrentUser"
              :role="member.projectRole"
              @change:role="onChangeRole(member.id, $event)"
            >
              {{ member.fullName }} - {{ member.projectRole }}
            </PermissionsDialogProjectMember>
          </ul>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
