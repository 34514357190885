import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useDebounceFn, useElementBounding, useElementSize, useStorage } from '@vueuse/core'
import { computed, ref, watch, type ComputedRef, type Ref } from 'vue'

export type ScaleType = number | 'fit'

/**
 * Provides functionality to allow a user to scroll into a container 'naturally', where
 * the element at the cursor position remains under the cursor after the zoom level changes.
 *
 * The composable returns:
 * - `scrollContainer` - a ref to the container element that should be scrolled
 * - `scrollContainerChild` - a ref to the child element that should be zoomed
 * - `scale` - the current scale level
 * - `onZoom` - a function that should be called when a wheel event is detected
 */
export const useNaturalZoom = (storageKey: ComputedRef<string>, originalWidth?: Ref<number>) => {
  const scrollContainer = ref<HTMLElement | null>(null)
  const scrollContainerChild = ref<HTMLElement | null>(null)

  const {
    height: containerHeight,
    top: containerTop,
    left: containerLeft,
    width: containerWidth,
  } = useElementBounding(scrollContainer)
  const { height: childHeight, width: childWidth } = useElementSize(scrollContainerChild)

  const scale = useStorage<ScaleType>(storageKey.value, 'fit', undefined, {
    // We need to do this serialization magic because the value is stored as a string
    // and in previous versions of the app probably "null" was written into the storage
    serializer: {
      read: (v: string) => {
        if (v === 'fit' || (!isNaN(Number(v)) && v !== null)) {
          return v === 'fit' ? 'fit' : Number(v)
        }
        return 'fit'
      },
      write: (v: ScaleType) => String(v),
    },
  })

  const fitAsAbsolute = computed<number>(() => {
    if (!originalWidth) {
      return 0.99
    }

    return containerWidth.value / originalWidth.value
  })

  /**
   * When the user zooms in or out, we use this ref to store the coordinates of their zoom event
   * so that we can keep the element under the cursor in the same position after the zoom. This
   * ref will be null if the user has not zoomed in or out with a scroll event, and instead
   * uses the UI controls to zoom.
   */
  const scrollEventProportions = ref<{
    container: { x: number; y: number }
    child: { x: number; y: number }
  } | null>(null)

  /**
   * Set the scale when zooming in/out with a scroll event
   */
  const onZoom = useDebounceFn((event: WheelEvent) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)

    scrollEventProportions.value = {
      container: {
        // What proportion across the container width was the scroll event?
        x: (event.clientX - containerLeft.value) / containerWidth.value,
        // What proportion down the container height was the scroll event?
        y: (event.clientY - containerTop.value) / containerHeight.value,
      },
      child: {
        // What proportion across the child width was the scroll event?
        x:
          (event.clientX - containerLeft.value + scrollContainer.value.scrollLeft) /
          childWidth.value,
        // What proportion down the child height was the scroll event?
        y:
          (event.clientY - containerTop.value + scrollContainer.value.scrollTop) /
          childHeight.value,
      },
    }

    if (scale.value === 'fit') {
      // If scale is fit, then when scrolling in/out we want to jump to the next
      // largest/smallest multiple of 0.25
      if (event.deltaY < 0) {
        const nextScaleUp = Math.ceil(fitAsAbsolute.value * 4) / 4
        scale.value = nextScaleUp
      } else {
        const nextScaleDown = Math.floor(fitAsAbsolute.value * 4) / 4
        scale.value = nextScaleDown
      }
    } else {
      if (event.deltaY > 0) {
        scale.value = Math.max(scale.value - 0.25, 0.25)
      } else {
        scale.value = Math.min(scale.value + 0.25, 2)
      }
    }
  })

  /**
   * Set the scale explicitly, without a scroll event (e.g. when interacting
   * with the scale controls in the UI)
   */
  const setScale = (newScale: ScaleType) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)

    // Set the target scroll position to the same position in the child,
    // and the center of the container
    scrollEventProportions.value = {
      container: {
        x: 0.5,
        y: 0.5,
      },
      child: {
        x: 0.5,
        y:
          (scrollContainer.value.scrollTop + containerHeight.value / 2) /
          scrollContainerChild.value.clientHeight,
      },
    }

    scale.value = newScale
  }

  /**
   * This watcher will run after the zoom level has changed and the child element's
   * height has been updated. It will then try and scroll the container to the
   * same position as it was before the zoom level changed.
   */
  watch(childHeight, async (newChildHeight) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)
    if (scrollEventProportions.value === null) {
      return
    }

    const newChildY = newChildHeight * scrollEventProportions.value.child.y
    const newContainerY = containerHeight.value * scrollEventProportions.value.container.y

    const newChildX = childWidth.value * scrollEventProportions.value.child.x
    const newContainerX = containerWidth.value * scrollEventProportions.value.container.x

    scrollContainer.value.scrollTop = newChildY - newContainerY
    scrollContainer.value.scrollLeft = newChildX - newContainerX

    scrollEventProportions.value = null
  })

  return { onZoom, scrollContainer, scrollContainerChild, scale, setScale }
}
