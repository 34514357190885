import { confirmFieldFileUpload } from '@/backend/confirmFieldFileUpload'
import { startFieldFileUpload } from '@/backend/startFieldFileUpload'
import { SUPPORTED_FILE_EXTENSIONS, SUPPORTED_MIME_TYPES } from '@/backend/types'
import { uploadFile } from '@/backend/uploadFile'
import { toast } from '@/shared/toast'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useFileDialog } from '@vueuse/core'
import { watch, type Ref } from 'vue'
import { useLibraryStore, type LibraryItem } from './libraryStore'
import { useLibraryBackend } from './useLibraryBackend'

/**
 * Provides a function that will open a file upload dialog that will upload
 * a file to the library.
 */
export const useUploadLibraryFile = (workspaceId: Ref<string>) => {
  const { captureAnalyticsEvent } = useAnalytics()
  const libraryStore = useLibraryStore()
  const { getLibraryItemToCreateId } = useLibraryBackend()

  const startUpload = async (file: File) => {
    if (!(SUPPORTED_MIME_TYPES as string[]).includes(file.type)) {
      toast.warning(
        [
          'The file you tried to upload is not supported, so the upload was skipped.',
          'We only support the following file extensions:',
          SUPPORTED_FILE_EXTENSIONS.join(', ').concat('.'),
        ].join(' '),
      )
      return
    }

    assertIsNotNullOrUndefined(libraryStore.library)

    const optimisticItem: LibraryItem = {
      id: crypto.randomUUID(),
      fileUrl: null,
      filename: file.name,
      name: file.name,
      updatedAt: new Date().toISOString(),
      status: 'uploading',
      type: 'file',
      inputReference: [],
    }
    libraryStore.setItem(optimisticItem)

    let libraryItemId: string
    try {
      libraryItemId = await getLibraryItemToCreateId({
        libraryId: libraryStore.library.id,
        workspaceId: workspaceId.value,
      })
    } catch (error) {
      libraryStore.libraryItems = libraryStore.libraryItems.filter(
        (i) => i.id !== optimisticItem.id,
      )
      throw error
    }

    // Now that we have an ID, we can update the item with the correct ID
    const optimisticIndex = libraryStore.libraryItems.findIndex((i) => i.id === optimisticItem.id)
    libraryStore.libraryItems = libraryStore.libraryItems.with(optimisticIndex, {
      ...optimisticItem,
      id: libraryItemId,
    })

    try {
      const result = await startFieldFileUpload(
        workspaceId.value,
        libraryStore.library.id,
        libraryItemId,
        libraryStore.library.fileProperty.id,
        file.name,
      )

      if (!result.ok) {
        toast.error('Failed to fetch url to upload to')
        return
      }

      const uploadResult = await uploadFile(result.data.file_upload_url, file, (progress) => {
        libraryStore.uploadProgressMap[libraryItemId] = progress
      })

      if (!uploadResult.ok) {
        toast.error('Failed to upload file to S3')
        return
      }

      const confirmResult = await confirmFieldFileUpload(result.data.confirm_upload_url)
      if (!confirmResult.ok) {
        toast.error('Failed to confirm upload of file')
        return
      }

      const fileValue = confirmResult.data
      if (!('original_filename' in fileValue.manual_value)) {
        throw new Error('Property is not a file type')
      }
    } catch (error) {
      libraryStore.libraryItems = libraryStore.libraryItems.filter((i) => i.id !== libraryItemId)
      throw error
    } finally {
      captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_UPLOADED_FILE)
    }
  }

  const { open: onClickUpload, files: dialogFiles } = useFileDialog({ multiple: false })
  watch(dialogFiles, () => dialogFiles.value?.[0] && startUpload(dialogFiles.value[0]))

  return { onClickUpload }
}
