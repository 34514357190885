import type { components } from '@/api'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { FileField, TextField } from './useProject'

type BackendGrounding = components['schemas']['Projects.Grounding.Grounding']
type BackendSource = BackendGrounding['sources'][number]
type BackendClaim = BackendGrounding['claims'][number]

export type Source = {
  boundingBoxes: BackendSource['bounding_boxes']
  id: string
  propertyId: BackendSource['property_id']
}

type ClaimWithSources = {
  location: BackendClaim['location']
  sources: Source[]
}

type ClaimWithoutSources = {
  location: BackendClaim['location']
  id: string
}

export type Claim = ClaimWithSources | ClaimWithoutSources

/**
 * Holds all the state related to grounding claims and sources for a given
 * field.
 */
export const useGroundingStore = defineStore('grounding', () => {
  /**
   * The field that contains grounding claims and sources.
   */
  const field = ref<TextField | null>(null)

  /**
   * All fields that are inputs to the grounded field, including fields
   * that have no sources.
   */
  const inputFields = ref<FileField[]>([])

  /**
   * Property ID for the input field that is currently active.
   */
  const activeInputId = ref<string | null>(null)

  /**
   * All claims for the current field. Claims and sources come in from the backend
   * in separate arrays, but they are linked in a 0-many relationship. This computed
   * property combines the two arrays into a single array of claims, where each claim
   * is linked to its sources.
   */
  const claims = computed<Claim[]>(() => {
    if (!field.value?.grounding) {
      return []
    }

    // The backend assigns indices to sources, but we still want to render claims
    // with no sources. This means we need to track and increment our own indices.
    let nextSourceIndex = 1

    const accumulated: Claim[] = []

    const orderedClaims = field.value.grounding.claims.toSorted(
      (a, b) => a.location.offset - b.location.offset,
    )

    const sourceMap: Partial<Record<string, BackendSource>> = Object.fromEntries(
      field.value.grounding.sources.map((s) => [s.index, s]),
    )

    for (const claim of orderedClaims) {
      if (claim.source_indices.length === 0) {
        accumulated.push({
          location: claim.location,
          id: nextSourceIndex.toString().padStart(2, '0'),
        })
        nextSourceIndex++
      } else {
        const accumulatedSources: ClaimWithSources['sources'] = []
        for (const sourceIndex of claim.source_indices) {
          const matchingSource = sourceMap[sourceIndex]
          assertIsNotNullOrUndefined(matchingSource, 'No source found for the given index.')

          accumulatedSources.push({
            boundingBoxes: matchingSource.bounding_boxes,
            propertyId: matchingSource.property_id,
            id: nextSourceIndex.toString().padStart(2, '0'),
          })
          nextSourceIndex++
        }

        accumulated.push({
          location: claim.location,
          sources: accumulatedSources,
        })
      }
    }

    return accumulated
  })

  /** All sources for the current field */
  const sources = computed<Source[]>(() =>
    claims.value.flatMap((claim) => ('sources' in claim ? claim.sources : [])),
  )

  /**
   * Get all sources that are associated with a given property.
   */
  const getSourcesForProperty = (propertyId: string) => {
    return sources.value.filter((source) => source.propertyId === propertyId)
  }

  const activeInputField = computed(() =>
    inputFields.value.find((field) => field.propertyId === activeInputId.value),
  )

  const activeInputSources = computed(() =>
    activeInputId.value ? getSourcesForProperty(activeInputId.value) : [],
  )

  const clickClaim = (sourceId: string) => {
    const source = sources.value.find((s) => s.id === sourceId)
    assertIsNotNullOrUndefined(source, 'No source found for the given index.')

    activeInputId.value = source.propertyId
  }

  const reset = () => {
    field.value = null
    inputFields.value = []
    activeInputId.value = null
  }

  return {
    field,
    inputFields,
    activeInputField,

    sources,
    activeInputSources,
    getSourcesForProperty,

    claims,
    activeInputId,

    clickClaim,

    reset,
  }
})
