import { starField } from '@/backend/starField'
import type { Entity, Field, Property } from '@/modules/Project/useProject'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { computed, type ComputedRef } from 'vue'
import { useProfileImage } from '../WorkspaceSettings/useProfileImage'
import { useProject } from './useProject'

export const useProjectTooltip = ({
  field,
  entity,
  propertyId,
}: {
  field: ComputedRef<Field | undefined>
  entity: ComputedRef<Entity | undefined>
  propertyId: ComputedRef<Property['id'] | undefined>
}) => {
  const workspaceMembersStore = useWorkspaceMembers()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  const entityId = computed(() => entity.value?.id || null)
  const isCorrectedModelOutput = computed(() => {
    return field.value?.manualValue !== null && field.value?.toolValue !== null
  })

  const userProfileImage = useProfileImage(computed(() => field?.value?.manualValueUpdatedBy ?? ''))

  const lastCorrectedBy = computed(() => {
    const member = workspaceMembersStore.workspaceMembers.find(
      (i) => i.id === field?.value?.manualValueUpdatedBy,
    )
    if (!member) {
      return
    }

    return {
      name:
        member.firstName && member.lastName
          ? `${member.firstName} ${member.lastName}`
          : member.email,
      avatarUrl: userProfileImage.value,
    }
  })

  const onStarField = async () => {
    if (!field.value) {
      return
    }
    const newGroundTruth = field.value ? !field.value.groundTruth : true

    if (
      !workspacesStore.currentWorkspace ||
      !projectStore.projectId ||
      !entityId.value ||
      !propertyId.value
    ) {
      return
    }

    const response = await starField({
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      entityId: entityId.value,
      propertyId: propertyId.value,
      groundTruth: newGroundTruth,
    })

    projectStore.updateField({ ...field.value, groundTruth: newGroundTruth })

    if (response.ok) {
      return
    }

    if (response.error) {
      projectStore.updateField({ ...field.value, groundTruth: !newGroundTruth })
      throw new Error(response.error.message)
    }
  }

  return {
    isCorrectedModelOutput,
    lastCorrectedBy,
    onStarField,
  }
}
