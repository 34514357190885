import type { operations } from '@/api'
import { post } from './client'

export type ExportProjectPath = `/api/workspaces/${string}/projects/${string}/exports`
export type ExportProjectResponse =
  operations['export-create']['responses']['200']['content']['application/json']

export type ExportProjectRequest = Exclude<
  operations['export-create']['requestBody'],
  undefined
>['content']['application/json']

export const exportProject = (workspaceId: string, projectId: string, name: string) =>
  post<ExportProjectRequest, ExportProjectResponse, ExportProjectPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/exports`,
    { name },
  )
