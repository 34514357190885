<script lang="ts" setup>
/**
 * Intends to be the eventual final component which we use to render a select list,
 * which would bve a styled replacement for the <select> element.
 *
 * Currently is just logic glue around a trigger and a dropdown menu.
 *
 * When finalizing this component, be sure to read documentaiton on floating-ui
 * to figure out things like auto-placement and sizing, etc.
 */
import { onKeyStroke } from '@vueuse/core'
import { ref } from 'vue'
import PopupMenu from './PopupMenu.vue'
import type { OffsetOptions } from '@floating-ui/vue'

defineProps<{
  offset?: OffsetOptions
  matchTargetWidth?: boolean
  /**
   * The element to teleport the popup to. Defaults to 'body'. Can be used to teleport
   * the popup to a modal dialog, so that the modal's onClickOutside handler doesn't
   * trigger when clicking on the popup.
   */
  teleportTo?: string
}>()

const open = ref(false)

defineExpose({ open })

/**
 * Callback which we pass to the slot so that the parent component may
 * close the dropdown.
 *
 * Possibly not the cleanest approach, but allows us to fully give control to the
 * parent component in what to render as the menu.
 *
 * Use it when passing in the template for the menu:
 *
 * ```
 * <SelectDropdown>
 *   <template #items="{ close }">
 *     <ListMenu>
 *       <ListMenuItem
 *         v-for="item in items"
 *         :key="item.id"
 *         :label="item.name"
 *         @click="() => {
 *           selectedItem = item
 *           close()
 *         }"
 *       />
 *     </ListMenu>
 *   </template>
 * </SelectDropdown>
 * ```
 */
const close = () => (open.value = false)

onKeyStroke('Escape', (e: KeyboardEvent) => {
  if (!open.value) return
  open.value = false
  e.stopImmediatePropagation()
})
</script>

<template>
  <PopupMenu
    :open="open"
    :placement="'bottom-start'"
    :offset="offset"
    :teleport-to="teleportTo"
    :match-target-width="matchTargetWidth"
    @change:open="open = $event"
  >
    <template #trigger>
      <slot
        name="trigger"
        :is-open="open"
      />
    </template>
    <template #dropdown>
      <slot
        name="dropdown"
        :close="close"
      />
    </template>
  </PopupMenu>
</template>
