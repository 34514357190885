<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { TOOL_PROVIDER_LABELS } from '@/modules/WorkspaceSettings/propertyConfig'
import TextField from '@/uiKit/TextField.vue'
import { computed, ref, watch } from 'vue'
import WorkspaceSettingsAiModelsProviderIcon from './WorkspaceSettingsAiModelsProviderIcon.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import AiModelsAnimation from './AiModelsAnimation.vue'
import TextArea from '@/uiKit/TextArea.vue'
import { ToolProvider } from '@/backend/types'

const props = defineProps<{
  provider: ToolProvider
  open: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'create', data: { secretValue: string; secretProjectId: string }): void
}>()

const providerName = computed(() => TOOL_PROVIDER_LABELS[props.provider])

const labelId = computed(() => `${props.provider}-config-dialog`)

const secretValue = ref('')
const secretProjectId = ref('')

const createProviderKey = async () => {
  emit('create', { secretValue: secretValue.value, secretProjectId: secretProjectId.value })
}

watch(
  () => props.open,
  (open) => {
    // The dialog only opens when the user is adding new credentials (users
    // can't see existing credentials because they're secret. So we can
    // clear any existing values when the dialog is closed. Otherwise
    // the user would see the previous values when they open the dialog again.
    if (!open) {
      secretValue.value = ''
      secretProjectId.value = ''
    }
  },
)
</script>

<template>
  <ModalDialog
    :open="open"
    disable-focus-trap
    :aria-labelledby="labelId"
    @close="$emit('close')"
  >
    <div class="relative w-[480px] overflow-hidden rounded-xl bg-surface-tertiary">
      <IconButton
        variant="transparent"
        size="md"
        icon="close"
        rounded
        class="absolute right-2 top-2"
        aria-label="Close dialog"
        @click="$emit('close')"
      />
      <div
        class="flex flex-col items-center gap-8 rounded-xl bg-surface-popover pt-[88px] [&>*]:w-[340px]"
      >
        <div class="flex items-center justify-center gap-2">
          <WorkspaceSettingsAiModelsProviderIcon :provider="provider" />
          <AiModelsAnimation />
          <WorkspaceSettingsAiModelsProviderIcon :provider="ToolProvider.internal" />
        </div>
        <div>
          <h2
            :id="labelId"
            class="mb-2.5 text-center text-xl-18px-bold text-text"
          >
            Connect your {{ providerName }} API Key
          </h2>
          <p class="text-center text-sm-12px-default text-text-subtle">
            By connecting your API Key, usage for every model by {{ providerName }} will now be
            provided by your account instead of your Go tokens allowance.
          </p>
        </div>
        <form
          class="flex flex-col gap-2"
          @submit.prevent=""
        >
          <template v-if="provider === 'google_ai'">
            <TextField
              autofocus
              label="Project ID"
              size="md"
              placeholder="Enter Project ID"
              :value="secretProjectId"
              @input="secretProjectId = $event"
            />
            <TextArea
              id="service-account-credential"
              class="resize-none font-mono"
              label="Service Account credential"
              size="md"
              :value="secretValue"
              @input="secretValue = $event"
            />
          </template>
          <TextField
            v-else
            autofocus
            label="API Key"
            placeholder="Enter API Key"
            size="md"
            :value="secretValue"
            @input="secretValue = $event"
          />
          <DarwinButton
            class="mb-6 mt-16 self-center"
            variant="black"
            rounded
            size="md"
            @click="createProviderKey"
            >Save</DarwinButton
          >
        </form>
      </div>
      <div class="px-6 py-4 text-text-subtlest">
        By clicking "Save", you confirm your intention to connect your {{ providerName }} account to
        V7 Go. This action signifies that you accept of the Terms and conditions outlined.
      </div>
    </div>
  </ModalDialog>
</template>
