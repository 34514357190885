<script lang="ts" setup>
// Figma name: ToolTip
// https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/AGIDB---Design-System?type=design&node-id=199%3A5717&mode=dev

import { type AutoPlacementOptions } from '@floating-ui/vue'
import { useDebounce } from '@vueuse/core'
import { computed, ref } from 'vue'

import DarwinButton from './DarwinButton.vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'
import PopOver from './PopOver.vue'

export type ToolTipProps = {
  title?: string
  body?: string
  buttonIcon?: IconName
  buttonLabel?: string
  arrow?: boolean
  noPadding?: boolean
  offset?: number
  /**
   * High level rules for how the popup should be placed.
   *
   * @see https://floating-ui.com/docs/autoPlacement
   */
  placement?: AutoPlacementOptions
  disabled?: boolean
}

const props = defineProps<ToolTipProps>()

defineEmits<{
  (e: 'action'): void
}>()

const isOpen = ref(false)
const debouncedValue = computed(() => (isOpen.value ? 300 : 100))
const debouncedIsOpen = useDebounce(isOpen, debouncedValue)
// Same as isOpen, but allows for props.disabled to override it
const overridenIsOpen = computed(() => debouncedIsOpen.value && !props.disabled)

const role = computed(() => (props.buttonLabel ? 'dialog' : 'tooltip'))
</script>

<template>
  <PopOver
    :open="overridenIsOpen"
    :arrow="arrow"
    :placement="placement"
    :role="role"
    :offset="offset"
    @mouseover:tooltip="isOpen = true"
  >
    <template #trigger>
      <span
        data-test="tooltip-target"
        class="contents"
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
        @focusin="isOpen = true"
        @focusout="isOpen = false"
        @click="isOpen = false"
      >
        <slot :is-open="debouncedIsOpen" />
      </span>
    </template>

    <template #content>
      <div
        class="relative w-max max-w-[352px] overflow-hidden rounded-corner-6 border border-border"
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
      >
        <div
          class="bg-surface-popover-inverted text-text-inverted-irreversible"
          :class="[!noPadding && (body ? 'p-2' : 'px-2 py-1.5')]"
        >
          <slot name="content">
            <div class="flex flex-col justify-start gap-2 self-stretch">
              <div class="flex flex-col gap-0.5">
                <div
                  v-if="title"
                  class="select-none text-sm-12px-default font-medium text-text-inverted-irreversible"
                >
                  {{ title }}
                </div>
                <div
                  v-if="body"
                  class="select-none text-sm-12px-light text-text-inverted-subtle-irreversible"
                >
                  {{ body }}
                </div>
              </div>
              <DarwinButton
                v-if="buttonLabel"
                size="xs"
                variant="blue"
                class="w-fit text-text"
                @click="$emit('action')"
              >
                <template
                  v-if="buttonIcon"
                  #leading-icon
                >
                  <IconSprite :icon="buttonIcon" />
                </template>
                {{ buttonLabel }}
              </DarwinButton>
            </div>
          </slot>
        </div>
      </div>
    </template>
  </PopOver>
</template>
