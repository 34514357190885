import { watch, type Ref } from 'vue'
import { useModelProviders } from './modelProvidersStore'
import { useWorkspacePermissions } from './useWorkspacePermissions'

export const useLoadModelProviders = (workspaceId: Ref<string>) => {
  const modelProvidersStore = useModelProviders()
  const { canAccessSettings } = useWorkspacePermissions()
  watch(
    workspaceId,
    (id) => {
      modelProvidersStore.refreshTools(id)

      if (canAccessSettings.value) {
        modelProvidersStore.refreshProviderConfig(id)
      }
    },
    { immediate: true },
  )
}
