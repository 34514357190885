<script setup lang="ts">
import { computed, ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import ClaimIndicator from './ClaimIndicator.vue'
import { captureException } from '@sentry/vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import ToolTip from '@/uiKit/ToolTip.vue'
import { type Claim, type Source } from '@/modules/Project/useGroundingStore'

const props = defineProps<{
  text: string
  claims: Claim[]
}>()

const orderedClaims = computed(() =>
  props.claims.toSorted((a, b) => a.location.offset - b.location.offset),
)

const sections = computed<Array<{ sectionText: string; claim?: Claim }>>(() => {
  if (orderedClaims.value.length === 0) {
    return [{ sectionText: props.text }]
  }

  const claimedSections = orderedClaims.value.reduce<Array<{ sectionText: string; claim?: Claim }>>(
    (acc, claim, index) => {
      const previousClaim = orderedClaims.value[index - 1]
      const start = previousClaim ? previousClaim.location.offset : 0
      const end = claim.location.offset
      const sectionText = props.text.slice(start, end)
      acc.push({ sectionText, claim })
      return acc
    },
    [],
  )

  const lastClaim = orderedClaims.value.at(-1)
  if (!lastClaim) {
    // This should never happen since we guard against the case where there are no claims above,
    // but just in case...
    captureException(new Error('Last claim is missing'))
    return claimedSections
  }

  const lastSection = props.text.slice(lastClaim.location.offset)
  claimedSections.push({ sectionText: lastSection })
  return claimedSections
})

const container = ref<HTMLElement | null>(null)
const selectedSourceId = ref<Source['id'] | null>(null)

const { clickClaim } = useGroundingInteractions({
  onSourceClick: (id: string) => {
    if (!container.value) return
    const claim = container.value.querySelector(`[data-claim="${id}"]`)
    claim?.scrollIntoView({ behavior: 'smooth' })
    selectedSourceId.value = id
  },
})
</script>

<template>
  <div
    ref="container"
    class="contents whitespace-pre-wrap"
  >
    <span
      v-for="({ sectionText, claim }, sectionIndex) in sections"
      :key="sectionIndex"
      v-on-click-outside="() => (selectedSourceId = null)"
      class="scroll-my-4 transition-all"
      :class="
        !selectedSourceId ||
        (claim && 'sources' in claim && claim.sources.some((s) => s.id === selectedSourceId))
          ? 'opacity-100'
          : 'pointer-events-none opacity-25'
      "
    >
      <span class="pointer-events-none">{{ sectionText }}</span>
      <slot
        name="claim"
        :claim="claim"
      >
        <template v-if="claim">
          <ToolTip
            v-if="'id' in claim"
            title="Grounding source could not be retrieved"
            arrow
            :placement="{ allowedPlacements: ['top', 'top-end', 'top-start'] }"
          >
            <ClaimIndicator
              :label="claim.id"
              disabled
            />
          </ToolTip>
          <ClaimIndicator
            v-for="{ id } in claim.sources"
            v-else
            :key="id"
            class="-translate-y-px"
            :label="id"
            :data-claim="id"
            @click="
              () => {
                clickClaim(id)
                selectedSourceId = id
              }
            "
          />
        </template>
      </slot>
    </span>
  </div>
</template>
