<script setup lang="ts">
import { storeToRefs } from 'pinia'

import CheckBox from '@/uiKit/CheckBox.vue'

import { useProject } from './useProject'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'

defineProps<{
  extraWidth?: boolean
}>()
const project = useProject()
const { toggleAllEntities } = project
const { selectedEntityIds, activeView, allEntitiesSelected } = storeToRefs(project)

const { canEditProjects } = useWorkspacePermissions()
</script>

<template>
  <div
    class="inline-flex h-8 items-center justify-center gap-2 px-3 py-1.5 hover:cursor-pointer"
    :class="extraWidth ? 'w-16' : 'w-12'"
  >
    <CheckBox
      v-if="canEditProjects"
      :indeterminate="
        selectedEntityIds.size > 0 &&
        activeView &&
        activeView.entities &&
        selectedEntityIds.size < activeView.entities.length
      "
      :checked="allEntitiesSelected"
      click.native.stop
      @change="toggleAllEntities"
    />
  </div>
</template>
