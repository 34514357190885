<script setup lang="ts">
import ProjectCreateButton from '@/modules/Projects/ProjectCreateButton.vue'
import CrumbItem from '@/sharedComponents/CrumbItem.vue'
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import SupportButton from '@/sharedComponents/SupportButton.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'

defineProps<{ workspaceId: string }>()
const { isOpen } = useSidebar()

const hasIntercomChat = useHasIntercomChat()
</script>

<template>
  <div class="border-b border-border-subtle bg-surface-secondary-persist py-2 pl-2">
    <div class="flex flex-row justify-between">
      <div class="relative flex items-center">
        <div
          class="absolute left-0 z-10 transition duration-300 ease-in-out-quint"
          :class="
            isOpen
              ? 'pointer-events-none translate-x-[-8px] opacity-0'
              : 'translate-x-0 opacity-100'
          "
        >
          <SidebarToggle :disable-tooltip="isOpen" />
        </div>
        <CrumbItem
          class="ml-20"
          :to="{ name: 'Workspace' }"
          :active="true"
          >Recent projects</CrumbItem
        >
      </div>
      <div
        class="flex"
        :class="hasIntercomChat ? 'mr-2 gap-[60px]' : 'mr-24'"
      >
        <ProjectCreateButton
          :workspace-id="workspaceId"
          size="sm"
        />
        <SupportButton v-if="hasIntercomChat" />
      </div>
    </div>
  </div>
</template>
