<script setup lang="ts">
import { computed } from 'vue'

import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

import { type SimpleFilter, parseConjunction, useFilters } from '@/modules/Project/useFilters'
import { type Property, useProject } from '@/modules/Project/useProject'

import FilterBarItem from './FilterBarItem.vue'
import FilterBarAddFilter from './FilterBarAddFilter.vue'

const filtersStore = useFilters()
const projectStore = useProject()

const findPropertyByFilter = (filter: SimpleFilter): Property | undefined => {
  if ('property_id' in filter.matcher) {
    const propertyId = filter.matcher['property_id']
    return projectStore.properties.find((p) => p.id === propertyId)
  }
}

const filterItems = computed(() =>
  (filtersStore.currentFilter?.filters || [])
    .filter((f): f is SimpleFilter => 'matcher' in f)
    .map((f) => ({ filter: f, property: findPropertyByFilter(f) }))
    .filter((f) => f.property),
)

const onChangeConjunction = () => {
  if (!filtersStore.currentFilter) return

  const newConjunction = filtersStore.currentFilter.conjunction === 'and' ? 'or' : 'and'
  filtersStore.setConjunction(newConjunction)
}
</script>

<template>
  <div
    v-if="
      filtersStore.viewFilters &&
      filtersStore.currentFilter &&
      filtersStore.currentFilter.filters.length > 0
    "
    class="no-scrollbar flex shrink-0 select-none items-center gap-2 overflow-x-scroll border-t border-border-subtle bg-surface-secondary-persist px-2.5 py-2"
  >
    <DarwinButton
      variant="neutral"
      size="xs"
      @click="onChangeConjunction"
    >
      {{ parseConjunction(filtersStore.currentFilter.conjunction) }}
      <template #trailing-icon>
        <IconSprite icon="chevron-select" />
      </template>
    </DarwinButton>

    <div class="flex items-center gap-2">
      <FilterBarItem
        v-for="({ filter, property }, i) in filterItems"
        :key="i"
        class="flex h-6 shrink-0 cursor-default items-center rounded-corner-6 border border-border-subtle bg-surface-primary"
        :filter="filter"
        :index="i"
        :property="property"
        @delete="filtersStore.removeFilter(i)"
      />
      <FilterBarAddFilter>
        <template #trigger="{ isOpen, onClick }">
          <IconButton
            class="mr-2 text-icon-subtle"
            :class="{ 'bg-background-transparent-hovered': isOpen }"
            aria-label="Add a filter"
            size="md"
            icon="plus"
            variant="outline"
            @click="onClick"
          />
        </template>
      </FilterBarAddFilter>
    </div>
  </div>
</template>
