<script setup lang="ts">
/**
 * Primary component for '/:workspaceId/:projectId'
 *
 * Renders all project details and should be composed of smaller components
 */
import { computed, ref, watch } from 'vue'
import { useProject } from '@/modules/Project/useProject'
import { useRouter } from 'vue-router'

import { useProjects } from '@/modules/Projects/useProjects'

import { updateProject } from '@/backend/updateProject'
import ProjectName from '@/modules/Project/ProjectName.vue'
import { copyToClipboard } from '@/shared/clipboard'
import { omit } from '@/shared/utils'
import { doExportProject } from '@/shared/utils/exports'
import CrumbChevron from '@/sharedComponents/CrumbChevron.vue'
import CrumbItem from '@/sharedComponents/CrumbItem.vue'
import DeleteProjectDialog from '@/sharedComponents/DeleteProjectDialog.vue'
import PageHeader from '@/sharedComponents/PageHeader.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'

const props = defineProps<{
  workspaceId: string
  projectId: string
  viewId?: string
  parentProjectId?: string
  entityId?: string
}>()
const store = useProjects()
const project = computed(() => store.projects.find((w) => w.id === props.projectId))
const parentProject = computed(() => store.projects.find((w) => w.id === props.parentProjectId))
const subProjectName = computed(() => project.value?.parentProperty?.name)

const isRenaming = ref(false)

const hasError = ref(false)

const localProjectName = ref('')
watch(
  () => project.value?.name,
  () => {
    localProjectName.value = project.value?.name || ''
  },
)

const renameProject = async (newName: string) => {
  hasError.value = false
  if (newName.length < 3) {
    hasError.value = true
    return
  }

  const result = await updateProject(props.workspaceId, props.projectId, newName)

  if (!result.ok) {
    hasError.value = true
    return
  }

  store.updateProject(props.projectId, { name: result.data.name })

  isRenaming.value = false
}

const isDeleting = ref(false)
const router = useRouter()
const afterDelete = async () => {
  router.push(`/${props.workspaceId}`)
  isDeleting.value = false
}

const MenuIds = {
  CopyProjectId: 'copy-project-id',
  Rename: 'rename',
  Export: 'export',
  Delete: 'delete',
} as const

const { captureAnalyticsEvent } = useAnalytics()

const onCrumbClick = (isOpen: boolean) => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_CRUMB_MENU, {
    workspaceId: props.workspaceId,
    projectId: project.value?.id,
    value: isOpen,
  })
}

const revertProjectName = () => {
  localProjectName.value = project.value?.name || ''
  isRenaming.value = false
}

const { canEditProjects, canExportData } = useWorkspacePermissions()

const projectStore = useProject()
const view = computed(() => projectStore.views.find((w) => w.id === props.viewId))
const isMainView = computed(() => view.value?.name === 'main')
</script>

<template>
  <!-- Start with "All projects" hardcoded string -->
  <PageHeader
    :workspace-id="workspaceId"
    :project-id="projectId"
    :parent-project-id="parentProjectId"
  >
    <CrumbItem :to="`/${workspaceId}`">All projects</CrumbItem>

    <CrumbChevron />

    <!-- If there is a parent project, show it.
     --- As of now, collection projects can't be renamed from here, as that would mean renaming
     --- the collection property in the parent project, and that would require some work -->
    <template v-if="parentProject">
      <CrumbItem
        :to="{
          name: 'WorkspaceProject',
          params: { workspaceId, projectId: parentProjectId },
        }"
        >{{ parentProject.name || 'Untitled Project' }}</CrumbItem
      >

      <CrumbChevron />

      <CrumbItem
        :active="!entityId && !viewId"
        :to="{
          name: 'WorkspaceSubProjectTable',
          params: { workspaceId, parentProjectId, projectId },
          query: { parentEntityId: router.currentRoute.value.query.parentEntityId },
        }"
        >{{ subProjectName }}</CrumbItem
      >
    </template>

    <!-- If we're in entity view, then we can just navigate back to project by clicking-->
    <template v-if="project && entityId && !subProjectName">
      <CrumbItem
        :to="{
          name: 'WorkspaceProject',
          params: { workspaceId, projectId },
        }"
      >
        <ProjectName :project="project" />
      </CrumbItem>
    </template>
    <!-- If there's no parent project, this is a top-level project, and we can rename it. -->
    <InlineTextField
      v-else-if="isRenaming && project"
      autofocus
      size="xs"
      :value="localProjectName"
      :error="hasError"
      data-test="rename-project"
      @input="localProjectName = $event"
      @submit="renameProject"
      @blur="renameProject(localProjectName)"
      @esc="revertProjectName"
    ></InlineTextField>
    <!-- We can also open a floating menu with more project actions -->
    <FloatingMenu
      v-else-if="project && !subProjectName"
      @select="
        (id) => {
          switch (id) {
            case MenuIds.CopyProjectId:
              copyToClipboard('Project ID', projectId)
              break
            case MenuIds.Rename:
              if (project?.name) {
                localProjectName = project.name
              }
              isRenaming = true
              break
            case MenuIds.Export:
              captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
              doExportProject(workspaceId, projectId, 200, 3000) // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
              break
            case MenuIds.Delete:
              isDeleting = true
              break
          }
        }
      "
    >
      <template #trigger="{ triggerProps }">
        <CrumbItem
          data-test="crumbs-project-name"
          class="!shrink !grow-0 truncate"
          :active="view ? false : true"
          v-bind="triggerProps"
          @click="onCrumbClick(triggerProps['aria-expanded'] ? true : false)"
        >
          <ProjectName
            id="project-name"
            :project="project"
          />
        </CrumbItem>
      </template>
      <template #content="{ contentProps, getItemProps }">
        <ListMenuContainer
          class="w-[280px] min-w-max p-0.5"
          v-bind="contentProps"
        >
          <div class="w-full">
            <ToolTip
              class="w-full"
              :arrow="true"
              :placement="{ allowedPlacements: ['right'] }"
              :title="projectId"
            >
              <ListMenuItem
                label="Copy project ID"
                icon="copy"
                v-bind="omit(getItemProps({ value: MenuIds.CopyProjectId }), ['onSelect'])"
              />
            </ToolTip>
          </div>
          <div
            v-if="canEditProjects"
            class="w-full"
          >
            <ListMenuItem
              label="Rename"
              icon="edit"
              v-bind="omit(getItemProps({ value: MenuIds.Rename }), ['onSelect'])"
            />
          </div>
          <div
            v-if="canExportData"
            class="w-full"
          >
            <ListMenuItem
              label="Export"
              icon="arrow-bottom-circle"
              v-bind="omit(getItemProps({ value: MenuIds.Export }), ['onSelect'])"
            />
          </div>
          <template v-if="canEditProjects">
            <div class="my-0.5 w-full border-t border-background-gray-subtlest"></div>
            <div class="w-full">
              <ListMenuItem
                critical
                label="Delete project"
                icon="trash"
                v-bind="omit(getItemProps({ value: MenuIds.Delete }), ['onSelect'])"
              />
            </div>
          </template>
        </ListMenuContainer>
      </template>
    </FloatingMenu>

    <!-- If there is a view, show it. As of now, views can't be renamed from here -->
    <template v-if="view && !isMainView">
      <CrumbChevron />
      <CrumbItem
        :active="!entityId"
        :to="
          parentProject
            ? {
                name: 'WorkspaceSubProjectTableView',
                params: { workspaceId, parentProjectId, projectId, viewId: view.id },
              }
            : {
                name: 'WorkspaceProjectTableView',
                params: { workspaceId, projectId, viewId: view.id },
              }
        "
      >
        {{ view.name }}
      </CrumbItem>
    </template>

    <!-- If there is an entity, show it -->
    <template v-if="entityId">
      <CrumbChevron />
      <CrumbItem active>
        {{ entityId }}
      </CrumbItem>
    </template>

    <DeleteProjectDialog
      :workspace-id="props.workspaceId"
      :project-id="props.projectId"
      :open="isDeleting"
      @close="isDeleting = false"
      @delete="afterDelete"
    />
  </PageHeader>
</template>
