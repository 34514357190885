import { computed, type ComputedRef } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { type WorkspaceMember } from './useWorkspaceMembers'

export const WorkspacePermission = {
  EditProjects: 'EditProjects',
  BulkDeleteEntities: 'BulkDeleteEntities',
  AccessSettings: 'AccessSettings',
  EditProperties: 'EditProperties',
  ViewTutorials: 'ViewTutorials',
  ExportData: 'ExportData',
  SeeUnassignedProjects: 'SeeUnassignedProjects',
  UseAskGo: 'UseAskGo',
} as const

export type WorkspacePermission = (typeof WorkspacePermission)[keyof typeof WorkspacePermission]

type PermissionWithCan = `can${WorkspacePermission}`

/**
 * Maps each workspace permission to the roles that have that permission.
 */
const permissionRoleMap: Record<WorkspacePermission, WorkspaceMember['role'][]> = {
  EditProjects: ['admin', 'owner'],
  AccessSettings: ['admin', 'owner'],
  EditProperties: ['admin', 'owner'],
  BulkDeleteEntities: ['admin', 'owner'],
  ViewTutorials: ['admin', 'owner'],
  ExportData: ['admin', 'owner'],
  SeeUnassignedProjects: ['admin', 'owner'],
  UseAskGo: ['admin', 'owner', 'editor'],
}

/** Helper function to return whether a role has a permission */
const roleHasPermission = (
  role: WorkspaceMember['role'] | undefined | null,
  permission: WorkspacePermission,
  // The role should never really be undefined or null, this will only happen
  // when there is no workspace, which will cause the app to break in many other
  // ways! We default to `true` to make testing easier, as otherwise we would
  // need to mock the workspace store in every test.
): boolean => (role ? permissionRoleMap[permission].includes(role) : true)

/**
 * Composable function that returns computed refs to indicate whether the current
 * user has permission to perform certain actions in the workspace.
 */
export const useWorkspacePermissions = (): Record<PermissionWithCan, ComputedRef<boolean>> => {
  const workspacesStore = useWorkspaces()
  const currentRole = computed(() => workspacesStore.currentWorkspace?.role)

  return {
    canUseAskGo: computed(() => roleHasPermission(currentRole.value, WorkspacePermission.UseAskGo)),
    canEditProjects: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.EditProjects),
    ),
    canBulkDeleteEntities: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.BulkDeleteEntities),
    ),
    canAccessSettings: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.AccessSettings),
    ),
    canEditProperties: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.EditProperties),
    ),
    canViewTutorials: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.ViewTutorials),
    ),
    canExportData: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.ExportData),
    ),
    canSeeUnassignedProjects: computed(() =>
      roleHasPermission(currentRole.value, WorkspacePermission.SeeUnassignedProjects),
    ),
  }
}
