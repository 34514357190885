import { getEntity } from '@/backend/getEntity'
import { listEntityIds, type ListEntityIdsArgs } from '@/backend/listEntityIds'
import { listFilteredEntityIds } from '@/backend/listFilteredEntityIds'
import { useEntity } from '@/modules/Project/useEntity'
import { serializeEntity, useProject } from '@/modules/Project/useProject'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'

import { useFilters } from './useFilters'

export const usePrevOrNextEntity = () => {
  const entityStore = useEntity()
  const filtersStore = useFilters()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  const listEntityIdsFn = (args: ListEntityIdsArgs, activeViewId?: string) => {
    if (!filtersStore.currentFilter || filtersStore.currentFilter.filters.length === 0) {
      return listEntityIds({ ...args, active_view_id: activeViewId })
    }

    if (activeViewId) {
      return listFilteredEntityIds({
        ...args,
        filter: {
          conjunction: 'and',
          filters: [
            filtersStore.currentFilter,
            {
              matcher: { name: 'any_of', values: [activeViewId] },
              subject: 'active_view_id',
            },
          ],
        },
      })
    }

    return listFilteredEntityIds({
      ...args,
      filter: filtersStore.currentFilter,
    })
  }

  const loadNeighbours = async () => {
    if (!entityStore.entityId || !projectStore.projectId || !workspacesStore.currentWorkspace) {
      return
    }

    const workspaceId = workspacesStore.currentWorkspace.id
    const projectId = projectStore.projectId
    const entityId = entityStore.entityId

    if (!entityStore.nextId) {
      listEntityIdsFn(
        {
          workspaceId: workspaceId,
          projectId: projectId,
          after: entityId,
          first: 25,
        },
        projectStore.activeView?.id,
      ).then((result) => {
        if (!result.ok) return
        if (result.data.data.length === 0) return
        const minId = result.data.data[0]
        entityStore.insertNextIds(result.data.data, entityId)

        getEntity(workspaceId, projectId, minId).then((result) => {
          if (!result.ok) return
          entityStore.updateEntity(serializeEntity(result.data))
        })
      })
    } else if (!entityStore.next) {
      getEntity(workspaceId, projectId, entityStore.nextId).then((result) => {
        if (!result.ok) return
        entityStore.updateEntity(serializeEntity(result.data))
      })
    }

    if (!entityStore.prevId) {
      listEntityIdsFn(
        {
          workspaceId: workspaceId,
          projectId: projectId,
          before: entityId,
          last: 25,
        },
        projectStore.activeView?.id,
      ).then((result) => {
        if (!result.ok) return
        if (result.data.data.length === 0) return
        const maxId = result.data.data[result.data.data.length - 1]
        entityStore.insertPrevIds(result.data.data, entityId)

        getEntity(workspaceId, projectId, maxId).then((result) => {
          if (!result.ok) return
          entityStore.updateEntity(serializeEntity(result.data))
        })
      })
    } else if (!entityStore.prev) {
      getEntity(workspaceId, projectId, entityStore.prevId).then((result) => {
        if (!result.ok) return
        entityStore.updateEntity(serializeEntity(result.data))
      })
    }
  }

  return { loadNeighbours }
}
