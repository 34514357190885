<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconButton from '@/uiKit/IconButton.vue'

import type { LayoutItem } from './EntityViewCard.vue'
import CardTextEditor from './CardTextEditor.vue'

const props = defineProps<{
  item: LayoutItem
}>()

const emit = defineEmits<{
  (e: 'submit', val: string): void
}>()

const hasFocus = ref(false)

const value = computed(() =>
  props.item.field.type === 'url' ? props.item.field.manualValue || props.item.field.toolValue : '',
)

const metadata = computed(() => {
  if (props.item.field.type === 'url') {
    return props.item.field.metadata
  }
  return undefined
})

const status = computed(() => props.item.field.status)

const httpUrl = computed(() => {
  if (!value.value || typeof value.value !== 'string') return
  const startsWithHttpOrS = value.value.match(/^https?:\/\//)
  return startsWithHttpOrS ? value.value : `https://${value.value}`
})

const submit = (value: string) => {
  emit('submit', value)
  setFocus(false)
}

const setFocus = (state: boolean) => {
  hasFocus.value = state
  if (state) {
    nextTick(() => {
      input.value?.focus()
    })
  }
}

const openInNewTab = () => {
  if (value.value) {
    window.open(httpUrl.value, '_blank')
  }
}

const input = ref<typeof CardTextEditor | null>(null)
</script>

<template>
  <div
    v-if="!hasFocus && value"
    class="size-full rounded-corner-4 bg-surface-primary"
  >
    <div class="p-1.5 pt-0">
      <div class="flex max-w-full flex-row justify-between gap-1">
        <BadgeItem
          size="sm"
          :label="httpUrl || ''"
          variant="neutral"
          class="!shrink cursor-pointer"
          underline
          @click.stop="openInNewTab"
        >
          <template #leading-icon>
            <CircularProgress
              v-if="status === 'computing'"
              size="xs"
              color="neutral"
            />
            <ObjectURLImage
              v-else-if="metadata?.favicon?.url"
              class="size-4 shrink-0 overflow-hidden rounded-corner-4"
              :url="metadata?.favicon?.url"
              :loading="false"
            >
              <template #fallback>
                <LoadingSkeleton
                  data-test="loading-skeleton"
                  class="size-4"
                  :status="true"
                />
              </template>
            </ObjectURLImage>
            <IconSprite
              v-else
              icon="link"
              size="xs"
              color="error"
            />
          </template>
        </BadgeItem>
        <IconButton
          variant="transparent"
          icon="edit"
          size="sm"
          @click="setFocus(true)"
        />
      </div>
      <div
        v-if="metadata?.screenshot?.url"
        class="pt-1 before:-mx-2 before:mb-1 before:block before:border-t before:border-border-subtle"
        @click="setFocus(true)"
      >
        <img
          class="rounded-corner-4"
          :src="metadata.screenshot.url"
        />
      </div>
    </div>
  </div>
  <CardTextEditor
    v-else
    ref="input"
    :value="value ?? ''"
    :json="false"
    :readonly="props.item.readonly"
    :label="item.property?.name"
    @submit="submit"
    @blur="setFocus(false)"
    @enter="submit"
  />
</template>
