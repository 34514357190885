<script setup lang="ts">
import { useElementSize } from '@vueuse/core'
import { useGroundingStore, type Source } from './useGroundingStore'
import { computed, nextTick } from 'vue'
import type { OcrPage } from '@/backend/types'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import ScaleButtons from './ScaleButtons.vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.vue'
import { useGroundingPolygons } from './useGroundingPolygons'

const props = defineProps<{
  sources: Source[]
  imagePath: string
  ocrPage: OcrPage
}>()

const originalImageWidth = computed(() => props.ocrPage.width)
const storageKey = computed(
  () => `grounding-image-scale-${props.imagePath.slice(0, 300).replace(/[\s/:]/g, '-')}`,
)
const { setScale, scale, scrollContainer, scrollContainerChild, onZoom } = useNaturalZoom(
  storageKey,
  originalImageWidth,
)
const { height: imageHeight, width: imageWidth } = useElementSize(scrollContainerChild)

/** Position of the claim indicator that appears to the top left of a source */
const getClaimIndicatorStyles = (
  bbox: Source['boundingBoxes'][number],
): Pick<CSSStyleDeclaration, 'left' | 'top'> => {
  const { height, width } = props.ocrPage

  const PADDING_PX = 2

  return {
    left: `${(bbox.xmin * imageWidth.value) / width - PADDING_PX}px`,
    top: `${(bbox.ymin * imageHeight.value) / height - PADDING_PX}px`,
  }
}

const imageStyle = computed<
  Pick<CSSStyleDeclaration, 'height' | 'width' | 'minHeight' | 'minWidth'>
>(() => {
  if (scale.value === 'fit') {
    return {
      width: '100%',
      height: 'auto',
      minWidth: '100%',
      minHeight: '100%',
    }
  }

  const width = `${props.ocrPage.width * scale.value}px`
  const height = `${props.ocrPage.height * scale.value}px`
  return {
    width,
    height,
    minWidth: width,
    minHeight: height,
  }
})

const groundingStore = useGroundingStore()
const { clickSource } = useGroundingInteractions({
  onClaimClick: async (n) => {
    groundingStore.clickClaim(n)
    await nextTick()
    if (!scrollContainer.value) return

    const source = props.sources.find((s) => s.id === n)
    const page = source?.boundingBoxes[0].page
    const sourceEl = scrollContainer.value.querySelector(`[data-source="${n}"]`)
    if (!sourceEl || !page) return

    const viewbox = getOcrPageViewbox(page)
    const [vw, vh] = viewbox.split(' ').map(Number).slice(2)
    const actualWidth = props.ocrPage.width
    const actualHeight = (actualWidth / vw) * vh
    const wScale = actualWidth / vw
    const hScale = actualHeight / vh
    const points = getPolygonPointsFromSource(source, page).flatMap((polygons) => {
      return polygons
        .trim()
        .split(' ')
        .flatMap((points) => {
          const [x, y] = points.split(',').map(Number)
          return { x: x * wScale, y: y * hScale }
        })
    })

    const [minX, minY, maxX, maxY] = points.reduce(
      ([minX, minY, maxX, maxY], { x, y }) => [
        Math.min(minX, x),
        Math.min(minY, y),
        Math.max(maxX, x),
        Math.max(maxY, y),
      ],
      [Infinity, Infinity, -Infinity, -Infinity],
    )
    const width = maxX - minX
    const height = maxY - minY

    const pdfRect = scrollContainer.value.getBoundingClientRect()
    // Figure out appropriate zoom level
    const zoom = Math.min(pdfRect.width / (width + 20), pdfRect.height / (height + 20), 2)
    setScale(zoom)
    await new Promise((resolve) => setTimeout(resolve, 100))

    const scaledLeft = minX * zoom
    const scaledTop = minY * zoom
    scrollContainer.value.scrollTo({
      left: scaledLeft,
      top: scaledTop,
      behavior: 'smooth',
    })
  },
})

const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  computed(() => [props.ocrPage]),
)
</script>

<template>
  <div class="flex size-full min-h-0 flex-col gap-3 overflow-hidden pl-3">
    <div
      ref="scrollContainer"
      class="go-scrollbar max-w-full grow justify-center overflow-auto rounded-md border border-border-subtle p-3"
    >
      <div class="relative">
        <img
          ref="scrollContainerChild"
          :src="imagePath"
          :style="imageStyle"
          @wheel.ctrl.exact.prevent.stop="onZoom($event)"
          @wheel.meta.exact.prevent.stop="onZoom($event)"
        />
        <template
          v-for="source in sources"
          :key="source.id"
        >
          <ClaimIndicator
            v-if="source.boundingBoxes[0]"
            :data-source="source.id"
            class="absolute z-10 -translate-x-2 -translate-y-4 scroll-m-6"
            :label="source.id"
            :style="getClaimIndicatorStyles(source.boundingBoxes[0])"
            @click="clickSource(source.id)"
          />
          <svg
            :viewBox="getOcrPageViewbox(1)"
            class="pointer-events-none absolute left-0 top-0"
            :style="imageStyle"
          >
            <polygon
              v-for="(points, index) in getPolygonPointsFromSource(source, 1)"
              :key="index"
              :points="points"
              :data-test="`grounding-source-${source.id}-${index}`"
              class="fill-background-stages-model-subtle stroke-background-stages-model"
              :style="{
                strokeWidth: String(strokeWidthPerPage[0]),
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }"
            />
          </svg>
        </template>
      </div>
    </div>
    <ScaleButtons
      :scale="scale"
      class="grid grid-cols-3"
      full-width-mode
      @update:scale="setScale"
    />
  </div>
</template>
