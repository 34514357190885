<script setup lang="ts">
/**
 * Basic text field implementation in UI Kit
 *
 * This is not the final version of the component, but the API should remain as is
 *
 * What this is missing is
 *
 * - support for a help icon next to the label, which will render a help text prop
 *   - we should do this by adding an optional prop,
 *     then rendering a uikit help icon tooltip component
 * - support for a text prefix in the input field
 *   - we should do this by adding an optional prop which is value for the prefix
 *     then adding the optional html markup and styling
 * - support for an icon prefix in the input field
 *   - we should do this by supporting an optional prop which is the icon name
 * - support for an icon suffix in the input field
 *   - we should do this by supporting an optional prop which is the icon name
 * - support for slots in place of props for most if not all of the above
 *   - once the styles are in, we should just optionally show a slot instead of
 *     the prop for each supported case
 *   - we should not try to solve all potential styling issues if we do end up supporting this.
 */
import { computed, onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    placeholder?: string
    error?: string
    warning?: string
    info?: string
    value?: string
    label?: string
    size: 'md' | 'lg'
    rounded?: boolean
    autofocus?: boolean
    required?: boolean
    type?: 'text' | 'password' | 'email' | 'number' | 'search'
    disabled?: boolean
  }>(),
  {
    autofocus: undefined,
    error: undefined,
    placeholder: undefined,
    info: undefined,
    label: undefined,
    required: undefined,
    rounded: undefined,
    value: undefined,
    warning: undefined,
    type: 'text',
    disabled: false,
  },
)

const emit = defineEmits<{
  (e: 'change' | 'input', value: string): void
  (e: 'blur'): void
}>()

defineExpose({
  focus: () => refInput.value?.focus(),
})

const refInput = ref<HTMLInputElement>()

const emitChange = (event: Event): void => emit('change', (event.target as HTMLInputElement).value)
const emitInput = (event: Event): void => emit('input', (event.target as HTMLInputElement).value)

const focusing = ref(false)

const variant = computed<'error' | 'warning' | 'info' | 'default'>(() => {
  if (props.error) {
    return 'error'
  }
  if (props.warning) {
    return 'warning'
  }
  if (props.info) {
    return 'info'
  }
  return 'default'
})

onMounted(() => {
  if (props.autofocus) {
    refInput.value?.focus()
    refInput.value?.select()
  }
})
</script>

<template>
  <label
    class="flex w-full flex-col items-stretch justify-center gap-1 self-stretch"
    v-bind="$attrs"
  >
    <div
      v-if="label"
      class="text-xs-11px-default text-text transition"
    >
      {{ label }}
    </div>
    <div
      class="flex w-full items-center bg-background-gray-subtlest text-sm-12px-default text-text transition-colors placeholder:text-text-subtlest focus-within:bg-background-gray-subtlest focus-within:text-text focus-within:shadow-focus-ring-primary focus-within:outline-none aria-readonly:cursor-pointer disabled:cursor-not-allowed hover:[&:not(:disabled):not(:focus-within)]:bg-background-gray-subtlest-hovered"
      :class="[
        [variant === 'error' && ['border-border-critical'], variant !== error && []],
        [size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2'],
        [rounded ? 'rounded-corner-10' : 'rounded-corner-8'],
        $slots['leading-icon'] && 'gap-1.5',
      ]"
    >
      <div
        v-if="$slots['leading-icon']"
        class="grid place-items-center"
      >
        <slot name="leading-icon" />
      </div>
      <input
        ref="refInput"
        class="w-full bg-background-transparent outline-none"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        :disabled="disabled"
        @focusin="focusing = true"
        @focusout="focusing = false"
        @change="emitChange"
        @input="emitInput"
        @blur="emit('blur')"
      />
      <div
        v-if="$slots['trailing-icon']"
        class="grid place-items-center"
      >
        <slot name="trailing-icon" />
      </div>
    </div>
    <div
      v-if="error || warning || info"
      class="flex h-5 flex-row items-center transition duration-300"
      :class="[focusing ? 'text-sm-12px-bold' : 'text-sm-12px-default']"
    >
      <div
        v-if="error"
        class="text-text-critical"
      >
        {{ error }}
      </div>
      <div
        v-else-if="warning"
        class="text-text-warning"
      >
        {{ warning }}
      </div>
      <div
        v-else-if="info"
        class="text-text"
      >
        {{ info }}
      </div>
    </div>
  </label>
</template>
