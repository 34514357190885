<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import EnterprisePaywallVisual from '@/illustrations/enterprise-paywall.svg'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useIntercom } from '@/sharedComposables/useIntercom'
defineProps<{
  open: boolean
  workspaceId: string
}>()

defineEmits<{
  (e: 'close'): void
}>()

const { showIntercom } = useIntercom()
</script>

<template>
  <ModalDialog
    :open="open"
    to="body"
    aria-label="Upgrade your plan"
    @close="$emit('close')"
  >
    <div class="relative flex min-h-[344px] min-w-[480px] flex-col items-center pb-6">
      <EnterprisePaywallVisual class="absolute top-0" />
      <IconButton
        size="lg"
        variant="neutral"
        class="absolute right-2 top-2 text-text-subtle"
        icon="close"
        rounded
        @click="$emit('close')"
      />
      <div class="m-auto text-center">
        <h2 class="mb-1 text-xl-18px-bold text-text">You've reached a plan limit</h2>
        <p class="text-sm-12px-light text-text-subtle">
          Please ask your CSM to make any changes to your plan.
        </p>
      </div>
      <DarwinButton
        size="sm"
        class="absolute bottom-5"
        variant="neutral"
        @click="showIntercom"
        >Contact Support</DarwinButton
      >
    </div>
  </ModalDialog>
</template>
