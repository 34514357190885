import { get } from './client'
import type { BackendMeta } from './types'

export type ListLibraryItems = BackendMeta<'/api/workspaces/{workspace_id}/library/entities', 'get'>

export const listLibraryItems = ({
  workspaceId,
  start,
  end,
}: {
  workspaceId: string
  start: number
  end: number
}) =>
  get<ListLibraryItems['body'], ListLibraryItems['successResponse'], ListLibraryItems['path']>(
    `/api/workspaces/${workspaceId}/library/entities`,
    {
      order_by: ['id'],
      limit: end - start + 1,
      offset: start,
    },
  )
