import { addProjectPermission } from '@/backend/addProjectPermission'
import { removeProjectPermission } from '@/backend/removeProjectPermission'
import type { ResourceRole } from '@/backend/types'
import { updateProjectPermission } from '@/backend/updateProjectPermission'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { ProjectMemberRole } from './types'

export const useProjectPermissionsStore = defineStore('projectPermissions', () => {
  // Default permission to apply to any workspace members that haven't been
  // given an explicit permission
  const defaultRole = ref<ProjectMemberRole>('editor')

  // Map workspace members to their permissions
  const workspaceMemberRoles = ref<Partial<Record<WorkspaceMember['id'], ResourceRole | 'owner'>>>(
    {},
  )

  /**
   * Set an explicit project permission for a workspace member.
   */
  const setProjectRole = async ({
    role,
    userId,
    projectId,
    workspaceId,
  }: {
    userId: WorkspaceMember['id']
    role: ProjectMemberRole | null
    workspaceId: string
    projectId: string
  }) => {
    const oldRole = workspaceMemberRoles.value[userId]
    workspaceMemberRoles.value[userId] = role || undefined

    let res
    if (role === null) {
      // No role, i.e. remove the explicit permission
      res = await removeProjectPermission({
        userId,
        projectId,
        workspaceId,
      })
    } else if (oldRole) {
      // There's an old role and a new role - update the permission
      res = await updateProjectPermission({
        userId,
        role,
        projectId,
        workspaceId,
      })
    } else {
      // No old role, so we need to add a new permission
      res = await addProjectPermission({
        userId,
        role,
        projectId,
        workspaceId,
      })
    }

    if (!res.ok) {
      workspaceMemberRoles.value[userId] = oldRole
      throw new Error('Failed to set project permission')
    }
  }

  const reset = () => {
    defaultRole.value = 'editor'
    workspaceMemberRoles.value = {}
  }

  return {
    defaultRole,
    workspaceMemberRoles,
    setProjectRole,
    reset,
  }
})
