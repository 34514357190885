<script lang="ts">
export const ITEM_HEIGHT = 32
</script>

<script setup lang="ts">
import { debouncedWatch, onKeyStroke, useElementSize, useScroll } from '@vueuse/core'
import { computed, nextTick, onBeforeUnmount, ref, watch, type StyleValue } from 'vue'

import { bulkRemoveEntities } from '@/backend/bulkRemoveEntities'

import { useVerticalVirtualList } from '@/sharedComposables/useVerticalVirtualList'

import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

import { useBilling } from '../Billing/useBilling'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import ProjectTableActionBar from './ProjectTableActionBar.vue'
import ProjectTableAddRow from './ProjectTableAddRow.vue'
import ProjectTableHeader from './ProjectTableHeader.vue'
import ProjectTableRow from './ProjectTableRow.vue'
import { useCreateEntity } from './useCreateEntity'
import { useFieldUploadQueue } from './useFieldUploadQueue'
import { serializeEntity, serializeFields, useProject, type ViewInfo } from './useProject'
import { useProperty } from './useProperty'
import { useTable } from './useTable'
import { useTableClipboard } from './useTableClipboard'
import { useTableInteractions } from './useTableInteractions'
import { ACTION_BAR_Z_INDEX, useTableZIndices } from './useTableZIndices'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useWorkspacePermissions } from '../WorkspaceSettings/useWorkspacePermissions'
import { setFieldValue } from '@/backend/setFieldValue'
import { toast } from '@/shared/toast'
import { getEntity } from '@/backend/getEntity'
import { useListEntities } from './useListEntities'
import { useFilters } from './useFilters'
import { useAskGo } from './useAskGo'

const props = defineProps<{ parentEntityId?: string }>()

const projectStore = useProject()
const workspacesStore = useWorkspaces()
const propertyStore = useProperty()
const pendingIdsToDelete = ref<string[]>([])

const {
  list,
  containerProps,
  wrapperProps,
  scrollTo,
  state: rangeState,
  // TODO: Try to remove in the integration PR,
  // as it may be fixed differently there
} = useVerticalVirtualList(
  computed(() => {
    return projectStore.activeView?.entities?.slice() ?? []
  }),
  { itemHeight: ITEM_HEIGHT, overscan: 5 },
)

const { width: tableWidth, height: tableHeight } = useElementSize(containerProps.ref)

const INITIAL_ENTITY_LOAD_COUNT = 50
const rowsInView = computed(() =>
  containerProps.ref ? Math.ceil(tableHeight.value / ITEM_HEIGHT) : INITIAL_ENTITY_LOAD_COUNT,
)

const listEntities = useListEntities()
const hasLoadedInitialRange = ref(false)
const loadRange = async ({ start, end }: (typeof rangeState)['value'], viewInfo?: ViewInfo) => {
  if (!projectStore.projectId || !workspacesStore.currentWorkspace || !viewInfo) {
    // this should not be possible, but typescript says it is
    return
  }

  // force the initial range state when trying to load entities for a view that has not been loaded yet
  if (!viewInfo.entities) {
    start = 0
    end = rowsInView.value
  }

  if (start === end) {
    // this is a zero-range, which would happen if the project has no entities
    // nothing to load from BE in this case
    return
  }

  // if entities are stale we want to reload them all even if some are already loaded
  if (!projectStore.areEntitiesStale) {
    // shift the start index to the nearest non-loaded entity
    for (let i = start; i <= end; i++) {
      start = i
      if (!viewInfo.entities?.[i]) {
        break
      }
    }
    // shift the end index to the nearest non-loaded entity
    for (let i = end - 1; i > start; i--) {
      end = i + 1
      if (!viewInfo.entities?.[i]) {
        break
      }
    }
    if (start >= end) {
      // all entities in the range are already loaded
      return
    }
  }

  const entitiesResult = await listEntities({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    start,
    end,
    parentEntityId: props.parentEntityId,
    viewId: viewInfo.id,
  })

  if (!entitiesResult.ok) return

  const listedEntities = entitiesResult.data.data.map(serializeEntity)
  const totalCountFromServer = entitiesResult.data.metadata.total_count
  projectStore.spliceEntities(listedEntities, totalCountFromServer, start, viewInfo.id)
  if (projectStore.areEntitiesStale) {
    projectStore.dropEntitiesOutsideOfView(viewInfo.id, start, start + listedEntities.length)
    projectStore.setEntitiesStale(false)
  }
  hasLoadedInitialRange.value = true
}

/**
 * If we haven't loaded entities yet, then build an array of undefined
 * entities so that we can render a skeleton table.
 */
const entityList = computed<typeof list.value>(() =>
  hasLoadedInitialRange.value || list.value.length > 0
    ? list.value
    : Array.from({ length: INITIAL_ENTITY_LOAD_COUNT }).map((_, i) => ({
        data: undefined,
        index: i,
      })),
)

debouncedWatch(
  () => [rangeState.value, projectStore.activeView, projectStore.areEntitiesStale] as const,
  ([newRange, activeView]) => {
    loadRange(newRange, activeView)
  },
  { debounce: 100, immediate: true },
)

onBeforeUnmount(() => {
  projectStore.clearAllSelectedEntities()
})

const scrollIntoView = (container: HTMLElement, selector: string) => {
  const ellToScrollTo = container.querySelector(selector)
  if (
    ellToScrollTo &&
    !ellToScrollTo.isSameNode(document.activeElement) &&
    ellToScrollTo instanceof HTMLElement
  ) {
    ellToScrollTo.scrollIntoView()
  }
}

watch(
  () => propertyStore.property,
  (newVal) =>
    nextTick(() => {
      // if the property sidebar is open it might hide the selected column so we need to scroll to it
      newVal &&
        containerProps.ref.value &&
        scrollIntoView(containerProps.ref.value, '[data-column-selected=true]')
      if (!newVal) return
    }),
)

watch(
  () => projectStore.activeView?.id,
  () => {
    projectStore.clearAllSelectedEntities()
  },
)

watch(
  () => projectStore.visibleProperties.length,
  (newVal, oldVal) => {
    if (oldVal === 0) {
      /**
       * If the old value was 0 then either:
       * - the component was just mounted
       * - the user has just added a first property
       * In either case, we don't want to scroll.
       */
      return
    }

    nextTick(() => {
      // only scroll if we have added a col
      if (newVal > oldVal) {
        // scrollTo will trigger the scroll event,
        // which will also sync the header scroll position
        containerProps.ref.value?.scrollTo({ left: 999999 })
      }
    })
  },
)

const { captureAnalyticsEvent } = useAnalytics()

const billingStore = useBilling()
const { createEntity } = useCreateEntity()
const addRow = async () => {
  const ok = await createEntity()
  if (!ok) return

  captureAnalyticsEvent(ANALYTICS_EVENT.ENTITY_CREATED)

  // give the component a chance to rerender, then scroll to bottom
  nextTick(() => scrollTo((projectStore.activeView?.entities?.length ?? 0) - 1))
}

const removePendingDeletionEntities = async () => {
  if (
    !projectStore.projectId ||
    !workspacesStore.currentWorkspace ||
    pendingIdsToDelete.value.length <= 0
  ) {
    return
  }

  const result = await bulkRemoveEntities(
    workspacesStore.currentWorkspace.id,
    projectStore.projectId,
    pendingIdsToDelete.value,
  )
  if (result.ok) {
    if (billingStore.fieldUsage) {
      billingStore.fieldUsage.limitUsage -=
        pendingIdsToDelete.value.length * projectStore.properties.length
    }

    projectStore.removeEntitiesById(pendingIdsToDelete.value)
  }

  pendingIdsToDelete.value = []
}

const tableStore = useTable()
const selectedRange = computed(() => tableStore.selectedRange)

const tableBody = ref<HTMLElement | null>(null)
const { moveCellFocusDown, onTabKeystroke } = useTableInteractions(
  tableBody,
  computed(() => projectStore.activeView?.entities?.length ?? 0),
  computed(() => projectStore.properties.length),
)

/**
 * Handle a table cell emitting the 'next' event. Depending on how the event
 * was triggered, we may need to move the focus horizontally or vertically.
 */
const onNext = (e?: KeyboardEvent) => {
  if (!e) {
    moveCellFocusDown()
    return
  }

  if (e.key === 'Enter') {
    moveCellFocusDown()
    return
  }

  if (e.key === 'Tab') {
    onTabKeystroke(e)
    return
  }
}

useFieldUploadQueue(2)

const isEditing = computed(() => Boolean(tableStore.focusedCell))
useTableClipboard(selectedRange, isEditing)

const toggleRowSelection = (entityId: string) => {
  projectStore.toggleEntity(entityId, !isRowSelected(entityId))
}

const extendRowSelection = (rowIndex: number) => {
  const entity = projectStore.activeView?.entities?.at(rowIndex)
  entity && projectStore.extendRowSelectionTo(entity.id)
}

const isRowSelected = (entityId: string): boolean => projectStore.selectedEntityIds.has(entityId)

onKeyStroke('Escape', () => {
  projectStore.clearAllSelectedEntities()
})

/** Indices of all properties that should be rendered in this view */
const cells = computed(() => projectStore.visibleProperties.map((property, colIndex) => colIndex))

const scrollState = useScroll(containerProps.ref)
/**
 * Indices of all properties that are visible in the current
 * scrolled range
 */
const columnsInView = computed<number[]>(() => {
  const inView: number[] = []
  let processedWidth = 0
  projectStore.visibleProperties.forEach((property, index) => {
    const width = projectStore.getWidth(property.id)
    const columnStart = processedWidth
    const columnEnd = processedWidth + width
    processedWidth += width
    if (columnEnd < scrollState.x.value || columnStart > scrollState.x.value + tableWidth.value) {
      return
    }

    inView.push(index)
  })

  return inView
})

const tableScrollStore = useTableZIndices()
// When the container ref is assigned, pass it to the Z Indices store so
// we can track its scroll position and assign the correct z-index to the header
// and first column.
watch(
  containerProps.ref,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      tableScrollStore.tableRef = newValue
    } else if (!newValue && oldValue) {
      tableScrollStore.tableRef = null
    }
  },
  {
    immediate: true,
  },
)

const filterStore = useFilters()
// Scroll to the top when the filters have changed, as the scroll position may be
// out of view after the new filters are applied.
watch(
  () => filterStore.currentFilter,
  () => {
    if (!tableScrollStore.tableRef) {
      return
    }

    tableScrollStore.tableRef.scrollTop = 0
  },
)

/**
 * We have 2 separate grid layouts - the header and the rest of the table.
 * This is so that the header is always rendered, even when the table flickers
 * out of view while we load more virtual rows.
 */
const gridTemplateColumns = computed(() => {
  // Fixed first column, then auto size the property columns, then fill
  // the rest of the space with the 'add property' column
  const propertyWidths = projectStore.visibleProperties.map(
    ({ id }) => `${projectStore.getWidth(id)}px`,
  )
  return `${extraIndexColWidth.value ? 64 : 48}px ${propertyWidths.join(' ')} minmax(32px, 1fr)`
})

const headerGridStyles = computed(() => ({
  gridTemplateColumns: gridTemplateColumns.value,
  gridTemplateRows: `${ITEM_HEIGHT}px`,
}))

const gridStyles = computed(() => {
  return {
    gridTemplateColumns: gridTemplateColumns.value,
    // Every row is the same height - entity rows and the header/footer
    gridTemplateRows: `repeat(${(projectStore.activeView?.entities?.length ?? 0) + 1}, ${ITEM_HEIGHT}px)`,
  }
})

const firstColumnShadowStyles = computed<StyleValue>(() => {
  if (!containerProps.ref.value) {
    return {}
  }

  const height = Math.min(
    containerProps.ref.value.clientHeight - ITEM_HEIGHT,
    ((projectStore.activeView?.entities?.length ?? 0) + 2) * ITEM_HEIGHT,
  )

  return {
    height: `${height}px`,
    top: 0,
  }
})

function rowContextDelete(rowId?: string) {
  if (projectStore.selectedEntityIds.size) {
    pendingIdsToDelete.value = [...projectStore.selectedEntityIds]
  } else if (rowId) {
    pendingIdsToDelete.value = [rowId]
  }
}

function rowContextOpen(rowId?: string) {
  // If selected, leave as is
  if (rowId && projectStore.selectedEntityIds.has(rowId)) {
    return
  }
  // Otherwise, clear all and select this one
  projectStore.clearAllSelectedEntities()
  rowId && toggleRowSelection(rowId)
}

const { canEditProjects } = useWorkspacePermissions()

const onSetFieldValues = async (
  propertyId: string,
  valueMap: Partial<Record<string, string[] | null>>,
) => {
  const projectId = projectStore.projectId
  const workspaceId = workspacesStore.currentWorkspace?.id

  if (!projectId || !workspaceId) {
    throw new Error('Project ID or workspace ID is missing when setting field values')
  }

  projectStore.selectedEntityIds.clear()

  let successCount = 0
  await Promise.all(
    Object.entries(valueMap).map(async ([entityId, value]) => {
      const res = await setFieldValue({
        entityId,
        projectId,
        propertyId,
        value: { options: value ?? [] },
        workspaceId,
      })
      if (res.ok) {
        successCount++
      } else {
        const entityRes = await getEntity(workspaceId, projectId, entityId)

        if (entityRes.ok) {
          Array.from(serializeFields(entityRes.data.fields).values()).forEach(
            projectStore.updateField,
          )
        }
      }
    }),
  )

  const numberOfEntities = Object.keys(valueMap).length

  if (successCount === 0) {
    toast.error('Failed to bulk update values')
  } else if (successCount < numberOfEntities) {
    toast.warning(
      `Value applied to ${successCount} of ${numberOfEntities} entities. Some entities may have failed to update.`,
    )
  } else {
    toast.success(
      `Value applied to ${numberOfEntities} ${numberOfEntities > 1 ? 'entities' : 'entity'}`,
    )
  }
}

const extraIndexColWidth = computed(() => (rangeState.value.start ?? 0) > 9999)

const containerScrollState = useScroll(containerProps.ref)

const askGoStore = useAskGo()

watch(
  () => projectStore.selectedProperty,
  (selectedProperty) => {
    if (!askGoStore.isOpen) return
    if (!selectedProperty) return

    const index = projectStore.visibleProperties.indexOf(selectedProperty)
    if (index === -1) return

    const width = projectStore.getWidth(selectedProperty.id)

    const columnStart = projectStore.visibleProperties
      .slice(0, index)
      .reduce((acc, p) => acc + projectStore.getWidth(p.id), 0)

    // This margin is computed as the size of the Property Configuration Menu, so it's never on top of the Ask Go sidebar
    const margin = 360

    if (
      columnStart + margin + containerScrollState.x.value > tableWidth.value ||
      columnStart < containerScrollState.x.value
    ) {
      containerScrollState.x.value = columnStart - tableWidth.value / 2 + width / 2
    }
  },
)
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="w-full flex-1 [contain:strict]">
      <div class="z-0 contents">
        <div
          class="absolute left-8 top-0 z-1 w-4 shadow-sm transition-opacity"
          :class="tableScrollStore.leftScroll === 0 ? 'opacity-0' : 'opacity-100'"
          :style="firstColumnShadowStyles"
        />
        <div
          class="flex h-full flex-1 flex-col overflow-auto bg-surface-primary scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
          :class="{
            'rounded-tr-corner-12 border-r border-r-border-subtle': propertyStore.sidebarIsOpen,
          }"
          data-table-container
          role="grid"
          v-bind="containerProps"
          aria-multiselectable="true"
        >
          <ProjectTableHeader
            class="sticky top-0 grid w-max min-w-full bg-surface-primary"
            :extra-index-col-width="extraIndexColWidth"
            :class="tableScrollStore.zIndex.header"
            :style="headerGridStyles"
          />
          <div
            data-table-wrapper
            class="grid h-full min-w-fit flex-1 flex-col bg-surface-primary"
            :style="gridStyles"
            v-bind="wrapperProps"
          >
            <div
              id="grid-container"
              ref="tableBody"
              class="contents"
            >
              <ProjectTableRow
                v-for="{ data: row, index: rowIndex } in entityList"
                :key="rowIndex"
                :row-index="rowIndex"
                :cells="cells"
                :columns-in-view="columnsInView"
                :plural-selection="projectStore.selectedEntityIds.size > 1"
                :workspace-id="workspacesStore.currentWorkspace?.id"
                :project-id="projectStore.projectId"
                :entity-id="row?.id"
                :extra-index-col-width="extraIndexColWidth"
                @extend-selection="extendRowSelection(rowIndex)"
                @toggle="row && toggleRowSelection(row.id)"
                @delete="row && (pendingIdsToDelete = [row.id])"
                @next="onNext"
                @context:delete="rowContextDelete(row?.id)"
                @context:open="rowContextOpen(row?.id)"
              />
            </div>
            <ProjectTableAddRow
              v-if="canEditProjects && projectStore.activeView?.id == projectStore.mainView?.id"
              class="contents"
              :number-of-properties="projectStore.properties.length"
              :extra-index-col-width="extraIndexColWidth"
              @create-entity="addRow"
              >New entity</ProjectTableAddRow
            >
            <div
              v-else
              class="col-span-full h-0 border-t border-border-subtle"
            />
            <ProjectTableActionBar
              class="absolute bottom-3 left-1/2 -translate-x-1/2 transition duration-200 ease-in-out"
              :class="[
                ACTION_BAR_Z_INDEX,
                projectStore.selectedEntityIds.size
                  ? 'scale-100 opacity-100'
                  : 'scale-95 opacity-0',
              ]"
              @delete="pendingIdsToDelete = [...projectStore.selectedEntityIds]"
              @set-value="onSetFieldValues"
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :open="pendingIdsToDelete.length > 0"
      :title="`Delete ${pendingIdsToDelete.length > 1 ? 'these entities' : 'this entity'}?`"
      data-test="delete-confirmation-dialog"
      :description="`${
        pendingIdsToDelete.length > 1 ? 'These entities ' : 'This entity will'
      } will be deleted immediately. You can't undo this action.`"
      @confirm="removePendingDeletionEntities"
      @close="pendingIdsToDelete = []"
    />
  </div>
</template>
